const HomeIcon = (props) => (
  <svg
    width={18}
    height={21}
    viewBox='0 0 18 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.8515 2.1118L10.8518 2.11202L15.7584 6.04324C16.1486 6.35603 16.5261 6.8766 16.8066 7.49206C17.0861 8.1052 17.2493 8.76838 17.2493 9.34053V15.9545C17.2493 18.2317 15.6483 19.9613 13.8137 19.9613H4.63075C2.79746 19.9613 1.19519 18.2234 1.19519 15.9447V9.21372C1.19519 8.6823 1.34284 8.05249 1.5975 7.4602C1.85294 6.86607 2.19703 6.35381 2.55309 6.03577L2.55319 6.03568L6.81964 2.22263C6.81985 2.22244 6.82006 2.22226 6.82027 2.22207C7.9261 1.24143 9.70631 1.19293 10.8515 2.1118ZM9.22223 17.7909C9.90913 17.7909 10.3611 17.1696 10.3611 16.5593V13.6328C10.3611 13.0225 9.90913 12.4011 9.22223 12.4011C8.53532 12.4011 8.08334 13.0225 8.08334 13.6328V16.5593C8.08334 17.1696 8.53533 17.7909 9.22223 17.7909Z'
      stroke='currentColor'
    />
  </svg>
);
export default HomeIcon;
