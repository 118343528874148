export const handleConfigurablesGrouping = (configurables) => {
  const groupBy = configurables?.reduce((acc, item) => {
    const opts = Object.keys(item.option_details);

    opts.forEach((key) => {
      if (!acc[key]) {
        acc[key] = {};
      }
      acc[key][item?.option_details[key]?.option_id] = {
        ...item?.option_details[key],
        _media_: item?._media_,
        prices_with_tax: item?.prices_with_tax,
        sku: item?.sku,
      };
    });
    return acc;
  }, {});
  return groupBy;
};

export const productConfigurablesHandler = (product) => {
  if (
    product?.has_options &&
    product?._configurables &&
    product?._configurables?.length &&
    !product?._configurables?.every((item) => 'option_details' in item)
  ) {
    let updatedConfigurables = product?._configurables?.map((item) => {
      const opts = Object.keys(item.options);
      const updatedOptionDetails = {};
      opts?.forEach((key) => {
        const selectedKey = product?.option_details[key];
        const selectedOptsVal = item?.options[key];
        updatedOptionDetails[key] = selectedKey
          ? selectedKey?.find((itemF) => itemF?.option_id == selectedOptsVal)
          : {};
      });
      return { ...item, option_details: updatedOptionDetails };
    });
    return { ...product, _configurables: updatedConfigurables };
  } else {
    return product;
  }
};

export function retainKeys(obj, keysToKeep) {
  const result = {};

  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          result[key] = {};

          for (const subKey in obj[key]) {
              if (obj[key].hasOwnProperty(subKey)) {
                  if (subKey === 'attribute_id' && keysToKeep.includes(subKey)) {
                      result[key]['option_id'] = obj[key][subKey];
                  } else if (keysToKeep.includes(subKey)) {
                      result[key]['option_value'] = Number(obj[key][subKey]);
                  }
              }
          }
      }
  }

  return result;
}