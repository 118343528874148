const Arrow = (props) => (
  <svg
    width={11}
    height={7}
    viewBox='0 0 11 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.231288 0.244272C0.531318 -0.090672 1.00841 -0.0798111 1.2969 0.268531L5.02431 4.86257L8.75171 0.268531C9.0402 -0.079811 9.51729 -0.090672 9.81732 0.244272C10.1174 0.579216 10.1267 1.13313 9.83822 1.48147L5.56756 6.73147C5.42547 6.90304 5.22931 7 5.02431 7C4.8193 7 4.62314 6.90304 4.48105 6.73147L0.210394 1.48147C-0.0780967 1.13313 -0.068742 0.579216 0.231288 0.244272Z'
      fill='currentColor'
    />
  </svg>
);
export default Arrow;
