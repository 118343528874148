import FavProdCard from '@/components/icons/FavProdCard';
import FillHeart from '@/components/icons/FillHeart';
// import Heart from '@/components/icons/Heart';
import { useWishlist } from '@/context/wishlist';
import { handleWishList } from '@/utils/wishlist';

const AddtoWishlist = ({ fromFav, product, fromPDP, t }) => {
  const { wishlist, dispatch } = useWishlist();

  return fromFav ? (
    <button
      onClick={() => {
        handleWishList(wishlist, product, dispatch);
      }}
      className='flex size-7 items-center justify-center rounded-md bg-white sm:size-8'
    >
      {product?.sku in wishlist ? (
        <FillHeart className='size-3.5 sm:size-4' />
      ) : (
        <FavProdCard className='size-3.5 sm:size-4' />
      )}
    </button>
  ) : (
    <button
      className='flex items-center gap-1.5 sm:mb-1 sm:mt-2'
      onClick={() => handleWishList(wishlist, product, dispatch)}
    >
      {product?.sku in wishlist ? (
        <>
          <FillHeart className='size-4 max-sm:w-5' />
          {fromPDP && (
            <span className='block font-semibold uppercase'>
              {t('Remove from Wishlist')}
            </span>
          )}
        </>
      ) : (
        <>
          <FavProdCard className='size-4 max-sm:w-5' />
          {fromPDP && (
            <span className='block font-semibold uppercase'>
              {t('Add To Wishlist')}
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default AddtoWishlist;
