import Link from 'next/link';
import Arrow from '../icons/Arrow';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useGlobalData } from '@/context/global';
import { findObjectByIdV2 } from '@/utils/global';

const BreadCrumb = ({ current }) => {
  const [bc, setBc] = useState([]);
  const {
    appData: { categoryData },
  } = useGlobalData();
  const { locale, query } = useRouter();
  const ids = query?.category?.map((url_key) =>
    url_key.slice(url_key.lastIndexOf('-c-') + 3)
  );
  useEffect(() => {
    const bc_data = (ids || [])
      .map((id) => findObjectByIdV2(categoryData?.children_data, 'id', id))
      ?.filter((item) => item?.id);
    setBc(bc_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, categoryData, locale]);

  return (
    <section className='bg-zinc-100 py-3 sm:py-4'>
      <div className='mx-auto flex w-11/12 items-center gap-2'>
        <Link href='/' className='font-bold text-red-600 max-sm:text-xs'>
          {locale?.includes('en') ? 'Home' : 'الرئيسية'}
        </Link>
        <Arrow
          className={`mt-0.5 size-3 ${locale.includes('ar') ? 'rotate-90' : '-rotate-90'}`}
        />
        {current ? (
          <div className='font-bold max-sm:text-xs'>{current}</div>
        ) : bc?.length > 0 ? (
          bc.map((cat, idx) => {
            const link = bc
              ?.slice(0, idx + 1)
              .map((item) => item?.url_key)
              ?.join('/');
            return (
              <div
                key={`bread-crumb-${idx}`}
                className='flex items-center justify-center'
              >
                {bc?.length - 1 > idx ? (
                  <Link
                    href={`/${link}`}
                    className='hover:text-primary font-bold duration-200 hover:text-red-500 max-sm:text-xs'
                  >
                    {cat?.name}
                  </Link>
                ) : (
                  <div className='font-bold max-sm:text-xs'>{cat?.name}</div>
                )}

                {bc.length - 1 > idx && (
                  <Arrow
                    size='20px'
                    className={`mx-2 ${locale.includes('ar') ? 'rotate-90' : '-rotate-90'}`}
                  />
                )}
              </div>
            );
          })
        ) : null}
        <span className='font-bold max-sm:text-xs'>{''}</span>
      </div>
    </section>
  );
};

export default BreadCrumb;
