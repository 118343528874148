import { object, ref, string } from 'yup';
export const mobileRegex = (locale) =>
  locale.toLowerCase().includes('ae')
    ? /^(05)(0|2|4|5|6|8)([0-9]{7})$/
    : /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
export const loginValidation = (t, isMobile, locale) =>
  object().shape({
    username: isMobile
      ? string()
      : string().email(t('invalidEmail')).required(t('emailRequired')),
    mobilenumber: isMobile
      ? string()
          .label('Phone number')
          .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t('telephoneError1'))
          .matches(mobileRegex(locale), t('telephoneError2'))
          .min(10)
          .max(10)
          .required(t('TelephoneRequired'))
      : string(),
    // password: isMobile ? string() : string().required(t('PasswordRequired')),
  });

export const registerValidation = (t, locale) =>
  object().shape({
    firstname: string().required(t('FirstNameRequired')),
    lastname: string().required(t('LastNameRequired')),
    email: string().email(t('invalidEmail')).required(t('emailRequired')),

    terms: string().required(t('YouMustAcceptTerms')),
    mobile: string()
      .label('Phone number')
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t('telephoneError1'))
      .matches(mobileRegex(locale), t('telephoneError2'))
      .min(10)
      .max(10)
      .required(t('TelephoneRequired')),
  });

export const registerByMobileValidation = (t, locale) =>
  object().shape({
    mobilenumber: string()
      .label('Phone number')
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t('telephoneError1'))
      .matches(mobileRegex(locale), t('telephoneError2'))
      .min(10)
      .max(10)
      .required(t('TelephoneRequired')),
  });

export const otpValidation = (t) =>
  object().shape({
    otp: string().required(t('Otp is required')),
  });

export const emailValidation = (t) =>
  object().shape({
    email: string().email(t('Invalid Email')).required(t('email is required')),
  });

export const resetPasswordValidation = (t) =>
  object().shape({
    password: string()
      .min(8, t('HasAtLeast8Characters'))
      .matches(/[A-Z]/, t('ContainsOneUppercaseLetter'))
      .matches(/[a-z]/, t('ContainsOneLowercaseLetter'))
      .matches(/[0-9]/, t('ContainsOneNumber'))
      .required(t('PasswordRequired')),
    confirmPassword: string()
      .oneOf([ref('password'), null], t('PasswordsMustMatch'))
      .required(t('ConfirmPasswordRequired')),
  });

export const forgotValidation = (t, isMobile, locale) =>
  object().shape({
    ...(isMobile
      ? {
          mobilenumber: string()
            .label('Phone number')
            .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t('telephoneError1'))
            .matches(mobileRegex(locale), t('telephoneError2'))
            .min(10)
            .max(10)
            .required(t('TelephoneRequired')),
        }
      : {
          email: string().required(t('emailRequired')).email(t('emailError')),
        }),
  });

export const changePasswordValidation = (t) =>
  object().shape({
    password: string()
      .min(8, t('HasAtLeast8Characters'))
      .matches(/[A-Z]/, t('ContainsOneUppercaseLetter'))
      .matches(/[a-z]/, t('ContainsOneLowercaseLetter'))
      .matches(/[0-9]/, t('ContainsOneNumber'))
      .required(t('PasswordRequired')),
    confirmPassword: string()
      .oneOf([ref('password'), null], t('PasswordsMustMatch'))
      .required(t('ConfirmPasswordRequired')),
  });

export const editProfileValidation = (t) =>
  object().shape({
    firstname: string().required(t('FirstNameRequired')),
    lastname: string().required(t('LastNameRequired')),
    password: string()
      .min(8, t('HasAtLeast8Characters'))
      .matches(/[A-Z]/, t('ContainsOneUppercaseLetter'))
      .matches(/[a-z]/, t('ContainsOneLowercaseLetter'))
      .matches(/[0-9]/, t('ContainsOneNumber'))
      .required(t('PasswordRequired')),
  });

export const changePasswordUserValidation = (t) =>
  object().shape({
    password: string()
      .min(8, t('HasAtLeast8Characters'))
      .matches(/[A-Z]/, t('ContainsOneUppercaseLetter'))
      .matches(/[a-z]/, t('ContainsOneLowercaseLetter'))
      .matches(/[0-9]/, t('ContainsOneNumber'))
      .required(t('PasswordRequired')),
    newPassword: string()
      .min(8, t('HasAtLeast8Characters'))
      .matches(/[A-Z]/, t('ContainsOneUppercaseLetter'))
      .matches(/[a-z]/, t('ContainsOneLowercaseLetter'))
      .matches(/[0-9]/, t('ContainsOneNumber'))
      .required(t('PasswordRequired')),
    confirmPassword: string()
      .oneOf([ref('newPassword'), null], t('PasswordsMustMatch'))
      .required(t('ConfirmPasswordRequired')),
  });

export const changeMailValidation = (t) =>
  object().shape({
    email: string()
      .email(t('invalidEmail'))
      .matches(/^[a-zA-Z0-9._%+-]+@gmail\.com$/, t('invalidEmail'))
      .required(t('emailRequired')),
    remail: string()
      .email(t('invalidEmail'))
      .matches(/^[a-zA-Z0-9._%+-]+@gmail\.com$/, t('invalidEmail'))
      .notOneOf([ref('email'), null], t('EmailsNotMustMatch'))
      .required(t('ConfirmEmailRequired')),
    password: string()
      .min(8, t('HasAtLeast8Characters'))
      .matches(/[A-Z]/, t('ContainsOneUppercaseLetter'))
      .matches(/[a-z]/, t('ContainsOneLowercaseLetter'))
      .matches(/[0-9]/, t('ContainsOneNumber'))
      .required(t('PasswordRequired')),
  });
export const editAddressesValidation = (t, locale) =>
  object().shape({
    firstname: string().required(t('FirstNameRequired')),
    lastname: string().required(t('LastNameRequired')),
    email: string().email(t('invalidEmail')).required(t('emailRequired')),
    city: string().required(t('CityRequired')),
    region: string().required(t('RegionRequired')),
    street: string().required(t('Address Required')),
    district: string().required(t('districtRequired')),
    phone_number: string()
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t('telephoneError1'))
      .matches(mobileRegex(locale), t('telephoneError2'))
      .min(10)
      .max(10)
      .required(t('TelephoneRequired')),
  });
