import React, { useRef } from 'react';
import CloseIcon from '../icons/CloseIcon';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import CopyIcon from '../icons/CopyIcon';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  ViberShareButton,
  ViberIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';
import toast from 'react-hot-toast';
import { useTranslation } from 'next-i18next';

const ShareModal = ({ open, setOpen, url, title }) => {
  const iconSize = 36;
  const iconBorderRadius = 6;
  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setOpen(false));
  const { t } = useTranslation('common');

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`
        ${title}
        ــــــــــــــــــ
        ${url}
      `);
      toast.success(t('copied'));
      setOpen(false);
    }
  };
  return (
    open && (
      <div
        ref={modalRef}
        className='fixed left-1/2 top-1/2 z-50 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col rounded-md bg-slate-200 p-4 shadow-md  ring-1 ring-slate-300/70 md:min-w-80 md:max-w-[500px]'
      >
        <div className='flex justify-between text-slate-600'>
          <h2 className='text-lg font-bold'>{t('share')}</h2>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className='flex-grow'>
          <h1 className='my-3 divide-x-2 text-slate-600'>{title || ''}</h1>
          <div className='my-3 flex flex-wrap gap-2'>
            <FacebookShareButton url={url}>
              <FacebookIcon size={iconSize} borderRadius={iconBorderRadius} />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title || ''}>
              <XIcon size={iconSize} borderRadius={iconBorderRadius} />
            </TwitterShareButton>
            <WhatsappShareButton url={url} title={title || ''}>
              <WhatsappIcon size={iconSize} borderRadius={iconBorderRadius} />
            </WhatsappShareButton>
            <TelegramShareButton url={url} title={title || ''}>
              <TelegramIcon size={iconSize} borderRadius={iconBorderRadius} />
            </TelegramShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={iconSize} borderRadius={iconBorderRadius} />
            </LinkedinShareButton>
            <PinterestShareButton url={url}>
              <PinterestIcon size={iconSize} borderRadius={iconBorderRadius} />
            </PinterestShareButton>
            <ViberShareButton url={url} title={title || ''}>
              <ViberIcon size={iconSize} borderRadius={iconBorderRadius} />
            </ViberShareButton>
            <LineShareButton url={url} title={title || ''}>
              <LineIcon size={iconSize} borderRadius={iconBorderRadius} />
            </LineShareButton>

            <EmailShareButton url={url}>
              <EmailIcon size={iconSize} borderRadius={iconBorderRadius} />
            </EmailShareButton>
          </div>
        </div>
        <div className='flex'>
          <input
            ref={modalRef}
            type='text'
            value={url}
            readOnly
            className='flex-grow px-4 font-bold text-slate-500 focus:outline-0'
          />
          <button
            onClick={handleCopy}
            className='flex  items-center justify-center  gap-2 bg-[#D11A3C] px-4 py-2 text-white'
          >
            <span>{t('copyLink')}</span>
            <CopyIcon />
          </button>
        </div>
      </div>
    )
  );
};

export default ShareModal;
