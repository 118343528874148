const BranchesIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 10.8999C9.89168 10.8999 9.78335 10.8749 9.68335 10.8166L5.26669 8.26658C4.96669 8.09158 4.86667 7.70825 5.04167 7.41658C5.21667 7.11658 5.59167 7.01658 5.90001 7.19158L10 9.56658L14.075 7.20825C14.375 7.03325 14.7584 7.14158 14.9334 7.43325C15.1084 7.73325 15 8.11658 14.7083 8.28325L10.325 10.8166C10.2167 10.8666 10.1084 10.8999 10 10.8999Z"
      fill="currentColor"
    />
    <path
      d="M10 15.4337C9.65833 15.4337 9.375 15.1504 9.375 14.8087V10.2754C9.375 9.93372 9.65833 9.65039 10 9.65039C10.3417 9.65039 10.625 9.93372 10.625 10.2754V14.8087C10.625 15.1504 10.3417 15.4337 10 15.4337Z"
      fill="currentColor"
    />
    <path
      d="M10.0002 15.6247C9.51687 15.6247 9.04188 15.5163 8.65855 15.308L5.99187 13.8247C5.19187 13.383 4.5752 12.3247 4.5752 11.408V8.58301C4.5752 7.67467 5.20021 6.60801 5.99187 6.16634L8.65855 4.68301C9.42522 4.25801 10.5752 4.25801 11.3419 4.68301L14.0085 6.16634C14.8085 6.60801 15.4252 7.66634 15.4252 8.58301V11.408C15.4252 12.3163 14.8002 13.383 14.0085 13.8247L11.3419 15.308C10.9585 15.5163 10.4835 15.6247 10.0002 15.6247ZM10.0002 5.62467C9.7252 5.62467 9.45853 5.67467 9.26687 5.78301L6.60021 7.26634C6.19188 7.49134 5.8252 8.12467 5.8252 8.58301V11.408C5.8252 11.8747 6.19188 12.4997 6.60021 12.7247L9.26687 14.208C9.6502 14.4247 10.3502 14.4247 10.7335 14.208L13.4002 12.7247C13.8085 12.4997 14.1752 11.8663 14.1752 11.408V8.58301C14.1752 8.11634 13.8085 7.49134 13.4002 7.26634L10.7335 5.78301C10.5419 5.67467 10.2752 5.62467 10.0002 5.62467Z"
      fill="currentColor"
    />
    <path
      d="M12.5001 18.9579C12.2751 18.9579 12.0668 18.8329 11.9584 18.6412C11.8501 18.4412 11.8501 18.2079 11.9667 18.0079L12.8418 16.5495C13.0168 16.2579 13.4001 16.1579 13.7001 16.3329C14.0001 16.5079 14.0918 16.8912 13.9168 17.1912L13.6917 17.5662C15.9917 17.0245 17.7168 14.9579 17.7168 12.4912C17.7168 12.1495 18.0001 11.8662 18.3418 11.8662C18.6834 11.8662 18.9668 12.1495 18.9668 12.4912C18.9584 16.0579 16.0584 18.9579 12.5001 18.9579Z"
      fill="currentColor"
    />
    <path
      d="M1.66675 8.12533C1.32508 8.12533 1.04175 7.84199 1.04175 7.50033C1.04175 3.94199 3.94175 1.04199 7.50008 1.04199C7.72508 1.04199 7.93341 1.16699 8.04174 1.35866C8.15008 1.55866 8.15009 1.79199 8.03343 1.99199L7.15841 3.45033C6.98341 3.74199 6.60008 3.84199 6.30008 3.66699C6.00008 3.49199 5.9084 3.10866 6.0834 2.80866L6.30842 2.43366C4.00842 2.97532 2.28341 5.04199 2.28341 7.50866C2.29174 7.84199 2.00841 8.12533 1.66675 8.12533Z"
      fill="currentColor"
    />
  </svg>
);
export default BranchesIcon;
