import { useRouter } from 'next/router';
import { Cairo } from 'next/font/google';
import { Suspense, createContext, useEffect, useReducer } from 'react';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';

import Layout from '@/layout';
import dynamic from 'next/dynamic';
import NextNProgress from 'nextjs-progressbar';
import ErrorBoundary from '@/components/ErrorBoundary';
import SuspenseFallback from '@/components/SuspenseFallback';

import 'swiper/css';
import 'swiper/css/pagination';
import 'react-datepicker/dist/react-datepicker.css';
import '@/styles/globals.css';
import { AppProvider } from '@/context/global/provider';
import { CartProvider } from '@/context/cart/provider';
import useSWR, { SWRConfig } from 'swr';
import { handShake } from '@/services/handshake';
import { getCookie } from 'cookies-next';
import { initialState, modalReducer } from '@/context/Modal.reducer';
import { WishlistProvider } from '@/context/wishlist/Provider';
import { CompareProvider } from '@/context/compare/compare-context';
import { CheckoutProvider } from '@/context/checkout/provider';
import { AWSPersonalizeProvider } from '@/context/aws-personalize/aws-personalize-context';
import CookieConsent from '@/components/CookieConsent';

const cairoFont = Cairo({
  weight: ['200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal'],
  display: 'swap',
  subsets: ['arabic'],
  variable: '--font-cairo',
});

const Toaster = dynamic(
  () => import('react-hot-toast').then((c) => c.Toaster),
  { ssr: false }
);

export const ModalContext = createContext(null);

const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  const handshake = getCookie('handshake');
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const { mutate } = useSWR('handshake', () => handShake(locale), {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    refreshInterval: 30 * 60 * 1000,
  });

  // auto generate handshake if not exist or locale changed
  useEffect(() => {
    if (!handshake || handshake === 'undefined') {
      mutate();
      return;
    }
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (typeof window != 'undefined' && !window?.webengage) {
      window.webengage = {};
    }
    window.onresize = () => {
      const windowSize = window.devicePixelRatio;
      if (windowSize < 1) {
        document.body.style.width = windowSize * 100 + '%';
        document.body.style.margin = 'auto';
      } else {
        document.body.style.width = '100%';
        document.body.style.margin = 'auto';
      }
    };
  });

  useEffect(() => {
    if (typeof window != 'undefined' && !window.webengage) {
      window.webengage = {};
    }
  }, []);

  return (
    <SessionProvider session={pageProps?.session}>
      <main className={`${cairoFont.variable} font-sans`}>
        <NextNProgress
          color='#FFF'
          startPosition={0.3}
          stopDelayMs={200}
          height={4}
          showOnShallow={false}
        />
        <ModalContext.Provider value={{ state, dispatch }}>
          <AWSPersonalizeProvider>
            <AppProvider>
              <CompareProvider>
                <CartProvider>
                  <WishlistProvider>
                    <CheckoutProvider>
                      <Layout>
                        <Suspense fallback={<SuspenseFallback />}>
                          <ErrorBoundary>
                            <SWRConfig value={{ provider: () => new Map() }}>
                              <Component {...pageProps} />
                            </SWRConfig>
                          </ErrorBoundary>
                        </Suspense>
                      </Layout>
                    </CheckoutProvider>
                  </WishlistProvider>
                </CartProvider>
              </CompareProvider>
            </AppProvider>
          </AWSPersonalizeProvider>
        </ModalContext.Provider>
        <Toaster position='bottom-center' reverseOrder={false} />
      </main>
      <CookieConsent className='hidden px-4 md:flex' />
    </SessionProvider>
  );
};

export default appWithTranslation(App);
