// import { LayoutCategories } from '@/constants/Categories';
import { useGlobalData } from '@/context/global';
// import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import Link from 'next/link';
import Arrow from '@/components/icons/Arrow';
// import Discount from '@/components/icons/Discount';
import BurgerMenu from '@/components/icons/BurgerMenu';
import ExpandableMenu from '@/components/global/menus/ExpandableMenu';
import { usePathname } from 'next/navigation';
// import TopThisWeek from './TopThisWeek';
import useClient from '@/hooks/global/useClient';

const Categories = ({ t, isSearching }) => {
  const pathname = usePathname();
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [isClickAllDepartments, setIsClickAllDepartments] = useState(false);
  const { isClient } = useClient();

  const {
    appData: { menuTreeData, megaMenuTreeData },
  } = useGlobalData();
  const isHomePage = pathname === '/';

  console.log('megaMenuTreeData', megaMenuTreeData);

  useEffect(() => {
    pathname === '/' ? setShow(true) : setShow(false);
  }, [pathname]);

  return (
    <section className='hidden  bg-zinc-100 lg:block'>
      <div className='mx-auto flex max-w-[1440px]'>
        {/* <div className='flex-1 py-4 bg-zinc-200'></div> */}
        <div className='mx-auto grid w-11/12 grid-cols-12'>
          <div
            ref={ref}
            className='relative col-span-2 flex max-h-full min-w-60 items-center justify-between bg-zinc-200 py-2 pe-5 ps-3'
          >
            <div
              onClick={() => {
                setIsClickAllDepartments(true);
                setShow(!show);
              }}
              className='flex w-full cursor-pointer items-center justify-between gap-2 text-zinc-500'
            >
              <div className='flex'>
                <BurgerMenu />
                <span className='whitespace-nowrap font-semibold'>
                  {t('All Departments')}
                </span>
              </div>
              <Arrow />
            </div>
            {show && !isSearching && (
              <ExpandableMenu
                menuDataL1={menuTreeData}
                setShow={setShow}
                childrenKey={'children_data'}
                show={show}
                btnRef={ref}
                isClickAllDepartments={isClickAllDepartments}
                setIsClickAllDepartments={setIsClickAllDepartments}
                isHomePage={isHomePage}
              />
            )}
          </div>
          <div className='col-span-10 flex items-center justify-between bg-zinc-100 py-2 ps-14'>
            <div className='flex gap-8'>
              {megaMenuTreeData?.length > 0 &&
                isClient &&
                megaMenuTreeData?.map((category) => (
                  <Link
                    href={`/${category?.url_key}`}
                    key={`categories-layout-${category?.url_key}`}
                    className='text-[15px] font-semibold uppercase text-zinc-500'
                  >
                    {category?.name}
                  </Link>
                ))}
            </div>
            {/* <div className='flex items-center gap-3'>
              <TopThisWeek t={t} />
            </div> */}
          </div>
        </div>
        <div className='flex-1 bg-zinc-100 py-4'></div>
      </div>
    </section>
  );
};

export default Categories;
