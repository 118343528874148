// components/CookieConsent.js
import { getCookie, setCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
// import { MdCheck } from "react-icons/md";
import CookieSettingModal from './CookieSettingModal';

const CookieConsent = () => {
  const { locale } = useRouter();
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const consent = getCookie('cookieConsent');
    if (!consent) {
      setShowPopup(true);
    }
  }, []);

  const acceptCookies = () => {
    setCookie('cookieConsent', 'true', { maxAge: 86400 * 365 });
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <>
      <section className='fixed bottom-0 start-0 z-[999999999] w-screen bg-white pb-8 pt-12 shadow-inner max-sm:pb-3 max-sm:pt-6'>
        <div className='mx-auto flex w-11/12 items-center justify-between max-sm:flex-col max-sm:gap-5'>
          <div className='flex w-3/4 flex-col gap-2 max-sm:w-full'>
            <h2 className='text-lg font-semibold max-sm:text-sm'>
              {locale === 'ar'
                ? ' قصر الأوانى  يقدر خصوصيتك !'
                : 'Qasr Elawani values ​​your privacy!'}
            </h2>
            <p className='text-sm max-sm:text-[9px]'>
              {locale === 'ar'
                ? `على هذا الموقع نستخدم ملفات تعريف الإرتباط ووظائف مماثله لمعالجه
            المعلومات والبيانات الشخصية ( على سبيل المثال عنوان IP ومعلومات
            المتصفح ). تستخدم المعالجه لأغراض مثل دمج وتخصيص المحتوى والتحليل
            ال‘حصائى / القياس والإعلانات المخصصة ودمج وسائل التواصل الإجتماعى.
            هذه الموافقه طوعيه وليست مطلوبه لاإستخدام موقعنا ويمكن إلغاؤها فى اى
            وقت من تفضيلات الإستخدام`
                : `On this site we use cookies and similar functions to process personal information and data (e.g. IP address and browser information). The processing is used for purposes such as combining and personalizing content, statistical analysis/measurement, personalized advertising and social media integration. This consent is voluntary and is not required to use our site and can be revoked at any time from the usage preferences`}
            </p>
          </div>
          <div className='flex flex-col items-center gap-4 max-sm:gap-2'>
            <button
              onClick={acceptCookies}
              className='flex w-40 items-center justify-center gap-1 rounded-md bg-black p-1.5 text-white'
            >
              {/* <MdCheck /> */}
              <span className='text-sm font-semibold max-sm:text-[9px]'>
                {locale === 'ar' ? 'قبول الكل' : 'Accept all'}
              </span>
            </button>
            <button
              onClick={() => setShowModal(true)}
              className='w-40 rounded-md bg-black p-1.5 text-sm font-semibold text-white max-sm:text-[9px]'
            >
              {locale === 'ar' ? 'الإعدادات' : 'Settings'}
            </button>
            <div className='flex items-center gap-4'>
              <Link
                href='/static/privacy'
                className='text-xs text-gray-500 max-sm:text-[8px]'
              >
                {locale === 'ar' ? 'تعرف على المزيد' : 'Learn more'}
              </Link>
              <span className='text-gray-500'> | </span>
              <Link
                href='/static/terms'
                className='text-xs text-gray-500 max-sm:text-[8px]'
              >
                {locale === 'ar' ? 'الشروط والأحكام' : ' Terms & Conditions'}
              </Link>
              <span className='text-gray-500'> | </span>
              <Link
                href='/static/privacy'
                className='text-xs text-gray-500 max-sm:text-[8px]'
              >
                {locale === 'ar' ? 'إشعار قانونى' : 'Legal notice'}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {showModal ? (
        <CookieSettingModal {...{ setShowModal, acceptCookies, locale }} />
      ) : null}
    </>
  );
};

export default CookieConsent;

/*
<div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center z-[1000]">
      <p className="text-sm">
        {locale === "ar"
          ? "نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك. بمواصلة التصفح، فإنك توافق على استخدامنا لملفات تعريف الارتباط."
          : "We use cookies to improve your experience. By continuing to browse, you accept our use of cookies."}
      </p>
      <button
        onClick={acceptCookies}
        className="px-4 py-2 text-white rounded bg-blue hover:bg-blue-600"
      >
        {locale === "ar" ? "موافق" : "Accept"}
      </button>
    </div>

*/
