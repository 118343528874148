import personalizeEvents from '@/aws';
import { useSession } from 'next-auth/react';
import { createContext, useCallback, useLayoutEffect, useState } from 'react';
import store from 'store';

const AWSPersonalizeCtx = createContext({
  isDirectConnectToAWS: false,
  firstActionAfterLoginHandler: () => {},
  sendPersonalizeEvent: () => {},
});

export const AWSPersonalizeProvider = ({ children }) => {
  // used if I am not authenticated
  const [sessionId, setSessionId] = useState(store.get('sessionId') || '');

  // handle if start with unauthenticated user then I logged in the first action should has sessionId and userId
  const [isFirstActionAfterLogin, setIsFirstActionAfterLogin] = useState(false);

  const { data: userSession, status } = useSession();

  // handle sessionId if unauthenticated
  useLayoutEffect(() => {
    if (status === 'loading') {
      // Don't do anything while session data is loading
      return;
    }
    if (userSession?.user && !isFirstActionAfterLogin) {
      setSessionId('');
      store.remove('sessionId');
    } else if (!sessionId && !userSession?.user) {
      const generateSessionId = () => {
        return (
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          Date.now()
        );
      };
      const createdSessionId = generateSessionId();
      store.set('sessionId', createdSessionId);
      setSessionId(createdSessionId);
    }
  }, [sessionId, userSession?.user, isFirstActionAfterLogin, status]);

  // activate after login
  const firstActionAfterLoginHandler = () => {
    setIsFirstActionAfterLogin(true);
  };

  // helper for events
  // data param should be Object { itemId: 'prod sku', eventType: 'View' / 'Purchase' }
  const modifyDataHandler = useCallback(
    (data) => {
      const modifiedData = {
        ...(sessionId
          ? { sessionId }
          : userSession?.user
            ? { sessionId: String(userSession?.user?.id) } // don't remove String method cuz direct connect to Aws will fail
            : {}),
        ...(userSession?.user ? { userId: String(userSession?.user?.id) } : {}), // don't remove String method cuz direct connect to Aws will fail
        ...data,
      };
      return modifiedData;
    },
    [sessionId, userSession?.user]
  );

  /// events ///
  // direct connect with aws
  const sendPersonalizeEvent = async (eventList) => {
    const eventTrackerId = process.env.NEXT_PUBLIC_AWS_TRACKING_ID;
    const personalizeEvent = modifyDataHandler({
      trackingId: eventTrackerId,
      eventList, // shape of eventList: [{ eventType: 'View' / 'Purchase', itemId: 'prod sku', sentAt: Date.now() }]
    });
    try {
      await personalizeEvents.putEvents(personalizeEvent).promise();
      setIsFirstActionAfterLogin(false);
    } catch (err) {
      console.error('Error sending event to personalizeEvent:', err);
    }
  };

  return (
    <AWSPersonalizeCtx.Provider
      value={{
        isDirectConnectToAWS:
          status !== 'loading' && (!!sessionId || !!userSession?.user?.id),
        isFirstActionAfterLogin,
        firstActionAfterLoginHandler,
        sendPersonalizeEvent,
      }}
    >
      {children}
    </AWSPersonalizeCtx.Provider>
  );
};

export default AWSPersonalizeCtx;
