import { getAmastyProducts, getProductsBySkus } from '@/services/product';
import { useState } from 'react';
import useSWR from 'swr';

const fetchProducts = async (handshake, parentProduct, skus, type, state) => {
  // عشان ميعملش ريكوست تانى وكده كده الداتا معايا فى PDP
  if (state?.cartRelatedProducts) {
    return state?.cartRelatedProducts;
  } else {
    const amastyRes = await getAmastyProducts(handshake, parentProduct?.sku);
    const amastySKUs = amastyRes?.data?.data?.at(0)?.[type];

    const res = await getProductsBySkus(handshake, amastySKUs, false);
    return (
      res?.data?.data?.products?.filter(
        (product) => product?.stock.is_in_stock
      ) || []
    );
  }
};

export const useRelatedAndUpsellProducts = (
  product,
  handshake,
  language,
  state
) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [upsellProducts, setUpsellProducts] = useState([]);

  useSWR(
    () => `related-products-${product?.sku}-${language}`,
    () =>
      fetchProducts(
        handshake,
        product,
        product?.custom_related_products,
        'related',
        state
      ),
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 30 * 60,
      onSuccess: setRelatedProducts,
      onError: (error) =>
        console.error('error fetching related products:', error),
    }
  );

  useSWR(
    () => `up-sell-products-${product?.sku}-${language}`,
    () => fetchProducts(handshake, product?.up_sell_products, 'upsell', state),
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 30 * 60,
      onSuccess: setUpsellProducts,
      onError: (error) =>
        console.error('error fetching up sell products:', error),
    }
  );

  return { relatedProducts, upsellProducts };
};
