// const CompareIcon = ({ fromPDP, t, ...props }) => (
//   <span className={`flex items-center gap-2 ${!fromPDP && "relative top-1.5"}`}>
//   <svg
//     viewBox="0 0 26 26"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     {...props}
//   >
//     <path
//       d="M9.612 25.02c-3.551 0-6.448-2.827-6.448-6.294s2.897-6.295 6.448-6.295a.74.74 0 0 1 .744.726.74.74 0 0 1-.744.727c-2.738 0-4.96 2.169-4.96 4.842s2.222 4.842 4.96 4.842 4.96-2.17 4.96-4.842a.74.74 0 0 1 .744-.727.74.74 0 0 1 .744.727c0 3.467-2.896 6.294-6.448 6.294"
//       fill="currentColor"
//     />
//     <path
//       d="M17.349 25.02a.713.713 0 0 1-.717-.7c0-.381.325-.698.716-.698 2.761 0 5.016-2.201 5.016-4.896s-2.255-4.896-5.015-4.896c-2.761 0-5.016 2.2-5.016 4.896 0 .382-.325.7-.716.7a.713.713 0 0 1-.717-.7c0-3.47 2.895-6.295 6.449-6.295s6.448 2.826 6.448 6.295-2.895 6.294-6.448 6.294"
//       fill="currentColor"
//     />
//   </svg>
//     {fromPDP && (
//       <span className='mt-1.5 block font-semibold uppercase'>
//         {t('Add To Compare')}
//       </span>
//     )}
//   </span>
// );
// export default CompareIcon;

const CompareIcon = (props) => (
  <svg
    width={107}
    height={64}
    viewBox='0 0 107 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M33.202 63.4976C14.9154 63.4976 0 49.235 0 31.7488C0 14.2625 14.9154 0 33.202 0C35.2963 0 37.033 1.66071 37.033 3.66332C37.033 5.66594 35.2963 7.32664 33.202 7.32664C19.1039 7.32664 7.662 18.2678 7.662 31.7488C7.662 45.2298 19.1039 56.1709 33.202 56.1709C47.3001 56.1709 58.742 45.2298 58.742 31.7488C58.742 29.7462 60.4787 28.0855 62.573 28.0855C64.6673 28.0855 66.404 29.7462 66.404 31.7488C66.404 49.235 51.4886 63.4976 33.202 63.4976Z'
      fill='currentColor'
    />
    <path
      d='M73.0421 63.4976C71.0254 63.4976 69.353 61.8984 69.353 59.9699C69.353 58.0415 71.0254 56.4423 73.0421 56.4423C87.2575 56.4423 98.8659 45.342 98.8659 31.7488C98.8659 18.1556 87.2575 7.05529 73.0421 7.05529C58.8267 7.05529 47.2183 18.1556 47.2183 31.7488C47.2183 33.6772 45.5459 35.2764 43.5292 35.2764C41.5125 35.2764 39.8401 33.6772 39.8401 31.7488C39.8401 14.2517 54.7441 0 73.0421 0C91.3401 0 106.244 14.2517 106.244 31.7488C106.244 49.2459 91.3401 63.4976 73.0421 63.4976Z'
      fill='currentColor'
    />
  </svg>
);
export default CompareIcon;
