import { useEffect, useState } from "react";
import ShareModal from "./ShareModal";
import { useTranslation } from "next-i18next";

const Share = ({ title, description, label }) => {
  const {t} = useTranslation('common')
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({canonical :"", url:""})
  // const canonical = document.querySelector("link[rel=canonocal]");
  // const url = canonical ? canonical.href : document.location.href;
  const shareDetails = { url:config.url, title, description };

  useEffect(() => {
    setConfig({
      canonical : document.querySelector("link[rel=canonocal]"),
      url       : config.canonical ? config.canonical.href : document.location.href
    })
  }, [config.canonical])

  const handleShare = async () => {
    if (navigator.share) {
      try {
        const share = await navigator.share(shareDetails);
        console.log("share", share);
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      setOpen(true);
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  return (
    <div className="relative flex items-center justify-center">
      <button className="sharer-button" onClick={handleShare}>
        <span className="sharer-button-text">{label || t('share')}</span>
      </button>
      <ShareModal open={open} setOpen={setOpen} {...{url:config.url, title, t}} />
    </div>
  );
};

export default Share;
