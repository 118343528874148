import Arrow from '@/components/icons/Arrow';
import BranchesIcon from '@/components/icons/BranchesIcon';
import Heart from '@/components/icons/Heart';
import RecruitmentIcon from '@/components/icons/RecruitmentIcon';
import TrackIcon from '@/components/icons/TrackIcon';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

const TrackBranch = ({ locale, t, onHide, setAuth }) => {
  const { data: session } = useSession();
  const { push } = useRouter();

  return (
    <div className='border-b p-3'>
      <Link
        href={'/user/wishlist'}
        onClick={onHide}
        className='mb-4 flex items-center justify-between'
      >
        <div className='flex items-center gap-2'>
          <Heart className='size-4' />
          <span className='text-sm'>{t('Favourites')}</span>
        </div>
        <Arrow className={`${'ar' ? 'rotate-90' : '-rotate-90'} size-3`} />
      </Link>
      <div
        onClick={() => {
          session?.user ? push('/user/orders') : setAuth('Login');
          onHide();
        }}
        className='mb-4 flex cursor-pointer items-center justify-between'
      >
        <div className='flex items-center gap-2'>
          <TrackIcon className='size-4' />
          <span className='text-sm'>{t('Track Order')}</span>
        </div>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </div>
      <Link
        href={'/branches'}
        onClick={onHide}
        className='mb-4 flex items-center justify-between'
      >
        <div className='flex items-center gap-2'>
          <BranchesIcon className='size-4' />
          <span className='text-sm'>{t('Branches')}</span>
        </div>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </Link>
      <Link
        href={'/static/recruitment'}
        onClick={onHide}
        className='flex items-center justify-between'
      >
        <div className='flex items-center gap-2'>
          <RecruitmentIcon className='size-4' />
          <span className='text-sm'>{t('Recruitment')}</span>
        </div>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </Link>
    </div>
  );
};

export default TrackBranch;
