const TikTokIcon = (props) => (
  <svg
    width={20}
    height={19}
    viewBox='0 0 20 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1335_10482)'>
      <path
        d='M14.0593 0.250977H10.9498V12.819C10.9498 14.3165 9.75383 15.5466 8.2655 15.5466C6.77717 15.5466 5.58121 14.3165 5.58121 12.819C5.58121 11.3483 6.7506 10.145 8.18578 10.0915V6.93614C5.02308 6.98959 2.47168 9.58345 2.47168 12.819C2.47168 16.0814 5.07624 18.702 8.29209 18.702C11.5079 18.702 14.1124 16.0547 14.1124 12.819V6.37456C15.2819 7.23028 16.717 7.73835 18.2319 7.7651V4.6097C15.8932 4.52947 14.0593 2.60415 14.0593 0.250977Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1335_10482'>
        <rect
          width={18.451}
          height={18.451}
          fill='white'
          transform='translate(0.93457 0.250977)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default TikTokIcon;
