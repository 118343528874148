// import { handleConfigurablesGrouping } from "@/utils/product";
import { handleConfigurablesGrouping } from "@/utils/product";
import { useEffect, useState } from "react";

const useHandleAlbumDetails = (product) => {
  const [configurImg, setConfigurImg] = useState(null);
  const [configurSku, setConfigurSku] = useState(null);
  const [configurPrice, setConfigurPrice] = useState(null);
  const [configurSize, setConfigurSize] = useState(null);
  const [configurColor, setConfigurColor] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const [configurables, setConfigurables] = useState({});
  const [selectedIdx, setSelectedIdx] = useState({});
  // console.log("product", {
  //   configuration,
  //   configurables
  // });
  const handleOnClickConfigurable = (info, index, type) => {
    console.log('info, index, type', {info, index, type})
    if (type == "size") {
      setConfigurSize(info);
      setSelectedIdx((prev) => ({ ...prev, [type]: index }));
      setConfiguration((prev) => ({
        ...prev,
        [type]: { option_id: info?.option_id, option_value: info?.swatch_id },
      }));
    }

    if (type == "color") {
      setConfigurSku(info?.sku);
      setConfigurPrice(info?.prices_with_tax);
      setConfigurImg(info?._media_?.image);
      setConfigurColor(info);
      setSelectedIdx((prev) => ({ ...prev, [type]: index }));
      setConfiguration((prev) => ({
        ...prev,
        [type]: { option_id: info?.option_id, option_value: info?.swatch_id },
      }));
    }
    if (type != "color" && type != "size") {
      setConfigurSku(info?.sku);
      setConfigurPrice(info?.prices_with_tax);
      setConfigurImg(info?._media_?.image);
      setConfigurColor(info);
      setSelectedIdx((prev) => ({ ...prev, [type]: index }));
      setConfiguration((prev) => ({
        ...prev,
        [type]: { option_id: info?.option_id, option_value: info?.swatch_id },
      }));
    }
  };

  useEffect(() => {
    if (product?._configurables?.length && !configurColor && !configurSize) {
      setConfiguration((prev) => ({
        ...prev,
        ...(product?._configurables?.at(0)?.option_details?.size?.option_id &&
        product?._configurables?.at(0)?.option_details?.size?.swatch_id
          ? {
              size: {
                option_id:
                  product?._configurables?.at(0)?.option_details?.size
                    ?.option_id,
                option_value:
                  product?._configurables?.at(0)?.option_details?.size
                    ?.swatch_id,
              },
            }
          : {}),
        ...(product?._configurables?.at(0)?.option_details?.color?.option_id &&
        product?._configurables?.at(0)?.option_details?.color?.swatch_id
          ? {
              color: {
                option_id:
                  product?._configurables?.at(0)?.option_details?.color
                    ?.option_id,
                option_value:
                  product?._configurables?.at(0)?.option_details?.color
                    ?.swatch_id,
              },
            }
          : {}),
      }));
    }
    const configurablesGrouping = handleConfigurablesGrouping(
      product?._configurables
    );
    console.log("configurablesGrouping", configurablesGrouping);
    console.log("product?._configurables", product?._configurables);
    setConfigurables(configurablesGrouping);
    if (Object.keys(selectedIdx)?.length == 0) {
      Object.keys(configurablesGrouping || {})?.forEach((item) =>
        setSelectedIdx((prev) => ({ ...prev, [item]: 0 }))
      );
      setConfigurImg(
        Object.values((configurablesGrouping || {})?.color || {})?.at(0)
          ?._media_?.image
      );
      setConfigurSku(Object.values((configurablesGrouping || {})?.color || {})?.at(0)?.sku)
      // console.log('Object.values(configurablesGrouping)', Object.values(configurablesGrouping?.color)[0]?._media_?.image)
    }
  }, [configurColor, configurSize, product?._configurables, selectedIdx]);

  return {
    configurImg,
    configurSku,
    handleOnClickConfigurable,
    configurPrice,
    selectedIdx,
    setSelectedIdx,
    configuration,
    configurables,
  };
};

export default useHandleAlbumDetails;
