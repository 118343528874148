import { useContext } from 'react';
import { CheckoutContext } from './provider';

const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error('useCheckout must be used within a Checkout Provider');
  }
  return context;
};

export { useCheckout };
