import * as React from "react";
const RecruitmentIcon = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0834 2.5H8.33341V1.91667C8.33341 1.60725 8.2105 1.3105 7.99171 1.09171C7.77291 0.872916 7.47617 0.75 7.16675 0.75H4.83341C4.524 0.75 4.22725 0.872916 4.00846 1.09171C3.78966 1.3105 3.66675 1.60725 3.66675 1.91667V2.5H1.91675C1.45262 2.5 1.0075 2.68437 0.679311 3.01256C0.351123 3.34075 0.166748 3.78587 0.166748 4.25V9.5C0.166748 9.96413 0.351123 10.4092 0.679311 10.7374C1.0075 11.0656 1.45262 11.25 1.91675 11.25H10.0834C10.5475 11.25 10.9927 11.0656 11.3209 10.7374C11.649 10.4092 11.8334 9.96413 11.8334 9.5V4.25C11.8334 3.78587 11.649 3.34075 11.3209 3.01256C10.9927 2.68437 10.5475 2.5 10.0834 2.5ZM4.83341 1.91667H7.16675V2.5H4.83341V1.91667ZM10.6667 9.5C10.6667 9.65471 10.6053 9.80308 10.4959 9.91248C10.3865 10.0219 10.2381 10.0833 10.0834 10.0833H1.91675C1.76204 10.0833 1.61367 10.0219 1.50427 9.91248C1.39487 9.80308 1.33341 9.65471 1.33341 9.5V6.58333H3.66675V7.16667C3.66675 7.32138 3.72821 7.46975 3.8376 7.57915C3.947 7.68854 4.09537 7.75 4.25008 7.75C4.40479 7.75 4.55316 7.68854 4.66256 7.57915C4.77196 7.46975 4.83341 7.32138 4.83341 7.16667V6.58333H7.16675V7.16667C7.16675 7.32138 7.22821 7.46975 7.3376 7.57915C7.447 7.68854 7.59537 7.75 7.75008 7.75C7.90479 7.75 8.05316 7.68854 8.16256 7.57915C8.27196 7.46975 8.33341 7.32138 8.33341 7.16667V6.58333H10.6667V9.5ZM10.6667 5.41667H1.33341V4.25C1.33341 4.09529 1.39487 3.94692 1.50427 3.83752C1.61367 3.72812 1.76204 3.66667 1.91675 3.66667H10.0834C10.2381 3.66667 10.3865 3.72812 10.4959 3.83752C10.6053 3.94692 10.6667 4.09529 10.6667 4.25V5.41667Z"
      fill="currentColor"
    />
  </svg>
);
export default RecruitmentIcon;
