import React, { useMemo, useRef, useState } from 'react';
import Modal from '@/components/modals';
import useHandleOutSide from '@/hooks/useHandleOutSide';
// import CloseIcon from '../../icons/CloseIcon';
import Album from '../../product/Album';
import { useTranslation } from 'next-i18next';
import ExtraAttributes from '../../product/ExtraAttributes';
import PriceRates from '../../product/PriceRates';
import AddToCartSection from '../../product/AddToCartSection';
import FollowUs from './FollowUs';
import Link from 'next/link';
import Configurables from '@/components/product/Configurables';
import useHandleAlbumDetails from '@/hooks/product/useHandleAlbumDetails';
import { productConfigurablesHandler } from '@/utils/product';
import AddtoWishlist from '@/components/global/buttons/AddtoWishlist';
import AddToCompare from '@/components/product/AddToCompare';

const ProductQuickView = ({ onHide, show, product, img }) => {
  const ref = useRef();
  useHandleOutSide(ref, () => onHide());
  const { t } = useTranslation('common');
  const [qty, setQty] = useState(1);

  const modifiedProduct = useMemo(() => {
    return productConfigurablesHandler(product);
  }, [product]);

  const {
    configurSku,
    handleOnClickConfigurable,
    configurPrice,
    selectedIdx,
    configuration,
    configurables,
  } = useHandleAlbumDetails(modifiedProduct);

  const extraAttributes = {
    brand: product?.option_text_brand?.at(0),
    brand_url: `/${product?.option_text_brand?.at(0)}-c-${product?.brand?.at(0) || ''}`,
    in_stock: product?.stock?.is_in_stock == true ? true : false,
    brandImage: product?.brand_logo || null,
    color: product?.option_text_color?.at(0),
    category_url: product?.brand_url ? product?.brand_url : `#`,
    sku: configurSku
      ? configurSku
      : Array.isArray(product?.sku)
        ? product?.sku?.at(0)
        : product?.sku,
  };

  const sku = configurSku
    ? configurSku
    : Array.isArray(product?.sku)
      ? product?.sku?.at(0)
      : product?.sku;

  return (
    <Modal show={show} isCenter>
      <div
        ref={ref}
        className='gray-scroll relative max-h-[95vh] min-h-fit w-9/12 overflow-y-auto bg-white p-3'
      >
        {/* <div className='flex items-center justify-end'>
          <CloseIcon
            className='size-6 cursor-pointer'
            onClick={() =>
              setTimeout(() => {
                onHide();
              }, 100)
            }
          />
        </div> */}

        <div className='bg-white max-md:max-w-full max-md:px-5'>
          <div className='flex gap-5 max-md:flex-col max-md:gap-0'>
            <div className='flex w-[41%] flex-col max-md:ml-0 max-md:w-full'>
              <section className='mt-6 flex flex-col max-md:mt-10'>
                <Album t={t} product={product} fromQuickView img={img} />
              </section>
            </div>
            <div className='ml-5 flex w-[59%] flex-col max-md:ml-0 max-md:w-full'>
              <section className='flex grow flex-col max-md:mt-10 max-md:max-w-full'>
                <header className='flex gap-5 px-0.5 text-xl font-bold text-black max-md:max-w-full max-md:flex-wrap'>
                  <h1 className='mt-6 flex-auto self-end'>
                    {product?.name?.at(0)}
                  </h1>
                </header>
                <ExtraAttributes t={t} attributes={extraAttributes} />

                <PriceRates
                  t={t}
                  className='mt-7'
                  priceWithTax={
                    configurPrice ? configurPrice : product?.prices_with_tax
                  }
                  ratingScore={product?.reviews_count}
                  reviews_count={product?.reviews_count || 0}
                  show_discount_price={product?.show_discount_price || null}
                />

                <hr className='mt-7' />

                {product?.has_options && (
                  <Configurables
                    {...{
                      handleOnClickConfigurable,
                      selectedIdx,
                      t,
                      configurables,
                    }}
                  />
                )}

                <div className='mt-5 flex items-center justify-start gap-5 max-md:hidden'>
                  <div className='flex items-center gap-2'>
                    <AddtoWishlist product={product} fromPDP t={t} />
                  </div>
                  <div className='flex items-center gap-2'>
                    <AddToCompare
                      product={product}
                      t={t}
                      iconClassName={'mb-1 size-5'}
                      fromPDP
                    />
                  </div>
                </div>

                <div className='mt-7'>
                  <AddToCartSection
                    {...{
                      configurations: product?._configurables?.length
                        ? configuration
                        : null,
                      product,
                      sku,
                      qty,
                      setQty,
                    }}
                  />
                </div>
                <footer className='mt-7 flex w-full gap-5 text-sm font-bold capitalize max-md:max-w-full max-md:flex-wrap'>
                  <div className='flex flex-auto gap-4 text-black'>
                    <FollowUs t={t} />
                  </div>
                  <Link
                    href={`/product/${product?.rewrite_url}`}
                    className='flex-auto text-end text-rose-600 underline'
                  >
                    {t('View Full Details')}
                  </Link>
                </footer>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductQuickView;
