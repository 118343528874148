import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import CategoryCard from '@/components/cards/CategoryCard';
import { handleCategoryImageURL } from '@/helpers/category';

const Category = ({ language, categories }) => {
  return (
    categories?.length > 0 && (
      <section className={`${'mx-auto my-7 w-11/12'}`}>
        <Swiper
          key={language}
          dir={language === 'en' ? 'ltr' : 'rtl'}
          breakpoints={{
            320: {
              slidesPerView: 3,
            },
            540: {
              slidesPerView: 4,
            },
            769: {
              slidesPerView: 5,
            },
            961: {
              slidesPerView: 7,
            },
            1200: {
              slidesPerView: 9,
            },
          }}
          spaceBetween={20}
          className='my-7'
          modules={[Autoplay]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop
        >
          {categories.map((category, idx) => {
            return (
              <SwiperSlide key={`category-global-swiper-${idx}`}>
                <CategoryCard
                  img={
                    handleCategoryImageURL(category?.image) ||
                    '/imgs/placeholder.png'
                  }
                  name={category['name']}
                  url={category['url_key']}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    )
  );
};

export default Category;
