import { isMaskCart } from '@/helpers/common/IsMaskCart';
import axios from '@/utils/axios-config';

/*
===============================|> All Cart APis <|===============================
01 |--> create cart,
02 |--> get cart,
03 |--> add item to cart,
04 |--> add bundle products to cart,
05 |--> update cart item,
06 |--> delete cart item,
07 |--> Aapply coupon,
08 |--> delete coupon,
09 |--> merge guest cart
10 |--> add product fees
11 |--> delete product fees
12 |--> validate voucher coupon
13 |--> apply voucher coupon
14 |--> clear cart if region changed
15 |--> check if all cart items available to checkout
===============================|> All Cart APis <|===============================
*/

// 01- ============= create cart
const createCart = async (handshake, mage) => {
  let config = {
    method: 'post',
    url: '/cart/create',
    headers: {
      Authorization: `Bearer ${handshake}`,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
    data: JSON.stringify({ device: 'web' }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 02- =============  get cart
const getCart = async (handshake, mage, quote) => {
  let config = {
    method: 'get',
    url: '/cart/details/total',
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 03- ============= Add item to cart
const addItemToCart = async (
  handshake,
  mage,
  quote,
  sku,
  qty,
  configurable_item_options
) => {
  let data = {
    sku,
    qty: qty,
    ...(Object.keys(configurable_item_options)?.length
      ? {
          product_option: {
            extension_attributes: {
              configurable_item_options,
            },
          },
        }
      : {}),
    // extension_attributes: {
    //     region: region_id
    // }
  };
  let config = {
    method: 'post',
    url: '/cart/add/items/v2',
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
    data: JSON.stringify({ cartItem: data, device: 'Web' }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 04- ============= Add multi items to cart
const addMultiItemsToCart = async (handshake, mage, quote, skus, region_id) => {
  const cartItems = skus.map((sku) => ({
    sku,
    qty: 1,
    extension_attributes: { region: region_id },
  }));
  let config = {
    method: 'post',
    url: '/cart/multi/items',
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
    data: JSON.stringify({ cartItems }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 05- ============= Update cart item
const updateCartItem = async (handshake, mage, quote, itemId, qty) => {
  let config = {
    method: 'put',
    url: `/cart/update/items/${itemId}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
    data: JSON.stringify({
      cartItem: { qty, item_id: itemId },
    }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 06- ============= Delete cart item
const deleteCartItem = async (handshake, mage, quote, itemId) => {
  let config = {
    method: 'delete',
    url: `/cart/delete/items/${itemId}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
    data: JSON.stringify({ itemId }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 07- ============= Apply coupon code
const applyCoupon = async (handshake, mage, quote, couponCode) => {
  let config = {
    method: 'put',
    url: `/customer/applyCoupon/${couponCode}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 08- ============= Delete coupon
const deleteCoupon = async (handshake, mage, quote) => {
  let config = {
    method: 'delete',
    url: '/customer/deleteCoupon',
    headers: {
      Authorization: `Bearer ${handshake}`,
      quote: 'Bearer ' + quote,
      ...(mage ? { mage: `Bearer ${mage}` } : {}),
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 09- ============= merge guest user cart to logged in user cart
const mergeMaskedCart = async (handshake, mage, quote, storeId, customerId) => {
  let config = {
    method: 'put',
    url: '/cart/mergeQuote/' + quote,
    headers: {
      Authorization: `Bearer ${handshake}`,
      mage: 'Bearer ' + mage,
    },
    data: JSON.stringify({ customerId, storeId }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 10- ============= Add product fees
const addProductFees = async (handshake, quote, itemId, id, options) => {
  let config = {
    method: 'post',
    url: `/fees/setproductfees/${quote}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
    data: JSON.stringify({ feeData: { itemId, id, options } }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 11- ============= remove product fees
const deleteProductFees = async (handshake, quote, itemId, id) => {
  let config = {
    method: 'post',
    url: `/fees/removeproductfees/${quote}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
    data: JSON.stringify({ feeData: { itemId, id } }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 12- ============= validate voucher coupon
const validateErpVoucher = async (handshake, phoneno, voucher) => {
  let config = {
    method: 'post',
    url: '/erp/validate-erp-voucher',
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
    data: JSON.stringify({ phoneno, voucher }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 13- ============= apply voucher coupon
const applyErpVoucher = async (handshake, quoteId, phoneno, voucher) => {
  const isMask = isMaskCart(quoteId);
  let config = {
    method: 'post',
    url: '/erp/apply-erp-voucher',
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
    data: JSON.stringify({ phoneno, voucher, quoteId, isMask }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 14- ============= clear current cart --> use when change region
const clearCart = async (handshake, quote) => {
  const isMask = isMaskCart(quote) ? '0' : '1';
  let config = {
    method: 'get',
    url: `/cart/clearCart/${quote}/${isMask}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 13- ============= apply voucher coupon
const availableToCheckoutV2 = async (handshake, regionId, cartItems) => {
  let config = {
    method: 'post',
    url:
      process.env.NEXT_PUBLIC_API_BASEURL +
      '/mstore/available-to-checkout-region-v2',
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
    data: JSON.stringify({
      region: regionId,
      skus: cartItems,
    }),
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 14- =============  get Product Stocks
const getProductStock = async (handshake, skus) => {
  const data = JSON.stringify({ skus });
  const config = {
    method: 'post',
    url: '/product/stock',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// export all functions
export {
  createCart,
  getCart,
  addItemToCart,
  addMultiItemsToCart,
  updateCartItem,
  deleteCartItem,
  applyCoupon,
  deleteCoupon,
  mergeMaskedCart,
  addProductFees,
  deleteProductFees,
  validateErpVoucher,
  applyErpVoucher,
  clearCart,
  availableToCheckoutV2,
  getProductStock,
};
