import { deleteCartItem, updateCartItem } from '@/services/cart';
import { getProductsBySkus } from '@/services/product';
import { getCookies } from 'cookies-next';
import toast from 'react-hot-toast';

const { handshake, mage, quote } = getCookies();

export const updateCartItemQty = async (itemId, qty) => {
  const { data, error } = await updateCartItem(
    handshake,
    mage,
    quote,
    itemId,
    qty
  );
  if (data?.data?.item_id) {
    toast.success(data?.message);
    return true;
  }
  toast.error(error?.error?.message);
  return false;
};

export const removeCartItem = async (itemId) => {
  const { data, error } = await deleteCartItem(handshake, mage, quote, itemId);
  if (data?.data) {
    toast.success(data?.message);
    return true;
  }
  toast.error(error?.error?.message);
  return false;
};

// handle related products
export const getRelatedProducts = async (dispatch, product) => {
  if (product?.custom_related_products?.length > 0) {
    const skus = product?.custom_related_products;
    const relatedProductsResponse = await getProductsBySkus(handshake, skus);
    dispatch({
      type: 'related_products',
      payload: relatedProductsResponse?.data?.data?.products,
    });
  } else {
    dispatch({ type: 'related_products', payload: [] });
  }
};
