import { postFetcher } from "@/services/global";
import { useRouter } from "next/router";
import { useCallback } from "react";
import useSWRMutation from "swr/mutation";

const useSortProducts = ({ setAllSortingKeys, setAllProducts, categoryID, setMoreProductsIsLoading, allSortingKeys, allFilterKeys, fromCategory = true, setPageIndex, word = '' }) => {
  const { locale } = useRouter();
  const res = useSWRMutation(
    fromCategory ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}&${allSortingKeys}` : `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${allFilterKeys}`,
    postFetcher,
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const handleSorting = useCallback(
    async ({ keys }) => {
      console.log('keys', keys)
      setAllSortingKeys(keys)
      setPageIndex(1)
      let result = await res?.trigger(fromCategory ? {
        pageNo: 0,
        pageSize: 30,
        categoryID,
        locale,
        filterKeys: allFilterKeys,
        sortKeys: keys,
      }: {
        pageNo: 0,
        pageSize: 30,
        filterKeys: allFilterKeys,
        sortKeys: keys,
        word,
      });

      // console.log("handleSorting", {
      //   pageNo: 0,
      //   pageSize: 30,
      //   filterKeys: allFilterKeys,
      //   sortKeys: keys,
      //   word,
      // }, result?.products);

      setAllProducts(result?.products?.length && result?.products != undefined ? result?.products : []);
      setMoreProductsIsLoading(false) // to getting more products after scrolling
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilterKeys, categoryID, res, setAllProducts, setAllSortingKeys]
  );

  return {
    handleSorting,
  };
};

export default useSortProducts;
