const BurgerMenu = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.96 7.61431H3.04C2.63182 7.61431 2.29333 7.28027 2.29333 6.87747C2.29333 6.47466 2.63182 6.14062 3.04 6.14062H20.96C21.3682 6.14062 21.7067 6.47466 21.7067 6.87747C21.7067 7.28027 21.3682 7.61431 20.96 7.61431Z'
      fill='currentColor'
    />
    <path
      d='M20.96 12.5264H3.04C2.63182 12.5264 2.29333 12.1924 2.29333 11.7896C2.29333 11.3868 2.63182 11.0527 3.04 11.0527H20.96C21.3682 11.0527 21.7067 11.3868 21.7067 11.7896C21.7067 12.1924 21.3682 12.5264 20.96 12.5264Z'
      fill='currentColor'
    />
    <path
      d='M20.96 17.4385H3.04C2.63182 17.4385 2.29333 17.1045 2.29333 16.7017C2.29333 16.2989 2.63182 15.9648 3.04 15.9648H20.96C21.3682 15.9648 21.7067 16.2989 21.7067 16.7017C21.7067 17.1045 21.3682 17.4385 20.96 17.4385Z'
      fill='currentColor'
    />
  </svg>
);
export default BurgerMenu;
