import ProductCard from '../cards/ProductCard';

const ProductsList = ({ allProducts, ratings, categoryID, categoryName }) => {
  // const {query} = useRouter()
  // useEffect(() => {
  //   const item = allProducts?.at(0)
  //   const subset = item && query.gtm ?(({ name, sku, _category_, prices_with_tax, option_text_brand, stock}) => ({ item_name:name?.at(0), item_id:sku, item_category:_category_?.main_category?.name, price: prices_with_tax?.price?.toFixed(2), item_brand: option_text_brand[0], quantity: stock?.qty, discount:null }))(item) : null
  //   console.log("name", query)
  //   query.gtm && event_view_promotion(query?.creative_name, query?.creative_slot, query.promotion_id, null, "SAR", [subset])
  //   }, [])
  return (
    <div className='mx-auto grid grid-cols-2 gap-3 max-sm:order-2 max-sm:mx-auto max-sm:w-[97%] sm:grid-cols-3 md:my-4 md:grid-cols-4 min-[1500px]:grid-cols-5'>
      {allProducts?.map((product, i) => (
        <ProductCard
          key={`category-product-${i}`}
          {...{ categoryID, categoryName, ratings, product }}
          isPriority={i <= 7}
        />
      ))}
      <div id='afterProductsSection' className='w-full'></div>
    </div>
  );
};

export default ProductsList;
