import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react'
import useSortProducts from '../category/useSortProducts';
import useInfiniteProducts from '../category/useInfiniteProducts';
import useFilterProducts from '../category/useFilterProducts';
import useSWRMutation from 'swr/mutation';
import { postFetcher } from '@/services/global';
// import useOnClickOutsideV2 from '../global/useClickOutside';
import useHandleOutSide from '../useHandleOutSide';
import { event_product_searched } from '@/utils/gtm';
import useClickOutside from '../global/useClickOutside';
// import useClickOutside from '../global/useClickOutside';

const useSearchBox = (ourSearchContainer, ourSearchInput, ourSearchButton) => {
    const [inputValue, setInputValue] = useState("")
    const [suggestedProducts, setSuggestedProducts] = useState([])
    const [products, setProducts] = useState([])
    const [loadingSearch, setLoadingSearch] = useState(true)
    const [totalProducts, setTotalProducts] = useState(0)
    const { events } = useRouter()
    const ref = useRef();
    const [filter, setFilter] = useState(false);
    useHandleOutSide(ref, () => {
        setAllFilters([])
        setAllProducts([])
        setTotalProducts(0)
        setLoadingSearch(true)
        setInputValue('')
        setFilter(false)
    });

    const res = useSWRMutation(
        `/api/search?word=${inputValue}`, postFetcher,
        {
            revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            populateCache: true,
        }
    );
    
    const { allProducts, setAllProducts, allFilterKeys, setAllFilterKeys, allSortingKeys, setAllSortingKeys, setPageIndex } = useInfiniteProducts(
        { products, word: inputValue, fromCategory: false }
    );

    const { selectedFilterKeys, setSelectedFilterKeys, handleFilter, handleResetFilter, allFilters, setAllFilters } = useFilterProducts({
        setAllFilterKeys,
        allFilterKeys,
        setAllProducts,
        setAllSortingKeys,
        products,
        fromCategory: false,
        allSortingKeys,
        filters: "filterList",
        setPageIndex,
        word: inputValue
    });
    // console.log('allProducts in useSearchBox', allProducts);
    // console.log('allFilters in useSearchBox', allFilters);
      const {handleSorting} = useSortProducts({setAllSortingKeys, setAllProducts, fromCategory: false, allSortingKeys, allFilterKeys, setPageIndex, word: inputValue})

    useEffect(() => {
        events.on('routeChangeComplete', () => {
            setAllFilters([])
            setAllProducts([])
            setTotalProducts(0)
            setLoadingSearch(true)
            setInputValue('')
        })

    }, [events, setAllFilters, setAllProducts])

    useClickOutside([ourSearchContainer, ourSearchInput, ourSearchButton], () => {
        setAllFilters([])
        setAllProducts([])
        setTotalProducts(0)
        setLoadingSearch(true)
        setInputValue('')
    })

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            const fetchProductsBySearchWord = async () => {
                setLoadingSearch(true)
                
                if (inputValue && inputValue != undefined) {
                    let result = await res?.trigger({
                        pageNo: 0,
                        filterKeys: allFilterKeys,
                        sortKeys: allSortingKeys,
                        word: inputValue,
                    });
                    event_product_searched(inputValue, res?.data?.totalProducts)
                    console.log("params /////////", res)
                    // console.log("fetchProductsBySearchWord", {
                    //     pageNo: 0,
                    //     pageSize: 30,
                    //     filterKeys: allFilterKeys,
                    //     sortKeys: allSortingKeys,
                    //     word: inputValue,
                    // });
                    
                    setSuggestedProducts(
                        result?.suggestion &&
                          result?.suggestion?.["product_suggestions"] !== undefined
                          ? result?.suggestion?.["product_suggestions"]
                          : []
                    );
                    setAllFilters(result?.aggregate?.length && result?.aggregate != undefined ? result?.aggregate : []);
                    setAllProducts(result?.products?.length && result?.products != undefined ? result?.products : []);
                    setProducts(result?.products?.length && result?.products != undefined ? result?.products : []);
                    setTotalProducts(result?.totalProducts != undefined ? result?.totalProducts : 0);
                    setLoadingSearch(false)
                    
                } else {
                    setAllFilters([])
                    setAllProducts([])
                    setProducts([])
                    setTotalProducts(0)
                    setLoadingSearch(true)
                }
            }
            fetchProductsBySearchWord()
        }, 500)

        return () => clearTimeout(debounceTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, setInputValue])

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if the pressed key is the Esc key (key code 27)
            if (event.key === 'Escape' || event.keyCode === 27) {
                setAllFilters([])
                setAllProducts([])
                setTotalProducts(0)
                setLoadingSearch(false)
                setInputValue('')
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        inputValue,
        setInputValue,
        loadingSearch,
        allProducts,
        handleSorting,
        totalProducts,
        selectedFilterKeys,
        setSelectedFilterKeys,
        handleFilter,
        handleResetFilter,
        allFilters,
        ref,
        filter,
        setFilter,
        suggestedProducts,
    }
}

export default useSearchBox