import {postFetcher } from "@/services/global";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";

const useFilterProducts = ({ setAllFilterKeys, setAllProducts, allSortingKeys, categoryID, setMoreProductsIsLoading, filters, products, fromCategory = true, word = '', setPageIndex, setAllSortingKeys }) => {
  const [selectedFilterKeys, setSelectedFilterKeys] = useState([]);
  const [allFilters, setAllFilters] = useState(filters);
  const { locale } = useRouter();
  const res = useSWRMutation(
    fromCategory ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}` : `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${selectedFilterKeys?.join("&")}`,
    postFetcher,
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );
  
  useEffect(() => {
    setAllFilters(filters)
  }, [filters]);

  useEffect(() => {
    setSelectedFilterKeys([])
    setAllFilterKeys("")
    return () => {
      setSelectedFilterKeys([])
      setAllFilterKeys("")
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useCallback(
    async ({ code, item }) => {
      console.log('code, item useFilterProducts', code, item)
      let filterKeys = ''
      if (selectedFilterKeys?.includes(`${code}=${item?.Key}`)) {
        const updatedSelectedFilterKeys = selectedFilterKeys?.filter(
          (itemF) => itemF != `${code}=${item?.Key}`
        );
        setSelectedFilterKeys(updatedSelectedFilterKeys);
        filterKeys = `${updatedSelectedFilterKeys?.join("&")}`
      } else {
        setSelectedFilterKeys((prev) => [...prev, `${code}=${item?.Key}`]);
        filterKeys = `${selectedFilterKeys?.join("&")}&${code}=${item?.Key}`
      }

      setAllFilterKeys(filterKeys)
      // console.log('filterKeys', filterKeys);
      setPageIndex(1)

      let result = await res?.trigger(fromCategory ? {
        pageNo: 0,
        pageSize: 30,
        categoryID,
        locale,
        filterKeys,
        sortKeys: allSortingKeys,
      }: {
        pageNo: 0,
        pageSize: 30,
        sortKeys: allSortingKeys,
        word,
        filterKeys,
        sortKeys: allSortingKeys,
      });
      
    //   console.log("fetchProductsBySearchWord", {
    //     pageNo: 0,
    //     pageSize: 30,
    //     filterKeys,
    //     sortKeys: allSortingKeys,
    //     word,
    // });

      // console.log('filterKeys', {
      //   sortKeys: allSortingKeys,
      //   word,
      //   filterKeys
      // });
    
      result?.products?.length && result?.products != undefined && setAllProducts(result?.products);
      setMoreProductsIsLoading(false) // to getting more products after scrolling
      // result?.aggregate?.length && result?.aggregate != undefined && setAllFilters(result?.aggregate);

    },
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSortingKeys, categoryID, fromCategory, locale, res, selectedFilterKeys, word]
  );
  
  const handleResetFilter = async () => {
    setSelectedFilterKeys([])
    setAllSortingKeys('sortBy=price&sortDir=DESC')
    setAllProducts(products)
    let result = await res?.trigger(fromCategory ? {
      pageNo: 0,
      pageSize: 30,
      categoryID,
      filterKeys: ""
    }: {
      pageNo: 0,
      pageSize: 30,
      sortKeys: allSortingKeys,
      word,
      filterKeys: ""
    });
    
    result?.products?.length && result?.products != undefined && setAllProducts(result?.products);
    // if (fromCategory) {
    // } else {
    //   handleResetFilterSearch()
    // }
  }

  return {
    selectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    setAllFilters
  };
};

export default useFilterProducts;
