import Link from 'next/link';
import Image from '../Image';
import { useRouter } from 'next/router';
import { useGlobalData } from '@/context/global';

const ExtraAttributes = ({ t, attributes, brandId }) => {
  const {
    appData: { categoryData },
  } = useGlobalData();
  const brandsCat = categoryData?.children_data?.find(cat => cat.url_key.startsWith("brands") )
  const { locale } = useRouter();

  console.log(brandsCat)
  return (
    <div className='items-center gap-3 md:flex'>
      {attributes?.brandImage ? (
        <Link
          onClick={(e) => {
            attributes?.brand_url == null && e.preventDefault();
          }}
          href={
            attributes?.brand_url 
              ? `/${brandsCat?.url_key}?brand=${brandId}`
              : '#'
          }
        >
          <Image
            loading='eager'
            src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/${attributes?.brandImage}`}
            className={`h-8 w-32 object-contain ${attributes?.brand_url ? '' : 'cursor-default'} max-md:mb-3  md:h-6 md:pe-3  lg:h-12`}
            alt='Brand Logo!'
          />
        </Link>
      ) : null}
      <div className='flex items-center gap-3 text-sm md:text-base'>
        <div
          className={`relative flex items-center gap-1 pe-3 after:absolute after:h-[15px] after:w-[2px] after:bg-[#03BB86] after:content-[""] md:before:absolute md:before:h-[15px] md:before:w-[2px] md:before:bg-[#03BB86] md:before:content-[""] ${locale === 'en' ? 'after:right-0 md:before:-left-[13px]' : 'after:left-0 md:before:-right-[13px]'}`}
        >
          <span className='text-zinc-500'>{t('Model')} :</span>
          <span className='font-semibold'>{attributes?.brand}</span>
        </div>
        <div className='flex items-center gap-1'>
          <span className='text-zinc-500'>{t('SKU')} :</span>
          <span className='font-semibold'>{attributes?.sku}</span>
        </div>
      </div>
    </div>
  );
};

export default ExtraAttributes;
