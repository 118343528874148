import CallIcon from '@/components/icons/CallIcon';
import EmailIcon from '@/components/icons/EmailIcon';
import WhatsAppIcon from '@/components/icons/WhatsAppIcon';

const CallWhatsEmail = ({t}) => {
  return (
    <div className='grid grid-cols-3 gap-1 mb-4'>
      <div className='flex items-center justify-center gap-1 rounded-sm border px-1 py-1.5'>
        <CallIcon className='size-3' />
        <span className='text-xs'>{t('Call Us')}</span>
      </div>
      <div className='flex items-center justify-center gap-1 rounded-sm border px-1 py-1.5'>
        <WhatsAppIcon className='size-3' />
        <span className='text-xs'>{t('WhatsApp')}</span>
      </div>
      <div className='flex items-center justify-center gap-1 rounded-sm border px-1 py-1.5'>
        <EmailIcon className='size-3' />
        <span className='text-xs'>{t('EmailIcon')}</span>
      </div>
    </div>
  );
};

export default CallWhatsEmail;
