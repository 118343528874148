const Global = (props) => (
  <svg
    width={14}
    height={14}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.00033 13.2709C3.54116 13.2709 0.729492 10.4592 0.729492 7.00008C0.729492 3.54091 3.54116 0.729248 7.00033 0.729248C10.4595 0.729248 13.2712 3.54091 13.2712 7.00008C13.2712 10.4592 10.4595 13.2709 7.00033 13.2709ZM7.00033 1.60425C4.02533 1.60425 1.60449 4.02508 1.60449 7.00008C1.60449 9.97508 4.02533 12.3959 7.00033 12.3959C9.97533 12.3959 12.3962 9.97508 12.3962 7.00008C12.3962 4.02508 9.97533 1.60425 7.00033 1.60425Z'
      fill='currentColor'
    />
    <path
      d='M5.24965 12.6875H4.66632C4.42715 12.6875 4.22882 12.4892 4.22882 12.25C4.22882 12.0108 4.41548 11.8183 4.65465 11.8125C3.73882 8.68583 3.73882 5.31417 4.65465 2.1875C4.41548 2.18167 4.22882 1.98917 4.22882 1.75C4.22882 1.51083 4.42715 1.3125 4.66632 1.3125H5.24965C5.38965 1.3125 5.52381 1.3825 5.60548 1.49333C5.68715 1.61 5.71048 1.75583 5.66382 1.89C4.56715 5.18583 4.56715 8.81417 5.66382 12.1158C5.71048 12.25 5.68715 12.3958 5.60548 12.5125C5.52381 12.6175 5.38965 12.6875 5.24965 12.6875Z'
      fill='currentColor'
    />
    <path
      d='M8.75039 12.6876C8.70373 12.6876 8.65706 12.6817 8.61039 12.6642C8.38289 12.5884 8.25456 12.3376 8.33623 12.1101C9.43289 8.81423 9.43289 5.1859 8.33623 1.88423C8.26039 1.65673 8.38289 1.4059 8.61039 1.33006C8.84373 1.25423 9.08873 1.37673 9.16456 1.60423C10.3254 5.0809 10.3254 8.90756 9.16456 12.3784C9.10623 12.5709 8.93123 12.6876 8.75039 12.6876Z'
      fill='currentColor'
    />
    <path
      d='M7 10.0333C5.3725 10.0333 3.75083 9.8058 2.1875 9.34496C2.18167 9.5783 1.98917 9.7708 1.75 9.7708C1.51083 9.7708 1.3125 9.57246 1.3125 9.3333V8.74996C1.3125 8.60996 1.3825 8.4758 1.49333 8.39413C1.61 8.31246 1.75583 8.28913 1.89 8.3358C5.18583 9.43246 8.82 9.43246 12.1158 8.3358C12.25 8.28913 12.3958 8.31246 12.5125 8.39413C12.6292 8.4758 12.6933 8.60996 12.6933 8.74996V9.3333C12.6933 9.57246 12.495 9.7708 12.2558 9.7708C12.0167 9.7708 11.8242 9.58413 11.8183 9.34496C10.2492 9.8058 8.6275 10.0333 7 10.0333Z'
      fill='currentColor'
    />
    <path
      d='M12.2502 5.68738C12.2035 5.68738 12.1569 5.68155 12.1102 5.66405C8.81437 4.56738 5.1802 4.56738 1.88437 5.66405C1.65103 5.73988 1.40603 5.61738 1.3302 5.38988C1.2602 5.15655 1.3827 4.91155 1.6102 4.83571C5.08687 3.67488 8.91353 3.67488 12.3844 4.83571C12.6119 4.91155 12.7402 5.16238 12.6585 5.38988C12.606 5.57071 12.431 5.68738 12.2502 5.68738Z'
      fill='currentColor'
    />
  </svg>
);
export default Global;
