const CheckComIcon = (props) => (
  <svg
    width={50}
    height={36}
    viewBox='0 0 50 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.8964 36C16.8959 36 15.9455 35.5982 15.2451 34.895L1.08804 20.6808C-0.362681 19.2243 -0.362681 16.8134 1.08804 15.3568C2.53877 13.9002 4.93997 13.9002 6.39069 15.3568L17.8964 26.909L43.6093 1.09243C45.06 -0.364144 47.4612 -0.364144 48.912 1.09243C50.3627 2.54901 50.3627 4.95989 48.912 6.41646L20.5478 34.895C19.8474 35.5982 18.8969 36 17.8964 36Z'
      fill='currentColor'
    />
  </svg>
);
export default CheckComIcon;
