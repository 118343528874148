const StarsRateIcon = ({ rate, width = 20, height = 20, ...props }) => {
  const totalStars = 5;
  const filledStars = Math.floor(rate);

  const starPath = `
    M10 0
    L12.368 6.5
    L19 7.5
    L14.736 12.16
    L16 19.5
    L10 15
    L4 19.5
    L5.264 12.16
    L1 7.5
    L7.632 6.5
    Z
  `;

  return (
    <svg
      width={totalStars * width}
      height={height}
      viewBox={`0 0 ${totalStars * 20} 20`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {[...Array(totalStars)].map((_, index) => (
        <path
          key={`custom-start-rating-${index}`}
          d={starPath}
          transform={`translate(${index * 20}, 0)`}
          fill={index < filledStars ? '#F1C40F' : '#DDE1E8'}
        />
      ))}
    </svg>
  );
};

export default StarsRateIcon;
