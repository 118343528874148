const AddIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.75 7.5H0.75C0.34 7.5 0 7.16 0 6.75C0 6.34 0.34 6 0.75 6H12.75C13.16 6 13.5 6.34 13.5 6.75C13.5 7.16 13.16 7.5 12.75 7.5Z'
      fill='currentColor'
    />
    <path
      d='M6.75 13.5C6.34 13.5 6 13.16 6 12.75V0.75C6 0.34 6.34 0 6.75 0C7.16 0 7.5 0.34 7.5 0.75V12.75C7.5 13.16 7.16 13.5 6.75 13.5Z'
      fill='currentColor'
    />
  </svg>
);
export default AddIcon;
