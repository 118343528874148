import { otpValidation } from '@/utils/valitation/auth';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useRouter } from 'next/router';
import React from 'react';

const MobileOtpRegister = ({ t, handleOtp, loading }) => {
  const { locale } = useRouter();

  return (
    <>
      <div className='my-2.5 text-center text-sm sm:text-lg'>
        <span className='font-bold'>{t('Enter otp')} </span>
        <span className='text-zinc-500'>
          {t('which sent to confirm your identity in phone')}
        </span>
      </div>
      <Formik
        initialValues={{ otp: '' }}
        onSubmit={handleOtp}
        validationSchema={otpValidation(t)}
      >
        <Form className='*:mb-1.5'>
          <div className='*:mb-1'>
            <Field
              dir={locale === 'en' ? 'ltr' : 'rtl'}
              name='otp'
              className='w-full rounded-sm border p-2.5 outline-none max-sm:placeholder:text-sm'
              placeholder={t('Otp')}
            />
            <ErrorMessage
              name='otp'
              component='p'
              className='text-sm text-red-600 sm:text-lg'
            />
          </div>
          <button
            type='submit'
            disabled={loading}
            className='flex w-full items-center justify-center gap-2 rounded-sm bg-black p-1.5 py-2.5 text-sm font-bold text-white disabled:cursor-not-allowed disabled:opacity-75 sm:text-lg'
          >
            {loading && (
              <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
            )}

            <span>{t('Send')}</span>
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default MobileOtpRegister;
