const Twitter = (props) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={17}
    height={13}
    version='1'
    viewBox='0 0 100 100'
  >
    <path
      fill='currentColor'
      d='M223 728c58-84 124-181 147-214l42-62-128-149C109 102 122 120 158 120c26 0 46 18 155 145l126 145 34-48c18-26 64-91 101-144l67-98h241l-44 63C693 387 579 562 583 573c3 7 56 71 119 143 62 72 119 138 127 147 12 15 10 17-17 17s-45-16-148-135c-65-75-120-132-124-128s-46 65-94 136l-87 127H119l104-152zm339-215c123-181 228-334 232-340 5-10-10-13-63-13h-70L431 496C304 680 200 833 200 836c0 2 31 4 69 4h69l224-327z'
      transform='matrix(.1 0 0 -.1 0 100)'
    ></path>
  </svg>
);
export default Twitter;
