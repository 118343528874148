import * as React from 'react';
const CheckIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M11.75 22.5C5.82 22.5 1 17.68 1 11.75S5.82 1 11.75 1 22.5 5.82 22.5 11.75 17.68 22.5 11.75 22.5m0-20c-5.1 0-9.25 4.15-9.25 9.25S6.65 21 11.75 21 21 16.85 21 11.75 16.85 2.5 11.75 2.5'
      fill='#fff'
    />
    <path
      d='M10.58 15.58a.75.75 0 0 1-.53-.22l-2.83-2.83a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-5.67 5.67a.75.75 0 0 1-.53.22'
      fill='#fff'
    />
  </svg>
);
export default CheckIcon;
