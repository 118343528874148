import { setCookie } from 'cookies-next';
import LangIconV2 from '@/components/icons/LangIconV2';
import Arrow from '@/components/icons/Arrow';
import { useRouter } from 'next/router';

export default function LocaleSwitcher({
  hideArrow = true,
  callback = null,
  hideIcon = false,
}) {
  const { locale, push, pathname, asPath, query } = useRouter();
  return (
    <div className='group relative pe-4'>
      <div
        onClick={() => {
          // const currentStore = getCookie('selectedStore');
          const local = locale?.toLowerCase().includes('ar') ? 'en' : 'ar';
          const is_uae_store = locale?.toLowerCase()?.includes('ae');
          let currentLocale = is_uae_store ? local.concat('-AE') : local;

          console.log('currentLocale ar', currentLocale);
          setCookie('locale', currentLocale);
          setCookie('selectedStore', currentLocale);
          callback && callback();
          push(
            {
              route: pathname,
              query: query,
            },
            asPath,
            { locale: currentLocale }
          );
        }}
        className='flex cursor-pointer items-center gap-2'
      >
        {!hideIcon && <LangIconV2 className='size-5' />}
        <span className='mb-0.5'>
          {locale?.includes('en') ? 'العربية' : 'English'}
        </span>
        {!hideArrow && <Arrow />}
      </div>
    </div>
  );
}
