import { useContext } from 'react';

import AddIcon from '../icons/AddIcon';
import MinusIcon from '../icons/MinusIcon';
import AddToCart from '../global/buttons/AddToCart';
import CompareContext from '@/context/compare/compare-context';

const AddToCartSection = ({
  configurations,
  product,
  sku,
  qty,
  setQty,
  relatedProducts,
}) => {
  const compareCtx = useContext(CompareContext);
  return (
    <div
      className={`flex items-center justify-between gap-3 max-sm:fixed ${compareCtx.compareProducts.length === 0 ? 'max-sm:bottom-[50px]' : 'max-sm:bottom-28'} max-sm:start-1/2 max-sm:z-[100] max-sm:w-full max-sm:translate-x-1/2 max-sm:border-b max-sm:bg-white max-sm:px-3 max-sm:py-3`}
    >
      <div className='flex items-center gap-1'>
        <button
          onClick={() => setQty((qty) => qty + 1)}
          // disabled={qty === product?.stock?.qty}
          className='flex size-7 items-center justify-center rounded-md border lg:h-11 lg:w-10 lg:rounded-none lg:bg-[#EAE8E8]'
        >
          <AddIcon />
        </button>
        <span className='flex w-12 items-center justify-center lg:h-11 lg:border-y lg:text-lg'>
          {qty}
        </span>
        <button
          onClick={() => setQty((qty) => qty - 1)}
          disabled={qty === 1}
          className='flex size-7 items-center justify-center rounded-md border lg:h-11 lg:w-10 lg:rounded-none lg:bg-[#EAE8E8]'
        >
          <MinusIcon />
        </button>
      </div>
      <AddToCart
        fromPDP
        {...{ product, qty, configurations, sku, relatedProducts }}
      />
    </div>
  );
};

export default AddToCartSection;
