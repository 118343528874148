const ProfileCircle = (props) => (
  <svg
    width={24}
    height={25}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.12 14.0934C12.1 14.0934 12.07 14.0934 12.05 14.0934C12.02 14.0934 11.98 14.0934 11.95 14.0934C9.67998 14.0205 7.97998 12.1768 7.97998 9.90592C7.97998 7.59342 9.78998 5.70801 12.01 5.70801C14.23 5.70801 16.04 7.59342 16.04 9.90592C16.03 12.1872 14.32 14.0205 12.15 14.0934C12.13 14.0934 12.13 14.0934 12.12 14.0934ZM12 7.26009C10.6 7.26009 9.46998 8.44759 9.46998 9.89551C9.46998 11.3226 10.54 12.4788 11.9 12.5309C11.93 12.5205 12.03 12.5205 12.13 12.5309C13.47 12.458 14.52 11.3122 14.53 9.89551C14.53 8.44759 13.4 7.26009 12 7.26009Z'
      fill='currentColor'
    />
    <path
      d='M12 23.6982C9.31002 23.6982 6.74002 22.6566 4.75002 20.7607C4.57002 20.5941 4.49002 20.3441 4.51002 20.1045C4.64002 18.8649 5.38002 17.7087 6.61002 16.8545C9.59002 14.792 14.42 14.792 17.39 16.8545C18.62 17.7191 19.36 18.8649 19.49 20.1045C19.52 20.3545 19.43 20.5941 19.25 20.7607C17.26 22.6566 14.69 23.6982 12 23.6982ZM6.08002 19.8962C7.74002 21.3441 9.83002 22.1357 12 22.1357C14.17 22.1357 16.26 21.3441 17.92 19.8962C17.74 19.2607 17.26 18.6462 16.55 18.1462C14.09 16.4378 9.92002 16.4378 7.44002 18.1462C6.73002 18.6462 6.26002 19.2607 6.08002 19.8962Z'
      fill='currentColor'
    />
    <path
      d='M12 23.6976C6.07 23.6976 1.25 18.6768 1.25 12.4997C1.25 6.32259 6.07 1.30176 12 1.30176C17.93 1.30176 22.75 6.32259 22.75 12.4997C22.75 18.6768 17.93 23.6976 12 23.6976ZM12 2.86426C6.9 2.86426 2.75 7.18717 2.75 12.4997C2.75 17.8122 6.9 22.1351 12 22.1351C17.1 22.1351 21.25 17.8122 21.25 12.4997C21.25 7.18717 17.1 2.86426 12 2.86426Z'
      fill='currentColor'
    />
  </svg>
);
export default ProfileCircle;
