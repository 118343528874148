import { Cairo } from 'next/font/google';
import Portal from '../global/Portal';

const cairoFont = Cairo({
  weight: ['200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal'],
  display: 'swap',
  subsets: ['arabic'],
  variable: '--font-cairo',
});

const Index = ({ children, show, isCenter }) => {
  if (show)
    return (
      <Portal>
      <div
        className={`fixed left-0 top-0 z-[1000] h-screen w-screen bg-black bg-opacity-60 ${
          cairoFont.variable
        } font-sans ${isCenter && 'flex items-center justify-center'}`}
      >
        {children}
      </div>
      </Portal>
    );
  else return null;
};

export default Index;
