const { useReducer, useEffect, createContext } = require('react');
import browserStorage from 'store';
import { cartReducer } from './reducers';
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const savedSData = (browserStorage.get('cart') &&
  Object.keys(browserStorage.get('cart'))
    ? !('isStateUpdate' in browserStorage.get('cart'))
      ? {
          ...browserStorage.get('cart'),
          isStateUpdate: false,
        }
      : browserStorage.get('cart')
    : null) || {
    cart: {},
    cartItems: [],
    relatedProducts: [],
    isStateUpdate: false,
  };
  const [cart, dispatch] = useReducer(cartReducer, {
    ...savedSData,
    cartInfo: [],
  });
  useEffect(() => {
    browserStorage.set('cart', cart);
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
