const FaceBook = (props) => (
  <svg
    width={11}
    height={18}
    viewBox='0 0 11 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.63543 9.89101L10.1318 6.86633H7.20865V4.75986C7.20865 3.89567 7.53958 3.24752 8.86327 3.24752H10.2973V0.492904C9.52512 0.384879 8.64266 0.276855 7.8705 0.276855C5.33342 0.276855 3.56849 1.78919 3.56849 4.4898V6.86633H0.810791V9.89101H3.56849V17.5067C4.17518 17.6147 4.78188 17.6687 5.38857 17.6687C5.99527 17.6687 6.60196 17.6147 7.20865 17.5067V9.89101H9.63543Z'
      fill='currentcolor'
    />
  </svg>
);
export default FaceBook;
