const SearchIcon = (props) => (
  <svg
    width={22}
    height={22}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.1813 20.6917C4.56918 20.6917 0 16.1226 0 10.5104C0 4.89828 4.56918 0.329102 10.1813 0.329102C15.7935 0.329102 20.3626 4.89828 20.3626 10.5104C20.3626 16.1226 15.7935 20.6917 10.1813 20.6917ZM10.1813 1.81905C5.38368 1.81905 1.48995 5.72272 1.48995 10.5104C1.48995 15.2981 5.38368 19.2018 10.1813 19.2018C14.979 19.2018 18.8727 15.2981 18.8727 10.5104C18.8727 5.72272 14.979 1.81905 10.1813 1.81905Z'
      fill='currentColor'
    />
    <path
      d='M20.6114 21.6857C20.4226 21.6857 20.2339 21.6162 20.0849 21.4671L18.0979 19.4801C17.8097 19.192 17.8097 18.7151 18.0979 18.427C18.386 18.1389 18.8629 18.1389 19.151 18.427L21.138 20.414C21.4261 20.7021 21.4261 21.179 21.138 21.4671C20.9889 21.6162 20.8002 21.6857 20.6114 21.6857Z'
      fill='currentColor'
    />
  </svg>
);
export default SearchIcon;
