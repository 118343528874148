const Heart = (props) => (
  <svg
    width={32}
    height={29}
    viewBox='0 0 32 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.9997 28.5728C15.5605 28.5728 15.1355 28.5145 14.7813 28.3833C9.36968 26.4728 0.770508 19.6916 0.770508 9.67284C0.770508 4.56867 4.77967 0.427002 9.70967 0.427002C12.1038 0.427002 14.3422 1.3895 15.9997 3.11034C17.6572 1.3895 19.8955 0.427002 22.2897 0.427002C27.2197 0.427002 31.2288 4.58325 31.2288 9.67284C31.2288 19.7062 22.6297 26.4728 17.218 28.3833C16.8638 28.5145 16.4388 28.5728 15.9997 28.5728ZM9.70967 2.6145C5.95551 2.6145 2.89551 5.77908 2.89551 9.67284C2.89551 19.6333 12.203 25.1749 15.4755 26.327C15.7305 26.4145 16.283 26.4145 16.538 26.327C19.7963 25.1749 29.118 19.6478 29.118 9.67284C29.118 5.77908 26.058 2.6145 22.3038 2.6145C20.1505 2.6145 18.153 3.64992 16.8638 5.44367C16.4672 5.99784 15.5605 5.99784 15.1638 5.44367C13.8463 3.63534 11.863 2.6145 9.70967 2.6145Z'
      fill='currentColor'
    />
  </svg>
);
export default Heart;
