const Youtube = (props) => (
  <svg
    width={19}
    height={14}
    viewBox='0 0 19 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.1562 0.648438H4.52626C2.44069 0.648438 0.75 2.31052 0.75 4.3608V9.58472C0.75 11.635 2.44069 13.2971 4.52626 13.2971H15.1562C17.2417 13.2971 18.9324 11.635 18.9324 9.58472V4.3608C18.9324 2.31052 17.2417 0.648438 15.1562 0.648438ZM12.6023 7.22692L7.63036 9.55812C7.49788 9.62024 7.34484 9.52528 7.34484 9.381V4.57289C7.34484 4.42656 7.5019 4.33172 7.63466 4.39787L12.6066 6.87478C12.7544 6.94841 12.7519 7.15682 12.6023 7.22692Z'
      fill='currentColor'
    />
  </svg>
);
export default Youtube;
