const Colors = ({ t, handleOnClickConfigurable, selectedIdx, parent }) => {
  const name = parent?.[0];
  const data = Object.values(parent?.[1]);

  console.log('selectedIdx in Colors', selectedIdx);
  return (
    <div className='flex flex-col gap-3'>
      <p className='whitespace-nowrap font-bold md:text-lg'>{t(name)} : </p>
      <ul className='flex flex-1 flex-wrap items-center gap-2'>
        {data?.map((item, key) => (
          <li
            onClick={() => handleOnClickConfigurable(item, key, name)}
            key={`product-color-${key}`}
            style={{ 'background-color': item?.value }}
            className={`${
              key == selectedIdx[name] && 'border-2 border-red-600'
            } size-7 cursor-pointer rounded-sm md:rounded-full`}
          />
        ))}
      </ul>
    </div>
  );
};

export default Colors;
