import { useRelatedAndUpsellProducts } from '@/hooks/product/useRelatedAndUpsellProducts';
import { calcCartTotalHandler } from '@/helpers/cart';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { getCookies } from 'cookies-next';
import { useCart } from '@/context/cart';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import Modal from '.';
import Link from 'next/link';
import CartItem from '../cart/CartItem';
import CloseIcon from '../icons/CloseIcon';
import ProductCard from '../cards/ProductCard';
import FreeShipping from '../cart/FreeShipping';
import useHandleOutSide from '@/hooks/useHandleOutSide';

const CartModal = ({ state, dispatch, t }) => {
  const ref = useRef();
  const { handshake } = getCookies();
  useHandleOutSide(ref, () => dispatch({ type: 'close-cart-modal' }));
  const cart = useCart();
  const { locale } = useRouter();

  const cartTotal = calcCartTotalHandler(cart?.cart?.cartItems || []);
  const { relatedProducts } = useRelatedAndUpsellProducts(
    cart?.cart?.cartItems[cart?.cart?.cartItems?.length - 1],
    handshake,
    locale,
    state
  );

  return (
    <Modal show={state.isOpen}>
      <div
        ref={ref}
        className='gray-scroll max-h-screen min-h-screen w-11/12 overflow-y-auto overflow-x-hidden bg-white p-3 sm:w-[500px]'
      >
        <div className='flex items-center justify-between border-b pb-3'>
          <h1 className='font-bold max-sm:text-[12px]'>{t('My Cart')}</h1>
          <CloseIcon
            className='size-5 cursor-pointer'
            onClick={() => dispatch({ type: 'close-cart-modal' })}
          />
        </div>
        <div className='gray-scroll my-3 grid max-h-[30vh] grid-cols-1 overflow-y-auto'>
          {cart?.cart?.cartItems?.map((item, key) => (
            <CartItem key={`cart-modal-item-${key}`} itemData={item} />
          ))}
        </div>
        <FreeShipping t={t} items={cart?.cart?.cartItems} fromModal />
        <p className='max-sm:text-[11px] '>
          <strong>{t('Total')}:</strong> {cartTotal} {t('SR')}
        </p>
        <div className='z-[50] mx-auto mb-2 grid h-12 w-full grid-cols-2 gap-3 bg-white p-2 max-sm:text-[11px] md:fixed md:bottom-0 md:w-[465px]'>
          <Link
            href='/cart'
            onClick={() => dispatch({ type: 'close-cart-modal' })}
            className='block rounded-md border border-black bg-black text-center text-sm font-semibold text-white max-sm:h-6 max-sm:text-[11px] sm:p-2 md:p-1.5'
          >
            {t('Go To Cart')}
          </Link>
          <button
            onClick={() => dispatch({ type: 'close-cart-modal' })}
            className='rounded-md border border-black text-sm font-semibold max-sm:h-6 max-sm:text-[11px] sm:p-2 md:p-1.5'
          >
            {t('Continue Shopping')}
          </button>
        </div>
        {relatedProducts?.length > 0 && (
          <>
            <p className='mb-3 text-[15px] font-bold min-[420px]:text-lg'>
              {t('People who bought this also bought')}
            </p>
            <div className='mb-11'>
              <Swiper
                spaceBetween={8}
                key={locale}
                dir={locale === 'en' ? 'ltr' : 'rtl'}
                breakpoints={{
                  320: { slidesPerView: 2.1 },
                  451: { slidesPerView: 2.5 },
                }}
                loop
                modules={[Autoplay]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
              >
                {relatedProducts?.map((product, i) => (
                  <SwiperSlide key={`cart-modal-related-product-${i}`}>
                    <ProductCard product={product} isPriority={i <= 5} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
