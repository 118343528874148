export const getProductValueFromProductAttributes = (product, key, locale) => {
  const attribute = product?.product_attributes?.find(
    (attribute) => attribute?.['1']?.label === key
  );
  if (attribute) {
    return locale === 'en' ? attribute?.['1']?.value : attribute?.['5']?.value;
  }
  return '';
};

export const productPriceHandler = (price, from_data, to_date) => {
  const today = new Date();
  const from = new Date(from_data);
  const to = new Date(to_date);

  const withinPeriod =
    (today >= from && today <= to) || (today >= from && to_date === '');

  return price > 0 && withinPeriod ? price : 0;
};

/**
 * Format Number
 * @param {number} number
 * @returns {string} formatted number or null
 * @example
 * formatPrice(100) // "100"
 * formatPrice(100.50) // "100.50"
 * formatPrice(0) // "0"
 * formatPrice(null) // null
 * formatPrice(NaN) // null
 **/
function intOrFixedNumber(number) {
  if (isNaN(number)) return null;
  const fixedNumber = number?.toFixed(2);
  const [IntPart, floatPart] = String(fixedNumber)?.split('.');
  return floatPart === '00' ? IntPart : fixedNumber;
}

/**
 * Get prices and discount percentage during period between start and end date
 * @param {object} prices {price, original_price, discounted_price_from, discount_price_to}
 * @returns {object} { originalPrice, specialPrice, discount }
 * originalPice == price before discount
 * specialPrice == discounted price during specific period
 * discount is applied discount in percentage (0-100) during specific period
 * discountedPrice = originalPrice - specialPrice
 */
export const validatePrice = (prices) => {
  const now = new Date();
  const startDate = new Date(prices?.discounted_price_from);
  const endDate = new Date(prices?.discounted_price_to);

  let originalPrice = prices?.original_price;
  let specialPrice = null;
  let discount = null;
  let discountPercentage = null;

  const withinPeriod =
    ((now >= startDate && now <= endDate) ||
      (now >= startDate && prices?.discounted_price_to === '')) &&
    prices?.discounted_price < prices?.original_price;

  if (withinPeriod) {
    specialPrice = prices?.discounted_price;
    discount = ((originalPrice - specialPrice) / originalPrice) * 100;
    discountPercentage = intOrFixedNumber(
      100 - (specialPrice / originalPrice) * 100
    );
  }

  return {
    originalPrice,
    specialPrice,
    discount: intOrFixedNumber(discount),
    discountPercentage,
  };
};

/**
 * format price object to use as params in validatePrice function
 * @param {object} item //product item with prices
 * @returns {object} // { price, original_price, discounted_price_from, discount_price_to } formated price object to use as params in validatePrice function
 */
export const priceFormatedObject = (item) => {
  return {
    discounted_price:
      item?.extension_attributes?.special_price ||
      item?.prices_with_tax?.discounted_price ||
      0,
    original_price:
      item?.extension_attributes?.original_price ||
      item?.prices_with_tax?.original_price ||
      0,
    discounted_price_from:
      item?.extension_attributes?.special_price_from ||
      item?.prices_with_tax?.discounted_price_from,
    discounted_price_to:
      item?.extension_attributes?.special_price_to ||
      item?.prices_with_tax?.discounted_price_to ||
      '',
  };
};

export const productRatingHandler = (productReviews) => {
  const ratingScore = productReviews?.length
    ? (
        productReviews
          ?.map((r) => r?.ratings?.[0]?.value || 5)
          ?.reduce((a, b) => a + b) / productReviews?.length
      )?.toFixed(2)
    : 0;

  return { ratingScore: ratingScore, reviews_count: productReviews?.length };
};

export const generateConfigurations = (product) => {
  if (product?.option_details) {
    const opDetails = Object.entries(product?.option_details);
    const optionsObject = opDetails.reduce(
      (acc, curr) =>
        Object.defineProperty(acc, curr[0], {
          value: curr[1][0],
          writable: true,
          enumerable: true,
          configurable: true,
        }),
      {}
    );
    return optionsObject;
  } else {
    return null;
  }
};

export function compareObjects(obj1, obj2) {
  // Check if both arguments are objects
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is different
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if values of all keys are equal
  for (let key of keys1) {
    if (!keys2.includes(key) || !compareObjects(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function extractOptionIds(obj) {
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key].hasOwnProperty('option_id')) {
      result[key] = obj[key].option_id;
    }
  }

  return result;
}
