const LangIconV2 = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.51413 14.7314C3.8009 14.7314 0.782715 11.7132 0.782715 7.99997C0.782715 4.28674 3.8009 1.26855 7.51413 1.26855C11.2274 1.26855 14.2456 4.28674 14.2456 7.99997C14.2456 11.7132 11.2274 14.7314 7.51413 14.7314ZM7.51413 2.20782C4.32062 2.20782 1.72198 4.80646 1.72198 7.99997C1.72198 11.1935 4.32062 13.7921 7.51413 13.7921C10.7076 13.7921 13.3063 11.1935 13.3063 7.99997C13.3063 4.80646 10.7076 2.20782 7.51413 2.20782Z"
      fill="currentColor"
    />
    <path
      d="M5.6353 14.1053H5.00913C4.75239 14.1053 4.53949 13.8924 4.53949 13.6356C4.53949 13.3789 4.73987 13.1723 4.9966 13.166C4.0135 9.80967 4.0135 6.19036 4.9966 2.83404C4.73987 2.82778 4.53949 2.62114 4.53949 2.36441C4.53949 2.10768 4.75239 1.89478 5.00913 1.89478H5.6353C5.78559 1.89478 5.92961 1.96992 6.01727 2.08889C6.10494 2.21413 6.12999 2.37067 6.07989 2.51469C4.90268 6.0526 4.90268 9.94743 6.07989 13.4916C6.12999 13.6356 6.10494 13.7922 6.01727 13.9174C5.92961 14.0301 5.78559 14.1053 5.6353 14.1053Z"
      fill="currentColor"
    />
    <path
      d="M9.39249 14.1052C9.3424 14.1052 9.2923 14.0989 9.24221 14.0801C8.998 13.9987 8.86024 13.7295 8.94791 13.4853C10.1251 9.94737 10.1251 6.05254 8.94791 2.50837C8.8665 2.26416 8.998 1.9949 9.24221 1.9135C9.49268 1.8321 9.75568 1.96359 9.83708 2.2078C11.0832 5.93983 11.0832 10.0476 9.83708 13.7733C9.77446 13.98 9.58661 14.1052 9.39249 14.1052Z"
      fill="currentColor"
    />
    <path
      d="M7.51393 11.2562C5.76689 11.2562 4.02612 11.0119 2.34796 10.5173C2.3417 10.7677 2.13506 10.9744 1.87833 10.9744C1.62159 10.9744 1.40869 10.7615 1.40869 10.5047V9.87857C1.40869 9.72828 1.48383 9.58426 1.60281 9.4966C1.72804 9.40893 1.88459 9.38388 2.02861 9.43398C5.56652 10.6112 9.46761 10.6112 13.0055 9.43398C13.1495 9.38388 13.3061 9.40893 13.4313 9.4966C13.5566 9.58426 13.6254 9.72828 13.6254 9.87857V10.5047C13.6254 10.7615 13.4125 10.9744 13.1558 10.9744C12.8991 10.9744 12.6924 10.774 12.6862 10.5173C11.0017 11.0119 9.26097 11.2562 7.51393 11.2562Z"
      fill="currentColor"
    />
    <path
      d="M13.1494 6.5911C13.0993 6.5911 13.0492 6.58484 12.9991 6.56605C9.46122 5.38884 5.56012 5.38884 2.02222 6.56605C1.77174 6.64746 1.50875 6.51596 1.42735 6.27175C1.3522 6.02128 1.4837 5.75828 1.72791 5.67688C5.45994 4.43079 9.56767 4.43079 13.2934 5.67688C13.5376 5.75828 13.6754 6.02754 13.5877 6.27175C13.5314 6.46587 13.3435 6.5911 13.1494 6.5911Z"
      fill="currentColor"
    />
  </svg>
);
export default LangIconV2;
