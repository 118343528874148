const ShareIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.68 10.66L15.3 7.35M6 9C6.59334 9 7.17336 9.17595 7.66671 9.50559C8.16006 9.83524 8.54458 10.3038 8.77164 10.852C8.9987 11.4001 9.05811 12.0033 8.94236 12.5853C8.8266 13.1672 8.54088 13.7018 8.12132 14.1213C7.70176 14.5409 7.16721 14.8266 6.58527 14.9424C6.00333 15.0581 5.40013 14.9987 4.85195 14.7716C4.30377 14.5446 3.83524 14.1601 3.50559 13.6667C3.17595 13.1734 3 12.5933 3 12C3 11.2044 3.31607 10.4413 3.87868 9.87868C4.44129 9.31607 5.20435 9 6 9V9ZM15 18C15 18.5933 15.1759 19.1734 15.5056 19.6667C15.8352 20.1601 16.3038 20.5446 16.8519 20.7716C17.4001 20.9987 18.0033 21.0581 18.5853 20.9424C19.1672 20.8266 19.7018 20.5409 20.1213 20.1213C20.5409 19.7018 20.8266 19.1672 20.9424 18.5853C21.0581 18.0033 20.9987 17.4001 20.7716 16.852C20.5446 16.3038 20.1601 15.8352 19.6667 15.5056C19.1734 15.1759 18.5933 15 18 15C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18ZM8.68 13.34L15.3 16.65L8.68 13.34ZM18 9C18.5933 9 19.1734 8.82405 19.6667 8.49441C20.1601 8.16477 20.5446 7.69623 20.7716 7.14805C20.9987 6.59987 21.0581 5.99667 20.9424 5.41473C20.8266 4.83279 20.5409 4.29824 20.1213 3.87868C19.7018 3.45912 19.1672 3.1734 18.5853 3.05765C18.0033 2.94189 17.4001 3.0013 16.8519 3.22836C16.3038 3.45543 15.8352 3.83994 15.5056 4.33329C15.1759 4.82664 15 5.40666 15 6C15 6.79565 15.3161 7.55871 15.8787 8.12132C16.4413 8.68393 17.2044 9 18 9Z'
      stroke='black'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ShareIcon;
