const MinusIcon = (props) => (
  <svg
    width={14}
    height={2}
    viewBox='0 0 14 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.75 1.5H0.75C0.34 1.5 0 1.16 0 0.75C0 0.34 0.34 0 0.75 0H12.75C13.16 0 13.5 0.34 13.5 0.75C13.5 1.16 13.16 1.5 12.75 1.5Z'
      fill='currentColor'
    />
  </svg>
);
export default MinusIcon;
