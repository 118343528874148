import StarsRateIcon from '../icons/StarsRateIcon';
import { useRouter } from 'next/router';
import { handlePriceByComma } from '@/helpers/common/handlePrice';
import { validatePrice } from '@/helpers/product';

const PriceRates = ({
  t,
  priceWithTax,
  ratingScore,
  reviews_count,
  show_discount_price,
}) => {
  const { locale } = useRouter();

  const price = validatePrice(priceWithTax);

  return (
    <>
      {/* <div className='flex items-center gap-3'>
        <StarsRateIcon rate={4} />
        <div className='flex items-center gap-2'>
          <span className='font-bold'>4.7</span>
          <span>(2500)</span>
        </div>
      </div> */}
      {reviews_count !== 0 && (
        <div className='flex flex-wrap items-center justify-between gap-4'>
          <div className='flex items-center gap-2'>
            <StarsRateIcon rate={Math.floor(ratingScore)} />
            <span className='text-zinc-500'>({reviews_count || 0})</span>
            <span className='font-bold'>{ratingScore}</span>
          </div>
        </div>
      )}

      <div className='relative my-1 flex items-center justify-between'>
        <div className='flex items-center gap-3'>
          <p className='text-xl font-bold'>
            {handlePriceByComma(price?.specialPrice || price?.originalPrice)}{' '}
            {t('SAR')}
          </p>
          {price?.specialPrice && (
            <p className='text-sm font-normal text-zinc-500 line-through'>
              {handlePriceByComma(price?.originalPrice)} {t('SAR')}
            </p>
          )}
        </div>
        <span
          className={`flex items-center gap-5 text-base font-semibold max-sm:text-sm ${price?.specialPrice ? 'visible' : 'invisible'}`}
        >
          <span
            className={`text-base font-semibold max-sm:text-sm ${price?.specialPrice ? 'visible' : 'invisible'}`}
          >
            {`${locale === 'en' ? 'Save' : 'وفر'} ${handlePriceByComma(price?.specialPrice - price?.originalPrice)} ${t('SAR')}`}
          </span>
          {price?.discount && show_discount_price == '1' && (
            <span
              className={`rounded-md bg-red-500 p-1 text-base font-semibold text-white max-sm:text-sm ${price?.specialPrice ? 'visible' : 'invisible'}`}
            >
              {Math.floor(Number(price?.discount))}%
              {locale === 'en' ? 'OFF' : 'خصم'}{' '}
            </span>
          )}
        </span>
      </div>
    </>
  );
};

export default PriceRates;
