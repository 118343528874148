import PackageIcon from '@/components/icons/features/PackageIcon';
import ReturnIcon from '@/components/icons/features/ReturnIcon';
import PercentageIcon from '@/components/icons/features/PercentageIcon';
import MapMarkerIcon from '@/components/icons/features/MapMarker';

export const activeData = [
  {
    'title-ar': 'توصيل سريع',
    'title-en': 'Fast delivery',
    'desc-ar': 'تمتع بخدمة توصيل أفضل',
    'desc-en': 'Enjoy better delivery service',
    icon: <PackageIcon />,
  },
  {
    'title-ar': 'قابل للإسترجاع',
    'title-en': 'Refundable',
    'desc-ar': 'قابل للإسترجاع خلال 7 أيام',
    'desc-en': 'Returnable within 7 days',
    icon: <ReturnIcon />,
  },
  {
    'title-ar': 'العروض الحالية',
    'title-en': 'Current offers',
    'desc-ar': 'عروض تناسب جميع الميزانيات',
    'desc-en': 'Offers to suit all budgets',
    icon: <PercentageIcon />,
  },
  {
    'title-ar': 'استلام من نقطة',
    'title-en': 'Pickup from point',
    'desc-ar': 'استلام من المعرض',
    'desc-en': 'Store pickup',
    icon: <MapMarkerIcon />,
  },
];
