const Instagram = (props) => (
  <svg
    width={18}
    height={17}
    viewBox='0 0 18 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.4926 1.72752H5.85211C4.01837 1.72752 2.53184 3.21405 2.53184 5.04779V11.6883C2.53184 13.5221 4.01837 15.0086 5.85211 15.0086H12.4926C14.3264 15.0086 15.8129 13.5221 15.8129 11.6883V5.04779C15.8129 3.21405 14.3264 1.72752 12.4926 1.72752ZM5.85211 0.0673828C3.10151 0.0673828 0.871704 2.29719 0.871704 5.04779V11.6883C0.871704 14.4389 3.10151 16.6687 5.85211 16.6687H12.4926C15.2433 16.6687 17.4731 14.4389 17.4731 11.6883V5.04779C17.4731 2.29719 15.2433 0.0673828 12.4926 0.0673828H5.85211ZM9.17238 10.8583C10.5477 10.8583 11.6626 9.74344 11.6626 8.36814C11.6626 6.99284 10.5477 5.87794 9.17238 5.87794C7.79708 5.87794 6.68218 6.99284 6.68218 8.36814C6.68218 9.74344 7.79708 10.8583 9.17238 10.8583ZM9.17238 12.5185C11.4646 12.5185 13.3227 10.6603 13.3227 8.36814C13.3227 6.07597 11.4646 4.2178 9.17238 4.2178C6.88022 4.2178 5.02205 6.07597 5.02205 8.36814C5.02205 10.6603 6.88022 12.5185 9.17238 12.5185ZM13.3224 5.37982C13.9642 5.37982 14.4845 4.85953 14.4845 4.21772C14.4845 3.57591 13.9642 3.05563 13.3224 3.05563C12.6806 3.05563 12.1603 3.57591 12.1603 4.21772C12.1603 4.85953 12.6806 5.37982 13.3224 5.37982Z'
      fill='currentColor'
    />
  </svg>
);
export default Instagram;
