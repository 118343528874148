import { useRouter } from "next/router";

const Loading = ({ text }) => {
  const { locale } = useRouter();
  return (
    <div className="flex items-center justify-center flex-col gap-4 h-[70vh]">
      <div className="text-xl loader-xl" />
      <div className={`mx-1 text-lg ${text || "text-slate-500"}`}>
        {locale?.includes("en") ? "Loading..." : "جاري التحميل"}
      </div>
    </div>
  );
};

export default Loading;
