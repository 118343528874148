import CheckComIcon from '../icons/CheckComIcon';
import CloseIcon from '../icons/CloseIcon';
import PrefrernceIcon from '../icons/PreferenceIcon';
import { useState } from 'react';

const Preference = ({ selected, setSelected, locale }) => {
  const [open, setOpen] = useState(false);
  return (
    <li
      onClick={() => setSelected(2)}
      className={`xs:justify-start flex items-center justify-center gap-2 border p-1.5 ${
        selected === 2 && 'border-black'
      } cursor-pointer rounded-md`}
    >
      <span className='flex flex-col items-center justify-center'>
        <span
          className={`flex ${
            open ? 'flex-row' : 'flex-row-reverse'
          } items-center`}
          onClick={() => setOpen(!open)}
        >
          <hr
            className={`xs:w-14 w-10 border ${
              open ? 'border-green-600' : 'border-red-600'
            }`}
          />
          {open ? (
            <span className='grid size-5 place-items-center rounded-full border border-green-600'>
              <CheckComIcon className='size-2 text-green-600' />
            </span>
          ) : (
            <span className='grid size-5 place-items-center rounded-full border border-red-600'>
              <CloseIcon className='size-4 text-red-600' />
            </span>
          )}
        </span>
      </span>
      <span className='flex items-center justify-center gap-1'>
        <PrefrernceIcon className='h-4 w-4' />
        <span>{locale === 'en' ? 'Pereference' : 'التفضيل'}</span>
      </span>
    </li>
  );
};

export default Preference;
