import ColoredWhatsappIcon from '@/components/icons/ColoredWhatsappIcon';
import Link from 'next/link';

const FloatingWhatsapp = () => {
  return (
    <Link
      href='https://wa.me/966920005296'
      target='_blank'
      className='fixed bottom-16 right-8 z-[350] max-sm:bottom-20 max-sm:right-4'
    >
      <ColoredWhatsappIcon width={50} height={50} />
    </Link>
  );
};

export default FloatingWhatsapp;
