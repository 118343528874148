const CategoryIcon = (props) => (
  <svg
    width={27}
    height={24}
    viewBox='0 0 27 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.9353 10.4868H18.7316C16.0651 10.4868 14.5996 9.18938 14.5996 6.82865V4.87764C14.5996 2.51691 16.0651 1.21948 18.7316 1.21948H20.9353C23.6017 1.21948 25.0672 2.51691 25.0672 4.87764V6.82865C25.0672 9.18938 23.6017 10.4868 20.9353 10.4868ZM18.7316 2.68274C16.9906 2.68274 16.2524 3.33633 16.2524 4.87764V6.82865C16.2524 8.36995 16.9906 9.02354 18.7316 9.02354H20.9353C22.6762 9.02354 23.4144 8.36995 23.4144 6.82865V4.87764C23.4144 3.33633 22.6762 2.68274 20.9353 2.68274H18.7316Z'
      fill='currentColor'
    />
    <path
      d='M7.71297 22.1929H5.50926C2.84278 22.1929 1.37732 20.8954 1.37732 18.5347V16.5837C1.37732 14.223 2.84278 12.9255 5.50926 12.9255H7.71297C10.3794 12.9255 11.8449 14.223 11.8449 16.5837V18.5347C11.8449 20.8954 10.3794 22.1929 7.71297 22.1929ZM5.50926 14.3888C3.76834 14.3888 3.0301 15.0424 3.0301 16.5837V18.5347C3.0301 20.076 3.76834 20.7296 5.50926 20.7296H7.71297C9.45389 20.7296 10.1921 20.076 10.1921 18.5347V16.5837C10.1921 15.0424 9.45389 14.3888 7.71297 14.3888H5.50926Z'
      fill='currentColor'
    />
    <path
      d='M6.61112 10.4868C3.72426 10.4868 1.37732 8.40897 1.37732 5.85314C1.37732 3.29731 3.72426 1.21948 6.61112 1.21948C9.49797 1.21948 11.8449 3.29731 11.8449 5.85314C11.8449 8.40897 9.49797 10.4868 6.61112 10.4868ZM6.61112 2.68274C4.6388 2.68274 3.0301 4.10699 3.0301 5.85314C3.0301 7.5993 4.6388 9.02354 6.61112 9.02354C8.58343 9.02354 10.1921 7.5993 10.1921 5.85314C10.1921 4.10699 8.58343 2.68274 6.61112 2.68274Z'
      fill='currentColor'
    />
    <path
      d='M19.8334 22.1929C16.9466 22.1929 14.5996 20.115 14.5996 17.5592C14.5996 15.0034 16.9466 12.9255 19.8334 12.9255C22.7203 12.9255 25.0672 15.0034 25.0672 17.5592C25.0672 20.115 22.7203 22.1929 19.8334 22.1929ZM19.8334 14.3888C17.8611 14.3888 16.2524 15.813 16.2524 17.5592C16.2524 19.3054 17.8611 20.7296 19.8334 20.7296C21.8057 20.7296 23.4144 19.3054 23.4144 17.5592C23.4144 15.813 21.8057 14.3888 19.8334 14.3888Z'
      fill='currentColor'
    />
  </svg>
);
export default CategoryIcon;
