import { countDownHandler } from '@/utils/global';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const SpecialOffer = ({ product }) => {
  const { locale } = useRouter();
  const [countDown, setCountDown] = useState(
    countDownHandler(product?.flash_sale_end_date)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown(countDownHandler(product?.flash_sale_end_date));
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <div
      className={`flex items-center justify-center gap-1 rounded-2xl bg-[#da241e] text-white sm:gap-2 ${
        product?.flash_sale_end_date &&
        product?.flash_sale_start_date &&
        countDownHandler(
          product?.flash_sale_end_date,
          product?.flash_sale_start_date
        )?.isCountDown
          ? 'visible'
          : 'invisible'
      }`}
    >
      <div className='flex  items-center justify-center gap-2'>
        <div className='grid  place-items-center rounded-[4px] text-sm font-[500]'>
          {countDown.days || 0}
        </div>
        <span className='text-secondary text-xs'>
          {locale === 'en' ? 'Day' : 'يوم'}
        </span>
      </div>
      <span> | </span>
      <div className='flex flex-col items-center justify-center gap-2'>
        <div className='grid size-7 place-items-center rounded-[4px] text-sm font-[500]'>
          {countDown.hours || 0}
        </div>
        {/* <span className='text-secondary text-xs'>
          {locale === 'en' ? 'Hour' : 'ساعة'}
        </span> */}
      </div>
      <span> : </span>
      <div className='flex flex-col items-center justify-center gap-2'>
        <div className='grid size-7 place-items-center rounded-[4px] text-sm font-[500]'>
          {countDown.minutes || 0}
        </div>
        {/* <span className='text-secondary text-xs'>
          {locale === 'en' ? 'Min' : 'دقيقة'}
        </span> */}
      </div>
      <span> : </span>
      <div className='flex flex-col items-center justify-center gap-2'>
        <div className='grid size-7 place-items-center rounded-[4px] text-sm font-[500]'>
          {countDown.seconds || 0}
        </div>
        {/* <span className='text-xs text-secondary'>
        {locale === 'en' ? 'Sec' : 'ثانية'}
      </span> */}
      </div>
    </div>
  );
};

export default SpecialOffer;
