const RemoveIcon = (props) => (
  <svg
    width={18}
    height={17}
    viewBox='0 0 18 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.5858 4.98507C15.5717 4.98507 15.5505 4.98507 15.5293 4.98507C11.7976 4.64594 8.07294 4.51796 4.38355 4.8571L2.94448 4.98507C2.64819 5.01067 2.38719 4.8187 2.35897 4.54995C2.33075 4.2812 2.54238 4.05084 2.83161 4.02525L4.27068 3.89727C8.02356 3.55174 11.8258 3.68611 15.6351 4.02525C15.9244 4.05084 16.136 4.2876 16.1078 4.54995C16.0866 4.79951 15.8538 4.98507 15.5858 4.98507Z'
      fill='currentColor'
    />
    <path
      d='M6.76788 4.33879C6.73966 4.33879 6.71144 4.33879 6.67617 4.33239C6.394 4.2876 6.19648 4.03805 6.24586 3.7821L6.40105 2.94385C6.51392 2.32956 6.66912 1.47852 8.31277 1.47852H10.161C11.8117 1.47852 11.9669 2.36155 12.0727 2.95025L12.2279 3.7821C12.2773 4.04445 12.0798 4.294 11.7976 4.33239C11.5084 4.37719 11.2332 4.19802 11.1909 3.94207L11.0357 3.11022C10.937 2.55352 10.9158 2.44474 10.168 2.44474H8.31982C7.57207 2.44474 7.55796 2.53432 7.45214 3.10382L7.28989 3.93567C7.24757 4.17242 7.02183 4.33879 6.76788 4.33879Z'
      fill='currentColor'
    />
    <path
      d='M11.5011 15.2359H6.97223C4.51028 15.2359 4.41152 14.001 4.33393 13.0027L3.8754 6.55912C3.85424 6.29677 4.07997 6.06641 4.3692 6.04722C4.66548 6.03442 4.91238 6.23278 4.93354 6.49513L5.39207 12.9388C5.46967 13.9114 5.49789 14.2761 6.97223 14.2761H11.5011C12.9825 14.2761 13.0107 13.9114 13.0813 12.9388L13.5398 6.49513C13.5609 6.23278 13.8149 6.03442 14.1041 6.04722C14.3933 6.06641 14.6191 6.29037 14.5979 6.55912L14.1394 13.0027C14.0618 14.001 13.963 15.2359 11.5011 15.2359Z'
      fill='currentColor'
    />
    <path
      d='M10.4074 11.7167H8.05837C7.76914 11.7167 7.5293 11.4991 7.5293 11.2367C7.5293 10.9744 7.76914 10.7568 8.05837 10.7568H10.4074C10.6967 10.7568 10.9365 10.9744 10.9365 11.2367C10.9365 11.4991 10.6967 11.7167 10.4074 11.7167Z'
      fill='currentColor'
    />
    <path
      d='M11.0006 9.15709H7.47341C7.18418 9.15709 6.94434 8.93953 6.94434 8.67718C6.94434 8.41483 7.18418 8.19727 7.47341 8.19727H11.0006C11.2898 8.19727 11.5296 8.41483 11.5296 8.67718C11.5296 8.93953 11.2898 9.15709 11.0006 9.15709Z'
      fill='currentColor'
    />
  </svg>
);
export default RemoveIcon;
