import { useGlobalData } from '@/context/global';
import { getFetcher } from '@/services/global';
import { useEffect } from 'react';
import useSWR from 'swr';
import store from 'store';
import { useRouter } from 'next/router';
import { decodeJwt } from '@/utils/global';
import { getCookie } from 'cookies-next';

export async function useCategoryTree(isChangeLang, setIsChangeLang) {
  const { locale } = useRouter();
  const { dispatch } = useGlobalData();
  const handshake = getCookie('handshake');

  const decodedToken = decodeJwt(handshake);
  const { mutate } = useSWR(
    () =>
      `/api/category/tree?locale=${locale}`,
    getFetcher,
    {
      revalidateOnMount: true, // 30 minutes in milliseconds
      revalidateOnFocus: false,
      onSuccess: (data) => {
        dispatch({
          type: 'GET_CAT_TREE',
          payload: {
            ...(data?.data?.data || {}),
            cacheTime: Date.now() + 30 * 60 * 1000,
            locale,
          },
        });
      },
    }
  );

  useEffect(() => {
    return () => {
      if (locale !== decodedToken?.storeName) {
        console.log('11111');
        mutate();
        setIsChangeLang(false);
        return;
      } else if (
        store.get('app_data')?.categoryData?.cacheTime > Date.now() &&
        locale === decodedToken?.storeName &&
        !isChangeLang &&
        locale === store.get('app_data')?.categoryData?.locale
      ) {
        console.log('22222');
        mutate();
        dispatch({
          type: 'GET_CAT_TREE',
          payload: {
            ...store.get('app_data')?.categoryData,
            cacheTime: store.get('app_data')?.cacheTime,
          },
        });
        return;
      } else {
        console.log('33333');
        mutate();
        setIsChangeLang(false);
      }
    };
  }, [
    decodedToken?.storeName,
    dispatch,
    isChangeLang,
    locale,
    mutate,
    setIsChangeLang,
  ]);

  return null;
}
