import toast from "react-hot-toast";

export const handleCopy = (product, t) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(`
      ${product?.name}
      ــــــــــــــــــ
      ${document.location.href}
    `);
    toast.success(t('copied'));
  }
};

export const labelPositions = ( pos, imgWidth = 0 ) => {
  const positions =  {
    0: {left : 0, 
        top : 0},
    1: {right : `${50 - imgWidth/2}%`, 
        top   : 0},
    2: {right : 0, 
        top   : 0},
    3: {left : 0, 
        top  : `${50 - imgWidth/2 }%`},
    4: {right : `${50 - imgWidth/2}%`, 
        top   : `${50 - imgWidth/2 }%`},
    5: {right : 0, 
        top   : `${50 - imgWidth/2 }%`},
    6: {left : 0,  
        top  : `${100 - imgWidth }%`},
    7: {right : `${50 - imgWidth/2}%`, 
        top   :`${100 - imgWidth}%`},
    8: {right : 0, 
        top  : `${100 - imgWidth}%`},
  }

  return positions?.[pos]
}