const EmailIcon = (props) => (
  <svg
    width={19}
    height={16}
    viewBox='0 0 19 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.9186 16H5.0814C1.85581 16 0 14.1838 0 11.027V4.97297C0 1.81622 1.85581 0 5.0814 0H13.9186C17.1442 0 19 1.81622 19 4.97297V11.027C19 14.1838 17.1442 16 13.9186 16ZM5.0814 1.2973C2.55395 1.2973 1.32558 2.49946 1.32558 4.97297V11.027C1.32558 13.5005 2.55395 14.7027 5.0814 14.7027H13.9186C16.446 14.7027 17.6744 13.5005 17.6744 11.027V4.97297C17.6744 2.49946 16.446 1.2973 13.9186 1.2973H5.0814Z'
      fill='currentColor'
    />
    <path
      d='M10.003 9C9.27246 9 8.53324 8.77518 7.96795 8.31688L5.24586 6.15511C4.96757 5.93028 4.91539 5.52387 5.14151 5.24716C5.36762 4.97045 5.77637 4.91857 6.05467 5.1434L8.77674 7.30517C9.4377 7.83265 10.5596 7.83265 11.2205 7.30517L13.9426 5.1434C14.2209 4.91857 14.6384 4.9618 14.8558 5.24716C15.0819 5.52387 15.0384 5.93893 14.7514 6.15511L12.0293 8.31688C11.4727 8.77518 10.7335 9 10.003 9Z'
      fill='currentColor'
    />
  </svg>
);
export default EmailIcon;
