import Arrow from '@/components/icons/Arrow';
import Link from 'next/link';

const ShippingReturnFAQ = ({ locale, t, onHide }) => {
  return (
    <div className='pb-3'>
      <div className='mb-4 flex items-center justify-between'>
        <Link
          onClick={onHide}
          href='/static/shipment-and-delivery'
          className='text-sm'
        >
          {t('Shipping Policy')}
        </Link>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </div>
      <div className='mb-4 flex items-center justify-between'>
        <Link
          onClick={onHide}
          href='/static/exchange-and-return'
          className='text-sm'
        >
          {t('Return & Refunds')}
        </Link>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </div>
      <div className='mb-4 flex items-center justify-between'>
        <Link
          onClick={onHide}
          href='/static/terms-conditions'
          className='text-sm'
        >
          {locale === 'en' ? 'Terms & Conditions' : 'الشروط والأحكام'}
        </Link>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </div>
      <div className='mb-4 flex items-center justify-between'>
        <Link onClick={onHide} href='/static/privacy' className='text-sm'>
          {locale === 'en' ? 'Privacy Policy' : 'سياسة الخصوصية'}
        </Link>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </div>
      <div className='flex items-center justify-between'>
        <Link onClick={onHide} href='/static/faq' className='text-sm'>
          {t('Frequently Asked Questions')}
        </Link>
        <Arrow
          className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
        />
      </div>
    </div>
  );
};

export default ShippingReturnFAQ;
