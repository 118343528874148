import LocaleSwitcher from '@/components/global/buttons/LocaleSwitcher';
import Arrow from '@/components/icons/Arrow';
import Global from '@/components/icons/Global';
import Image from '@/components/Image';
import { useCart } from '@/context/cart';
import { useCheckout } from '@/context/checkout';
import userStore from '@/hooks/useStore';
import { handleResetStorage } from '@/utils/global';
import { getCookie } from 'cookies-next';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

const Header = ({ t }) => {
  const { dispatch: dispatchCart } = useCart();
  const { dispatch: dispatchCheckout } = useCheckout();
  const amount = getCookie('shipping_free_limit') || '449';
  const router = useRouter();
  const { locale } = router;
  const store = userStore(locale);
  return (
    <header className='bg-black text-white md:py-3'>
      <div className=' mx-auto hidden w-11/12 max-w-[1440px] items-center justify-between py-1 text-sm md:flex'>
        <p suppressHydrationWarning className='text-center '>
          {t('topHeaderMsg', { amount })}
        </p>
        <div className='flex items-center justify-center gap-2'>
          <div className='group relative border-e pe-4'>
            <div className='flex cursor-pointer items-center gap-2'>
              <Image
                loading='lazy'
                src={
                  locale === 'ar' || locale === 'en'
                    ? '/imgs/layout/ksa.png'
                    : '/imgs/layout/uae.jfif'
                }
                className='h-[17px] w-[26px] object-contain'
                alt={locale === 'ar' ? 'Saudi Arabia Flag' : 'UAE Flag'}
              />
              <span className='mb-0.5'>
                {t(store === 'ksa' ? 'Saudi Arabia' : 'UAE')}
              </span>
              <Arrow />
            </div>
            <div className='absolute start-0 top-full z-[100] hidden min-w-32 max-w-32 rounded-md bg-white p-2 text-black shadow-lg group-hover:block'>
              {locale == 'ar-AE' ||
              locale == 'en-AE' ||
              locale == 'ar-ae' ||
              locale == 'en-ae' ? (
                <span
                  onClick={() => {
                    const currentLocale = locale?.includes('ar') ? 'ar' : 'en';
                    handleResetStorage(router, signOut, currentLocale);
                    dispatchCart({ type: 'clear' });
                    dispatchCheckout({ type: 'clear' });
                  }}
                  // href={{ pathname, query }}
                  // as={asPath}
                  // locale='ar'
                  className='mb-3 flex items-center gap-2'
                >
                  <Image
                    loading='lazy'
                    src='/imgs/layout/ksa.png'
                    className='object-contain'
                    alt='KSA Flag'
                  />
                  <span className='mb-0.5 cursor-pointer'>
                    {t('Saudi Arabia')}
                  </span>
                </span>
              ) : null}
              {locale.includes('ar') || locale.includes('en') ? (
                <span
                  onClick={() => {
                    const currentLocale = locale?.includes('ar')
                      ? 'ar-ae'
                      : 'en-ae';
                    handleResetStorage(router, signOut, currentLocale);
                    dispatchCart({ type: 'clear' });
                    dispatchCheckout({ type: 'clear' });
                  }}
                  // href={{ pathname, query }}
                  // as={asPath}
                  // locale='ar-ae'
                  className='flex items-center gap-2'
                >
                  <Image
                    loading='lazy'
                    src='/imgs/layout/uae.jfif'
                    className='h-[17px] w-[26px] object-contain'
                    alt='KSA Flag'
                  />
                  <span className='mb-0.5 cursor-pointer'>{t('UAE')}</span>
                </span>
              ) : null}
            </div>
          </div>
          <Link href='/branches' className='border-e pe-5 ps-2'>
            {t('Branches')}
          </Link>
          <Link href='/static/contact-us' className=' pe-5 ps-2'>
            {t('Contact Us')}
          </Link>
          {/* TODO  remove false to return locale switcher*/}
          {false && (
            <p className='flex items-center gap-2 ps-2'>
              <Global />
              <LocaleSwitcher />
            </p>
          )}
          <LocaleSwitcher />
        </div>
      </div>
      <p className='hidden text-center uppercase max-sm:text-sm'>
        {t('Discount on Tabby')}
      </p>
    </header>
  );
};

export default Header;
