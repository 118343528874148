// wishlistReducer.js
const wishlistReducer = (state, action) => {
  switch (action.type) {
    case 'get_wl':
      return (state = action?.payload);
    case 'add_wl':
      const item = action.payload;
      return { ...state, [item?.sku]: item };

    case 'remove_wl':
      const newState = { ...state };
      delete newState[action.payload];
      return newState;

    default:
      return state;
  }
};

export default wishlistReducer;
