import { priceFormatedObject, validatePrice } from "@/helpers/product";

const gtmEvent = (event, data) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];
    // console.log(event, data);
    setTimeout(() => {
      window?.dataLayer?.push({ ecommerce: null });
      window?.dataLayer?.push({
        event,
        ecommerce: data,
      });
    }, 1000);
  }
};

const customGtmEvent = (options = {}) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];

    setTimeout(() => {
      window?.dataLayer?.push({ ecommerce: null });
      window?.dataLayer?.push({
        ...options,
      });
    }, 1000);
  }
};

const event_coupon_redemption = (coupon, result) =>
  customGtmEvent({
    event: 'coupon_redemption',
    code: coupon, // The coupon code.
    result,
  });

const event_global_variables = (language, user_id, email, phone, city) =>
  customGtmEvent({
    event: 'global_variables',
    event_source: 'Website',
    ...(language ? {language}: {}),
    ...(user_id ? {user_id: `${user_id}`}: {}),
    ...(email ? {email}: {}),
    ...(phone ? {phone}: {}),
    ...(city ? {city}: {}),
    gender: 'Male',
    district: 'Al-Salama',
  });

const event_login = (method, result, user_id, email, phone, first_name, last_name, account_creation_date, total_revenue, last_purchase_date) =>
  customGtmEvent({
    event: 'login',
    ...(method ? {method}: {}),
    ...(result ? {result}: {}),
    ...(user_id ? {user_id: `${user_id}`}: {}),
    ...(email ? {email}: {}),
    ...(phone ? {phone}: {}),
    ...(first_name ? {first_name}: {}),
    ...(last_name ? {last_name}: {}),
    ...(account_creation_date ? {account_creation_date}: {}),
    ...(total_revenue ? {total_revenue}: {}),
    ...(last_purchase_date ? {last_purchase_date}: {}),
  });

const event_sign_up = (method, result, user_id, email, phone, first_name, last_name, user_type, account_creation_date) =>
  customGtmEvent({
    event: 'sign_up',
    ...(method ? {method}: {}),
    ...(result ? {result}: {}),
    ...(user_id ? { user_id } : {}),
    ...(email ? {email}: {}),
    ...(phone ? {phone}: {}),
    ...(first_name ? {first_name}: {}),
    ...(last_name ? {last_name}: {}),
    ...(user_type ? {user_type}: {}),
    ...(account_creation_date ? {account_creation_date}: {}),
  });

const event_otp_verification = (result) =>
  customGtmEvent({
    event: 'otp_verification',
    result,
  });

const event_view_item_list = (categoryname, categoryid, products, couponCode) => {
  
  const items = products?.map((product, i) => ({
    ...(product?.sku ? {item_id: product?.sku}: {}),
    ...(product?.name?.at(0) ? {item_name: product?.name?.at(0)}: {}),
    ...(couponCode ? {coupon: couponCode}: {}),
    currency: 'SAR',
    discount: product?.prices_with_tax?.original_price - (product?.prices_with_tax?.price || product?.prices_with_tax?.original_price),
    index: i,
    ...(product?.option_text_brand?.at(0) ? {item_brand: product?.option_text_brand?.at(0)}: {}),
    ...(product?.category?.at(1)?.name ? {item_category: product?.category?.at(1)?.name}: {}),
    ...(product?.category?.at(2)?.name ? {item_category2: product?.category?.at(2)?.name}: {}),
    ...(product?.category?.at(3)?.name ? {item_category3: product?.category?.at(3)?.name}: {}),
    ...(product?.category?.at(4)?.name ? {item_category4: product?.category?.at(4)?.name}: {}),
    ...(product?.category?.at(5)?.name ? {item_category5: product?.category?.at(5)?.name}: {}),
    price: product?.prices_with_tax?.price || product?.prices_with_tax?.original_price,
  }));

  return gtmEvent('view_item_list', {
    ...(categoryname || categoryid ? {item_list_name: categoryname || categoryid}: {}),
    ...(categoryid || categoryname ? {item_list_id: categoryid || categoryname}: {}),
    items,
  });
};

const event_view_promotion = (
  creative_name,
  creative_slot,
  location_id,
  promotion_id,
  promotion_name
) =>
  gtmEvent('view_promotion', {
    ...(creative_name ? {creative_name}: {}),
    ...((creative_slot != undefined || creative_slot != null) ? {creative_slot: `${creative_slot}`}: {}),
    ...(location_id ? {location_id: `${location_id}`}: {}),
    ...(promotion_id ? {promotion_id: `${promotion_id}`}: {}),
    ...(promotion_name ? {promotion_name: `${promotion_name}`}: {}),
  });

const event_select_promotion = (
  creative_name,
  creative_slot,
  location_id,
  promotion_id,
  promotion_name
) =>
  gtmEvent('select_promotion', {
    ...(creative_name ? {creative_name}: {}),
    ...(creative_slot || creative_slot == 0 ? {creative_slot: `${creative_slot}`}: {}),
    ...(location_id ? {location_id: `${location_id}`}: {}),
    ...(promotion_id ? {promotion_id: `${promotion_id}`}: {}),
    ...(promotion_name ? {promotion_name: `${promotion_name}`}: {}),
  });

const event_page_viewed = (
  currency,
  language,
  is_login,
  user_id,
  email,
  phone
) =>
  gtmEvent('page_viewed', {
    currency,
    language,
    is_login,
    user_id,
    email,
    phone,
  });

const event_sub_category_viewed = (
  subcategoryname,
  subcategoryid,
  productcount,
  categoryname,
  categoryid,
  type
) =>
  gtmEvent('sub_category_viewed', {
    subcategoryname,
    subcategoryid: subcategoryid?.toString(),
    productcount,
    categoryname,
    categoryid: categoryid?.toString(),
    type,
  });

const event_select_item = (product, couponCode = null) =>
  gtmEvent('select_item', {
    ...(product?.item_list_id? {item_list_id: product?.item_list_id}: {}),
    ...(product?.item_list_name ? { item_list_name: product?.item_list_name } : {}),
    items: [{
      ...(product?.sku? {item_id: product?.sku}: {}),
      ...(product?.name?.at(0) ? { item_name: product?.name?.at(0) } : {}),
      ...(couponCode ? {coupon: couponCode}: {}),
      currency: 'SAR',
      discount:
        product?.prices_with_tax?.original_price -
        (product?.prices_with_tax?.price || product?.prices_with_tax?.original_price),
      index: 0,
      ...(product?.option_text_brand?.at(0) ? {item_brand: product?.option_text_brand?.at(0)}: {}),
      ...(product?.category?.at(1)?.name ? {item_category: product?.category?.at(1)?.name}: {}),
      ...(product?.category?.at(2)?.name ? {item_category2: product?.category?.at(2)?.name}: {}),
      ...(product?.category?.at(3)?.name ? {item_category3: product?.category?.at(3)?.name}: {}),
      ...(product?.category?.at(4)?.name ? {item_category4: product?.category?.at(4)?.name}: {}),
      ...(product?.category?.at(5)?.name ? {item_category5: product?.category?.at(5)?.name}: {}),
      price: product?.prices_with_tax?.price || product?.prices_with_tax?.original_price,
    }]
  });

const event_view_item = (product, couponCode = null) =>
  gtmEvent('view_item', {
    items: [{
      ...(product?.sku? {item_id: product?.sku}: {}),
      ...(product?.name?.at(0) ? { item_name: product?.name?.at(0) } : {}),
      ...(couponCode ? {coupon: couponCode}: {}),
      currency: 'SAR',
      discount:
        product?.prices_with_tax?.original_price -
        (product?.prices_with_tax?.price || product?.prices_with_tax?.original_price),
      ...(product?.option_text_brand?.at(0) ? {item_brand: product?.option_text_brand?.at(0)}: {}),
      ...(product?.category?.at(1)?.name ? {item_category: product?.category?.at(1)?.name}: {}),
      ...(product?.category?.at(2)?.name ? {item_category2: product?.category?.at(2)?.name}: {}),
      ...(product?.category?.at(3)?.name ? {item_category3: product?.category?.at(3)?.name}: {}),
      ...(product?.category?.at(4)?.name ? {item_category4: product?.category?.at(4)?.name}: {}),
      ...(product?.category?.at(5)?.name ? { item_category5: product?.category?.at(5)?.name } : {}),
      ...(product?.item_list_id? {item_list_id: product?.item_list_id}: {}),
      ...(product?.item_list_name ? { item_list_name: product?.item_list_name } : {}),
      price: product?.prices_with_tax?.price || product?.prices_with_tax?.original_price,
    }]
  });

const event_add_to_cart = (product, qty, coupon_code = null) =>
  gtmEvent('add_to_cart', {
    currency: 'SAR',
    value: (product?.prices_with_tax?.price || product?.prices_with_tax?.original_price) * qty,
    items: [
      {
        item_id: product?.sku,
        ...(product?.name ? {item_name: typeof product?.name != 'string' ? product?.name?.at(0) : product?.name}: {}),
          ...(coupon_code ? { coupon: coupon_code } : {}),
        currency: 'SAR',
        discount: product?.prices_with_tax?.original_price - (+product?.prices_with_tax?.price || +product?.prices_with_tax?.original_price),
        ...(product?.option_text_brand?.at(0) || product?.extension_attributes?.brand_name ? {item_brand: product?.option_text_brand?.at(0) || product?.extension_attributes?.brand_name}: {}),
        ...(product?.category?.at(1)?.name || product?.extension_attributes?.category_names?.at(0)?.name ? {item_category:product?.category?.at(1)?.name || product?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(product?.category?.at(2)?.name || product?.extension_attributes?.category_names?.at(1)?.name ? {item_category2:product?.category?.at(2)?.name || product?.extension_attributes?.category_names?.at(1)?.name}: {}),
        ...(product?.category?.at(3)?.name || product?.extension_attributes?.category_names?.at(2)?.name ? {item_category3:product?.category?.at(3)?.name || product?.extension_attributes?.category_names?.at(2)?.name}: {}),
        ...(product?.category?.at(4)?.name || product?.extension_attributes?.category_names?.at(3)?.name ? {item_category4:product?.category?.at(4)?.name || product?.extension_attributes?.category_names?.at(3)?.name}: {}),
        ...(product?.category?.at(5)?.name || product?.extension_attributes?.category_names?.at(4)?.name ? {item_category5: product?.category?.at(5)?.name || product?.extension_attributes?.category_names?.at(4)?.name}: {}),
        item_list_id: (`${product?.categoryID}` || product?.categoryName) || `${product?.category?.at(0)?.category_id}` || null,
        item_list_name: product?.categoryName || product?.category?.at(0)?.name || null,
        ...(product?.item_variant ? { item_variant: product?.item_variant } : {}),
        price:
          product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price,
        quantity: qty,
      },
    ],
  });

const event_remove_from_cart = (product, qty, couponCode = null) =>
  gtmEvent('remove_from_cart', {
    currency: 'SAR',
    value: (qty == 1 ? product?.prices_with_tax?.price || product?.prices_with_tax?.original_price : product?.prices_with_tax?.price * qty || product?.prices_with_tax?.original_price * qty),
    items: [
      {
        item_id: product?.sku,
        ...(product?.name ? {item_name: typeof product?.name != 'string' ? product?.name?.at(0) : product?.name}: {}),
          ...(couponCode ? { coupon: couponCode } : {}),
        currency: 'SAR',
        discount: product?.prices_with_tax?.original_price - (+product?.prices_with_tax?.price || +product?.prices_with_tax?.original_price),
        ...(product?.option_text_brand?.at(0) || product?.extension_attributes?.brand_name ? {item_brand: product?.option_text_brand?.at(0) || product?.extension_attributes?.brand_name}: {}),
        ...(product?.category?.at(1)?.name || product?.extension_attributes?.category_names?.at(0)?.name ? {item_category:product?.category?.at(1)?.name || product?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(product?.category?.at(2)?.name || product?.extension_attributes?.category_names?.at(1)?.name ? {item_category2:product?.category?.at(2)?.name || product?.extension_attributes?.category_names?.at(1)?.name}: {}),
        ...(product?.category?.at(3)?.name || product?.extension_attributes?.category_names?.at(2)?.name ? {item_category3:product?.category?.at(3)?.name || product?.extension_attributes?.category_names?.at(2)?.name}: {}),
        ...(product?.category?.at(4)?.name || product?.extension_attributes?.category_names?.at(3)?.name ? {item_category4:product?.category?.at(4)?.name || product?.extension_attributes?.category_names?.at(3)?.name}: {}),
        ...(product?.category?.at(5)?.name || product?.extension_attributes?.category_names?.at(4)?.name ? {item_category5: product?.category?.at(5)?.name || product?.extension_attributes?.category_names?.at(4)?.name}: {}),
        item_list_id: (`${product?.categoryID}` || product?.categoryName) || `${product?.category?.at(0)?.category_id}` || null,
        item_list_name: product?.categoryName || product?.category?.at(0)?.name || null,
        ...(product?.item_variant ? { item_variant: product?.item_variant } : {}),
        price:
          product?.prices_with_tax?.price ||
          product?.prices_with_tax?.original_price,
        quantity: qty,
      },
    ],
  });

const event_add_to_wishlist = (product, qty, couponCode = null) => {
  return gtmEvent('add_to_wishlist', {
    currency: 'SAR',
    value: product?.prices_with_tax?.price * qty,
    items: [
      {
        item_id: product?.sku,
        item_name:
          typeof product?.name != 'string' ? product?.name?.at(0) : product?.name || null,
        ...(couponCode ? {coupon: couponCode}: {}),
        currency: 'SAR',
        discount:
          product?.prices_with_tax?.original_price - product?.prices_with_tax?.price,
        item_brand:
          product?.option_text_brand?.at(0) ||
          product?.extension_attributes?.brand_name ||
          null,
        item_category:
          product?.category?.at(1)?.name ||
          product?.extension_attributes?.category_names?.at(0) ||
          null,
        item_category2:
          product?.category?.at(2)?.name ||
          product?.extension_attributes?.category_names?.at(1) ||
          null,
        item_list_id: `${product?.category?.at(2)?.category_id}` || null,
        item_list_name: product?.category?.at(2)?.name || null,
        ...(product?.item_variant ? {item_variant: product?.item_variant}: {}),
        ...(validatePrice(priceFormatedObject(product))?.specialPrice || validatePrice(priceFormatedObject(product))?.originalPrice ? {price: validatePrice(priceFormatedObject(product))?.specialPrice || validatePrice(priceFormatedObject(product))?.originalPrice}: {}),
      },
    ],
  });
}

const event_remove_from_wishlist = (
  skuid,
  skuname,
  price,
  brand,
  image,
  type,
  categoryid,
  categoryname,
  sub_categoryid,
  subcategoryname
) =>
  gtmEvent('remove_from_wishlist', {
    skuid,
    skuname,
    quantity: 1,
    price,
    brand,
    image,
    type,
    categoryid: categoryid?.toString(),
    categoryname,
    sub_categoryid: sub_categoryid?.toString(),
    subcategoryname,
  });

const event_cart_updated = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total
) => {
  console.log('items', lineitems);
  const getImageUrl = (item) => {
    const productImage = item?.extension_attributes?.product_image;
    const image = item?.image;
    const media = item?._media_;
    if (productImage && productImage.startsWith('http')) return productImage;
    if (productImage)
      return `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${productImage}`;
    if (media && media.length > 0) return media?.at(0).image;
    if (image && Array.isArray(image) && image?.at(0)?.startsWith('http'))
      return image?.at(0);
    if (image && image?.at(0)?.startsWith('http')) return image;
    if (image)
      return `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${image}`;
    return null;
  };
  gtmEvent('cart_updated', {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      Image: getImageUrl(item),
      price: item?.price_incl_tax,
      quantity: item?.qty,
      categoryname:
        item?.extension_attributes?.category_names?.at(0)?.name ||
        item.categoryname ||
        '',
      subcategoryname:
        item?.extension_attributes?.category_names?.at(1)?.name ||
        item.subcategoryname ||
        '',
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
  });
};

const event_wishlist_updated = (wishlist) => {
  console.log('wishlist', wishlist);
  const total = wishlist.reduce(
    (total, item) => total + item?.prices_with_tax?.price,
    0
  );
  const lineitems = wishlist.map((item) => ({
    sku: Array.isArray(item?.sku) ? item?.sku.at(0) : item?.sku,
    name: Array.isArray(item?.name) ? item?.name.at(0) : item?.name,
    image: item?.image
      ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.image}`
      : '',
    price: item?.prices_with_tax?.price,
    quantity: 1,
    categoryname: item?._category_?.main_category?.name || '',
    subcategoryname: item?._category_?.sub_categories?.at(0)?.name || '',
  }));
  gtmEvent('wishlist_updated', {
    lineitems,
    numberofproducts: wishlist.length,
    skuid: wishlist.map((item) => item.sku).join(','),
    skuname: wishlist
      .map((item) => (Array.isArray(item.name) ? item?.name.at(0) : item?.name))
      .join(','),
    subtotal: 0.85 * total,
    tax: 0.15 * total,
    total,
  });
};

const event_cart_viewed = (lineitems, total, couponCode = null) => {

  return gtmEvent('view_cart', {
      currency: 'SAR',
      value: total,
      items: lineitems?.map((item) => ({
        ...(item?.extension_attributes?.product_sku ? {item_id: item?.extension_attributes?.product_sku}: {}),
        ...(typeof item?.name != 'string' ? item?.name?.at(0) : item?.name ? {item_name: typeof item?.name != 'string' ? item?.name?.at(0) : item?.name}: {}),
        ...(couponCode ? {coupon: couponCode}: {}),
        discount:
          item?.extension_attributes?.original_price - (item?.extension_attributes?.special_price || item?.extension_attributes?.original_price),
        ...(item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name ? {item_brand: item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name}: {}),
        ...(item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_category: item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name ? {item_category2: item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name}: {}),
        ...(item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name ? {item_category3: item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name}: {}),
        ...(item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name ? {item_category4: item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name}: {}),
        ...(item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name ? {item_category5: item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name}: {}),
        ...(item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id ? {item_list_id: item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id}: {}),
        ...(item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_list_name: item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(item?.item_variant ? { item_variant: item?.item_variant } : {}),
        ...(validatePrice(priceFormatedObject(item))?.specialPrice || validatePrice(priceFormatedObject(item))?.originalPrice ? {price: validatePrice(priceFormatedObject(item))?.specialPrice || validatePrice(priceFormatedObject(item))?.originalPrice}: {}),
        quantity: +item?.qty,
      })),
    });
  
}

const event_wishlist_viewed = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total
) =>
  gtmEvent('wishlist_viewed', {
    lineitems: lineitems?.map((item) => ({
      name: item?.name.at(0),
      image: item?.extension_attributes?.product_image
        ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`
        : '',
      price: item?.prices_with_tax?.price,
      quantity: 1,
      brand: item?.option_text_brand.at(0),
      categoryname: item?.extension_attributes?.category_names?.at(0).name,
      subcategoryname: item?.extension_attributes?.category_names?.at(1)?.name,
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
  });

const event_product_searched = (keyword, total_items) => {
  if (typeof window != undefined) {
    window.dataLayer = window.dataLayer || [];
    setTimeout(() => {
      window?.dataLayer?.push({ ecommerce: null });
      window?.dataLayer?.push({
        event: 'search',
        searched_term: keyword,
        result: total_items,
      });
    }, 1000);
  }
};
const event_checkout_initiated = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total,
  categoryname,
  subcategoryname
) =>
  gtmEvent('checkout_initiated', {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      image: item?.extension_attributes?.product_image
        ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`
        : '',
      quantity: item?.qty,
      price: item?.price_incl_tax,
      categoryname: item?.extension_attributes?.category_names?.at(0).name,
      subcategoryname: item?.extension_attributes?.category_names?.at(1)?.name,
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
    categoryname,
    subcategoryname,
  });

// const event_checkout_address_saved = (
//   lineitems,
//   numberofproducts,
//   skuid,
//   skuname,
//   subtotal,
//   tax,
//   total,
//   shippingcharges,
//   city,
//   district,
//   categoryname,
//   subcategoryname,
//   email,
//   phoneNumber,
//   firstName,
//   lastName,
// ) =>
//   gtmEvent("checkout_address_saved", {
//     lineitems: lineitems?.map((item) => ({
//       name: item?.name,
//       image: item?.extension_attributes?.product_image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`: '',
//       quantity: item?.qty,
//       price: item?.price_incl_tax,
//       categoryname    : item?.extension_attributes?.category_names?.at(0).name,
//       subcategoryname : item?.extension_attributes?.category_names?.at(1)?.name
//     })),
//     numberofproducts,
//     skuid,
//     skuname,
//     subtotal,
//     tax,
//     total,
//     shippingcharges,
//     city,
//     district,
//     categoryname,
//     subcategoryname,
//     email,
//     phoneNumber,
//     firstName,
//     lastName,
//   });

const event_checkout_address_saved = (lineitems, total, shipping_tier, couponCode = null) =>
  gtmEvent('add_shipping_info', {
    currency: 'SAR',
    value: total,
    ...(shipping_tier ? {shipping_tier}: {}),
    ...(couponCode ? {coupon: couponCode}: {}),
    items: lineitems?.map((item) => ({
      ...(item?.extension_attributes?.product_sku ? {item_id: item?.extension_attributes?.product_sku}: {}),
      ...(typeof item?.name != 'string' ? item?.name?.at(0) : item?.name ? {item_name: typeof item?.name != 'string' ? item?.name?.at(0) : item?.name}: {}),
      ...(couponCode ? {coupon: couponCode}: {}),
      discount:
        item?.extension_attributes?.original_price - (item?.extension_attributes?.special_price || item?.extension_attributes?.original_price),
        ...(item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name ? {item_brand: item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name}: {}),
        ...(item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_category: item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name ? {item_category2: item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name}: {}),
        ...(item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name ? {item_category3: item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name}: {}),
        ...(item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name ? {item_category4: item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name}: {}),
        ...(item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name ? {item_category5: item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name}: {}),
        ...(item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id ? {item_list_id: item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id}: {}),
        ...(item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_list_name: item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(item?.item_variant ? { item_variant: item?.item_variant } : {}),
        ...(item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price ? {price: item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price}: {}),
      quantity: +item?.qty,
    })),
  });

const event_checkout_started = (lineitems, total, couponCode = null) =>
  gtmEvent('begin_checkout', {
    currency: 'SAR',
    value: total,
    items: lineitems?.map((item) => ({
      ...(item?.extension_attributes?.product_sku ? {item_id: item?.extension_attributes?.product_sku}: {}),
      ...(typeof item?.name != 'string' ? item?.name?.at(0) : item?.name ? {item_name: typeof item?.name != 'string' ? item?.name?.at(0) : item?.name}: {}),
      ...(couponCode ? {coupon: couponCode}: {}),
      discount:
        item?.extension_attributes?.original_price - (item?.extension_attributes?.special_price || item?.extension_attributes?.original_price),
      ...(item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name ? {item_brand: item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name}: {}),
      ...(item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_category: item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
      ...(item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name ? {item_category2: item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name}: {}),
      ...(item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name ? {item_category3: item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name}: {}),
      ...(item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name ? {item_category4: item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name}: {}),
      ...(item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name ? {item_category5: item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name}: {}),
      ...(item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id ? {item_list_id: `${item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id}`}: {}),
      ...(item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_list_name: item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
      ...(item?.item_variant ? { item_variant: item?.item_variant } : {}),
      ...(item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price ? {price: item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price}: {}),
      quantity: +item?.qty,
    })),

  });

const event_payment_failed = (amount, mode, reason) =>
  gtmEvent('payment_failed', { amount, mode, reason });

const event_payment_sccuess = (lineitems, total, payment_type, couponCode = null) =>
  gtmEvent('add_payment_info', {
    currency: 'SAR',
    value: total,
    payment_type,
    ...(couponCode ? {coupon: couponCode}: {}),
    items: lineitems?.map((item) => ({
      item_id: item?.sku,
      item_name:
        typeof item?.name != 'string' ? item?.name?.at(0) : item?.name || null,
      ...(couponCode ? {coupon: couponCode}: {}),
      discount:
        item?.prices_with_tax?.original_price - item?.prices_with_tax?.price,
      item_brand:
        item?.option_text_brand?.at(0) ||
        item?.extension_attributes?.brand_name ||
        null,
      item_category:
        item?.category?.at(1)?.name ||
        item?.extension_attributes?.category_names?.at(0) ||
        null,
      item_category2:
        item?.category?.at(2)?.name ||
        item?.extension_attributes?.category_names?.at(1) ||
        null,
      item_category3:
        item?.category?.at(3)?.name ||
        item?.extension_attributes?.category_names?.at(2) ||
        null,
      item_category4:
        item?.category?.at(4)?.name ||
        item?.extension_attributes?.category_names?.at(3) ||
        null,
      item_category5:
        item?.category?.at(5)?.name ||
        item?.extension_attributes?.category_names?.at(4) ||
        null,
      item_list_id: item?.category?.at(2)?.category_id || null,
      item_list_name: item?.category?.at(2)?.name || null,
      ...(item?.item_variant ? { item_variant: item?.item_variant } : {}),  
      price: item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price,
      quantity: lineitems?.length,
    })),
  });

const event_payment_select = (lineitems, total, payment_type, couponCode = null) =>
  gtmEvent('add_payment_info', {
    currency: 'SAR',
    value: total,
    payment_type,
    ...(couponCode ? {coupon: couponCode}: {}),
    items: lineitems?.map((item) => ({
      ...(item?.extension_attributes?.product_sku ? {item_id: item?.extension_attributes?.product_sku}: {}),
      ...(typeof item?.name != 'string' ? item?.name?.at(0) : item?.name ? {item_name: typeof item?.name != 'string' ? item?.name?.at(0) : item?.name}: {}),
      ...(couponCode ? {coupon: couponCode}: {}),
      discount:
        item?.extension_attributes?.original_price - (item?.extension_attributes?.special_price || item?.extension_attributes?.original_price),
        ...(item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name ? {item_brand: item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name}: {}),
        ...(item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_category: item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name ? {item_category2: item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(1)?.name}: {}),
        ...(item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name ? {item_category3: item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(2)?.name}: {}),
        ...(item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name ? {item_category4: item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(3)?.name}: {}),
        ...(item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name ? {item_category5: item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(4)?.name}: {}),
        ...(item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id ? {item_list_id: item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id}: {}),
        ...(item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_list_name: item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name}: {}),
        ...(item?.item_variant ? { item_variant: item?.item_variant } : {}),
        ...(item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price ? {price: item?.prices_with_tax?.price || item?.extension_attributes?.special_price || item?.extension_attributes?.original_price}: {}),
      quantity: +item?.qty,
    })),
  });

  const event_purchase = (orderid, lineitems, tax, total, shippingcharges, couponCode) =>
    gtmEvent("purchase", {
      ...(orderid ? {transaction_id: orderid}: {}),
      ...(tax ? {tax}: {}), // The VAT value of the order
      ...(shippingcharges ? {shipping: shippingcharges}: {}), //Shipping Cost
      currency: "SAR",
      value: total,
      ...(couponCode ? {couponCode}: {}),
      items: lineitems?.map((item) => ({
        ...(item?.sku || item?.extension_attributes?.product_sku ? {item_id: item?.sku || item?.extension_attributes?.product_sku}: {}),
        ...(typeof item?.name != 'string' ? item?.name?.at(0) : item?.name ? {item_name: typeof item?.name != 'string' ? item?.name?.at(0) : item?.name}: {}),
        ...(couponCode ? {coupon: couponCode}: {}),
        discount:(item?.base_original_price - (item?.base_price || item?.base_original_price)) > 0 ? +(item?.base_original_price - (item?.base_price || item?.base_original_price)).toFixed(2): 0,
        ...(item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name ? {item_brand: item?.option_text_brand?.at(0) || item?.extension_attributes?.brand_name} : {}),
        ...(item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(1)?.name ? {item_category: item?.category?.at(1)?.name || item?.extension_attributes?.category_names?.at(1)?.name}: {}),
        ...(item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(2)?.name ? {item_category2: item?.category?.at(2)?.name || item?.extension_attributes?.category_names?.at(2)?.name}: {}),
        ...(item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(3)?.name ? {item_category3: item?.category?.at(3)?.name || item?.extension_attributes?.category_names?.at(3)?.name}: {}),
        ...(item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(4)?.name ? {item_category4: item?.category?.at(4)?.name || item?.extension_attributes?.category_names?.at(4)?.name}: {}),
        ...(item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(5)?.name ? {item_category5: item?.category?.at(5)?.name || item?.extension_attributes?.category_names?.at(5)?.name}: {}),
  
        ...(item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id ? {item_list_id: `${item?.category?.at(0)?.category_id || item?.extension_attributes?.category_names?.at(0)?.id}`} : {}),
        ...(item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name ? {item_list_name: item?.category?.at(0)?.name || item?.extension_attributes?.category_names?.at(0)?.name} : {}),
        ...(item?.variant ? { item_variant: item?.variant } : {}),
        ...(item?.prices_with_tax?.price || item?.row_total_incl_tax ? {price: item?.prices_with_tax?.price || item?.row_total_incl_tax}: {}),
        quantity: +item?.qty_ordered
      })),
    });

const event_refunds = (orderid, lineitems, total) =>
  gtmEvent('refund', {
    transaction_id: orderid,
    currency: 'SAR',
    value: total,
    items: lineitems?.map((item) => ({
      ...(item?.sku ? {item_id: item?.sku}: {}),
      ...((typeof item?.name != 'string' ? item?.name?.at(0) : item?.name) ? {item_name: typeof item?.name != 'string' ? item?.name?.at(0) : item?.name}: {}),
      currency: 'SAR',
      ...(item?.variant ? { item_variant: item?.variant } : {}),
      ...(item?.prices_with_tax?.price || item?.row_total_incl_tax ? {price: item?.prices_with_tax?.price || item?.row_total_incl_tax}: {}),
      quantity: +item?.qty_ordered,
    })),
  });

const event_mobile_number = (mobilenumber) =>
  gtmEvent('mobile_number', { mobilenumber });

const event_user_login = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent('user_login', {
    ...(firstname ? {firstname}: {}),
    ...(lastname ? {lastname}: {}),
    ...(mobilenumber ? {mobilenumber}: {}),
    ...(email ? {email}: {}),
    ...(totalordercount ? {totalordercount}: {}),
    ...(Totalltv ? {Totalltv}: {})
  });
const event_user_logged_in = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent('user_logged_in', {
    ...(firstname ? {firstname}: {}),
    ...(lastname ? {lastname}: {}),
    ...(mobilenumber ? {mobilenumber}: {}),
    ...(email ? {email}: {}),
    ...(totalordercount ? {totalordercount}: {}),
    ...(Totalltv ? {Totalltv}: {})
  });

const event_user_signup = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent('user_signup', {
    ...(firstname ? {firstname}: {}),
    ...(lastname ? {lastname}: {}),
    ...(mobilenumber ? {mobilenumber}: {}),
    ...(email ? {email}: {}),
    ...(totalordercount ? {totalordercount}: {}),
    ...(Totalltv ? {Totalltv}: {})
  });
const event_user_logout = (firstname, lastname, email, userid) =>
  gtmEvent('user_logout', {
    firstname,
    lastname,
    email,
    userid,
  });

const event_newsletter_subscribed = (email) =>
  gtmEvent('newsletter_subscribed', {
    email,
  });

// =========================================| NEW TEAM REQUIREMENTS |=========================================
const event_page_view = (language, user) => {
  gtmEvent('page_view', {
    currency: 'SAR',
    language,
    is_login: user?.id ? 'yes' : 'no',
    user_id: user?.id,
    email: user?.email,
    phone: user?.telephone,
  });
};
export {
  event_coupon_redemption,
  event_global_variables,
  event_login,
  event_sign_up,
  event_otp_verification,
  event_view_item_list,
  event_view_promotion,
  event_select_promotion,
  event_sub_category_viewed,
  event_view_item,
  event_select_item,
  event_add_to_cart,
  event_remove_from_cart,
  event_add_to_wishlist,
  event_remove_from_wishlist,
  event_cart_updated,
  event_wishlist_updated,
  event_cart_viewed,
  event_wishlist_viewed,
  event_product_searched,
  event_checkout_initiated,
  event_checkout_started,
  event_checkout_address_saved,
  event_payment_failed,
  event_payment_sccuess,
  event_payment_select,
  event_purchase,
  event_refunds,
  event_mobile_number,
  event_user_login,
  event_user_signup,
  event_page_viewed,
  event_newsletter_subscribed,
  event_user_logout,
  event_user_logged_in,
  event_page_view,
};
