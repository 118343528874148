const Image = ({
  src,
  alt,
  fetchPriority = 'low',
  loading = 'lazy',
  ...rest
}) => {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src || '/imgs/placeholder.webp'}
      alt={alt}
      loading={loading}
      fetchPriority={fetchPriority}
      className={
        rest?.fill ? 'w-full object-fill ' + rest?.className : rest?.className
      }
      {...rest}
    />
  );
};

export default Image;
