const UserIcon = ({ width = 19, height = 19, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.37631 9.96209C6.89943 9.96209 4.88354 7.94621 4.88354 5.46933C4.88354 2.99245 6.89943 0.976562 9.37631 0.976562C11.8532 0.976562 13.8691 2.99245 13.8691 5.46933C13.8691 7.94621 11.8532 9.96209 9.37631 9.96209ZM9.37631 2.14859C7.54795 2.14859 6.05557 3.64097 6.05557 5.46933C6.05557 7.29769 7.54795 8.79007 9.37631 8.79007C11.2047 8.79007 12.6971 7.29769 12.6971 5.46933C12.6971 3.64097 11.2047 2.14859 9.37631 2.14859Z'
      fill='currentColor'
    />
    <path
      d='M16.0882 17.7753C15.7679 17.7753 15.5022 17.5096 15.5022 17.1893C15.5022 14.4936 12.7519 12.3058 9.37642 12.3058C6.00099 12.3058 3.25064 14.4936 3.25064 17.1893C3.25064 17.5096 2.98498 17.7753 2.66463 17.7753C2.34427 17.7753 2.07861 17.5096 2.07861 17.1893C2.07861 13.8529 5.35247 11.1338 9.37642 11.1338C13.4004 11.1338 16.6742 13.8529 16.6742 17.1893C16.6742 17.5096 16.4086 17.7753 16.0882 17.7753Z'
      fill='currentColor'
    />
  </svg>
);
export default UserIcon;
