// use selected attributes to retrive only needed data from backend
const requiredAtteributes = [
  'sku',
  'name',
  'option_text_brand',
  'prices_with_tax',
  'image',
  '_media_',
  'stock',
  'label',
  'product_fees',
  'rewrite_url',
  'attributes',
  'product_attributes',
  'eligible_for_tabby',
  'eligible_for_tamara',
  'product_fees',
  'option_text_eligible_for_tabby',
  'option_text_eligible_for_tamara',
  'back_orders',
  'show_discount_price',
  'custom_related_products',
  'brand_logo',
  'reviews_count',
  'option_text_color',
  'flash_sale_end_date',
  'flash_sale_start_date',
  'has_options',
  '_configurables',
  'option_details',
  'category',
  'suggest',
  'entity_id',
];

const selectedAtteributes = requiredAtteributes
  ?.map((attr) => `select=${attr}`)
  ?.join('&');
export default selectedAtteributes;
