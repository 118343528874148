import React from 'react';

const Image = ({
  src,
  alt = '',
  className = '',
  loading = 'lazy',
  ...props
}) => {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src || ''}
      alt={alt}
      title={alt}
      loading={loading}
      className={className}
      {...props}
    />
  );
};

export default Image;
