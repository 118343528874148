const FillHeart = (props) => (
  <svg
    width={18}
    height={15}
    viewBox='0 0 18 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.048 4.56301C17.048 9.63655 9.09027 13.9068 9.09027 13.9068C9.09027 13.9068 1.13184 9.57343 1.13184 4.57329C1.13184 2.53176 2.9003 0.90676 5.11089 0.90676C7.32147 0.90676 9.08994 3.34426 9.08994 3.34426C9.08994 3.34426 10.8584 0.90676 13.069 0.90676C15.2796 0.90676 17.048 2.53176 17.048 4.56301Z'
      fill='currentColor'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default FillHeart;
