const CloseIcon = (props) => (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 76 76"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      baseProfile="full"
      enableBackground="new 0 0 76.00 76.00"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#000000"
        fillOpacity={1}
        strokeWidth={0.2}
        strokeLinejoin="round"
        d="M 26.9166,22.1667L 37.9999,33.25L 49.0832,22.1668L 53.8332,26.9168L 42.7499,38L 53.8332,49.0834L 49.0833,53.8334L 37.9999,42.75L 26.9166,53.8334L 22.1666,49.0833L 33.25,38L 22.1667,26.9167L 26.9166,22.1667 Z "
      />
    </svg>
  );
  export default CloseIcon;