// components
import Modal from '@/components/modals';
import Image from '@/components/Image';
import Heading from '@/components/category/Heading';
import SideMenu from '@/components/category/SideMenu';
import BreadCrumb from '@/components/global/BreadCrumb';
import FilterIcon from '@/components/icons/FilterIcon';
import FilterSort from '@/components/category/FilterSort';
import ProductsList from '@/components/category/ProductsList';

// constants
import { sortKeys } from '@/constants/Category';
import { useEffect, useMemo, useRef } from 'react';
import Category from '../global/swiper/Category';
import { useTranslation } from 'next-i18next';
import { event_view_item_list } from '@/utils/gtm';
import { getCookie } from 'cookies-next';

const Body = ({
  t,
  setInputValue,
  allProducts,
  ratings,
  handleSorting,
  selectedFilterKeys,
  handleFilter,
  handleResetFilter,
  allFilters,
  categoryID,
  fromCategory = true,
  currentCategory = null,
  setFilter,
  filter,
  suggestedProducts,
  imageBanner = null,
  category_description = null,
  ref,
  subCategories = [],
}) => {
  const {
    i18n: { language },
  } = useTranslation('common');

  const suggestedWords = useMemo(() => {
    const words = allProducts
      ?.slice(0, 5)
      ?.map((item) => item?.suggest)
      ?.flat(1)
      ?.filter((item) => item?.length >= 3)
      ?.slice(0, 15);

    return [...new Set(words || [])];
  }, [allProducts]);

  const event_view_item_list_ref = useRef(false);

  useEffect(() => {
    if (!event_view_item_list_ref?.current) {
      const categoryName = fromCategory ? currentCategory : 'search_results';
      const category_ID = fromCategory ? categoryID : 'search_results';
      setTimeout(() => {
        event_view_item_list(
          categoryName,
          category_ID,
          allProducts,
          getCookie('couponCode') || null
        );
      }, 500);
      event_view_item_list_ref.current = true;
    }
  }, [allProducts, categoryID, currentCategory, fromCategory]);

  return allProducts?.length ? (
    <>
      <BreadCrumb />
      {imageBanner ? (
        <Image
          // src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/${imageBanner.slice(5)}` || `${process.env.NEXT_PUBLIC_IMG_BASEURL}/${imageBanner}`}
          src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/${imageBanner}`}
          className='max-h-[400px] w-full object-contain md:rounded-sm'
          loading='lazy'
          alt='Category Banner'
        />
      ) : null}
      <div className='my-4'>
        {subCategories?.length > 0 && (
          <Category language={language} categories={subCategories} />
        )}
      </div>
      <div className='my-4 grid grid-cols-12 gap-4 md:mx-auto'>
        <SideMenu
          {...{
            filterList: allFilters,
            t,
            selectedFilterKeys,
            handleFilter,
            handleResetFilter,
            setInputValue: setInputValue,
            suggestedWords: fromCategory ? [] : suggestedWords,
            suggestedProducts,
          }}
        />
        <div className='col-span-full flex flex-col md:col-span-9'>
          <Heading
            {...{
              sortKeys,
              allProducts,
              handleSorting,
              category_description,
              t,
              categoryName: currentCategory,
            }}
          />
          <ProductsList
            {...{
              ratings,
              allProducts,
              categoryID: fromCategory ? categoryID : 'search_results',
              categoryName: fromCategory ? currentCategory : 'search_results',
            }}
          />
          <FilterSort {...{ sortKeys, handleSorting, setFilter, t }} />
        </div>
      </div>
      <Modal show={filter}>
        <div
          ref={ref}
          className='gray-scroll max-h-screen min-h-screen w-11/12 overflow-y-auto bg-white'
        >
          <div className='flex items-center gap-2 bg-zinc-200 p-3'>
            <FilterIcon />
            <span className='font-semibold'>{t('Filter')}</span>
          </div>
          <SideMenu
            {...{
              filterList: allFilters,
              t,
              selectedFilterKeys,
              handleFilter,
              handleResetFilter,
              isModal: true,
              setShowFilter: setFilter,
            }}
          />
          {allFilters && Array.isArray(allFilters) && allFilters?.length ? (
            <div className='grid grid-cols-6 gap-x-3 p-3 pt-0 text-sm'>
              <button
                onClick={() => setFilter(false)}
                className='col-span-4 rounded-md bg-black p-1.5 text-white'
              >
                {t('Filter')}
              </button>
              <button
                onClick={() => {
                  setFilter(false);
                  handleResetFilter();
                }}
                className='col-span-2 rounded-md  p-1.5'
              >
                {t('Cancel')}
              </button>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  ) : (
    <p className='h-screen text-center'>{t('There are no products')}</p>
  );
};

export default Body;
