import { event_add_to_wishlist } from "../gtm";

export const handleWishList = (wishlist, product, dispatch) => {
  if (product?.sku in wishlist) {
    dispatch({ type: 'remove_wl', payload: product?.sku });
  } else {
    dispatch({ type: 'add_wl', payload: product });
    event_add_to_wishlist(product, 1)
  }
};
