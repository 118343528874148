import Heart from '@/components/icons/Heart';
import useClient from '@/hooks/global/useClient';
// import SearchIcon from '@/components/icons/SearchIcon';
// import ShoppingCart from '@/components/icons/ShoppingCart';
import ProfileCircle from '@/components/icons/ProfileCircle';

import { useRouter } from 'next/router';
// import { useCart } from '@/context/cart';
import { signOut, useSession } from 'next-auth/react';
import { useWishlist } from '@/context/wishlist';
import CartHeader from './CartHeader';
import { getCookie } from 'cookies-next';
// import { calcCartTotalHandler } from '@/helpers/cart';

const UserActions = ({
  t,
  // setOpen,
  // open,
  setAuth,
}) => {
  const { push, locale } = useRouter();
  const { data: session } = useSession();

  const { wishlist } = useWishlist();

  const { isClient } = useClient();
  return (
    isClient && (
      <div className='order-2 flex items-center gap-3.5 md:order-3 md:gap-8'>
        <div
          onClick={() => {
            const mage = getCookie('mage');
            if (session?.user?.id && mage) {
              push('/user');
            } else {
              signOut({ redirect: false });
              setAuth('Login');
            }
          }}
          className='flex flex-row-reverse items-center gap-1.5'
        >
          <div className='relative flex cursor-pointer flex-col'>
            {session?.user?.telesales && (
              <p className='rounded-md bg-black text-center text-sm text-white'>
                {t('Telesales')}
              </p>
            )}
            {/* <span className='hidden text-[15px] font-semibold text-zinc-500 max-md:text-sm md:block'>
              {session?.user?.id ? t('welcome') : t('Sign In')}
            </span> */}
            <span className='text-xl  font-semibold md:-mt-0.5'>
              {session?.user?.id && getCookie('mage')
                ? session?.user?.firstname
                : t('Account')}
            </span>
          </div>
          <ProfileCircle className='max-sm:size-7 md:size-8' />
        </div>
        <div
          onClick={() => push('/user/wishlist')}
          className='relative cursor-pointer max-md:hidden'
        >
          {Object.keys(wishlist)?.length > 0 && (
            <span className='absolute -right-2 -top-2 flex size-5 items-center justify-center rounded-full bg-black text-xs text-white'>
              {Object.keys(wishlist)?.length}
            </span>
          )}
          <Heart />
        </div>
        <CartHeader t={t} locale={locale} />
        {/* <SearchIcon
          onClick={() => setOpen(!open)}
          className='block max-sm:size-6 md:hidden'
        /> */}
      </div>
    )
  );
};

export default UserActions;
