import axiosRequest from '@/utils/axios-config';
import { decodeJwt } from '@/utils/global';
import { setCookie } from 'cookies-next';

const handShake = async (locale, req, res) => {
  const is_uae_store = locale?.toLowerCase()?.includes('ae')
  const config = {
    method: 'post',
    url: '/handshake',
    data: { store: is_uae_store ? 'uae_'.concat(locale.toLowerCase().slice(0, 2)) : locale },
  };
  try {
    const reqToken = await axiosRequest(config);
    if (req && res) {
      setCookie('handshake', reqToken?.data?.token, {
        req,
        res,
        maxAge: process.env?.NEXT_PUBLIC_HANDSHAKE_MAX_AGE,
      });
    } else {
      console.log('reqToken?.data?.token in else', reqToken?.data?.token);
      setCookie('handshake', reqToken?.data?.token, {
        maxAge: process.env.NEXT_PUBLIC_HANDSHAKE_MAX_AGE,
      });
    }
    return reqToken;
  } catch (error) {
    return error;
  }
};

const handleHandshakeStore = locale => {
  const is_uae_store = locale?.toLowerCase()?.includes('ae')
  return is_uae_store? 'uae_'.concat(locale.toLowerCase().slice(0, 2)) : locale;
}

const fetchHandshake = async ({ req, res, locale }) => {
  // get handshake from cookies or generate new handshake
  const { handshake: token } = req.cookies;
  const store = handleHandshakeStore(locale)
  const handshake =
    token &&
    store == decodeJwt(token)?.storeName &&
    decodeJwt(token)?.exp > Date.now()
      ? token
      : (
          await handShake(
            locale,
            req,
            res
          )
        )?.data?.token;

  return handshake;
};

export { handShake, fetchHandshake };
