import Arrow from '@/components/icons/Arrow';
import { useState } from 'react';

const Brands = ({ options, t, selectedFilterKeys, handleFilter, code }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <ul className='mt-2.5'>
      {options
        ?.slice(0, showMore ? options?.length : 11)
        ?.map((option, idx) => (
          <li
            id={`brand-${option?.key}`}
            key={`filter-option-${idx}`}
            className='mb-2 flex cursor-pointer items-center gap-2 font-[400] text-[#586A84] last:mb-0'
            onClick={() =>
              handleFilter({
                code,
                item: option,
              })
            }
          >
            <input
              id={`brand-${option?.key}`}
              type='checkbox'
              className={`size-4 cursor-pointer appearance-none rounded-sm border checked:border-black checked:bg-black ${
                selectedFilterKeys?.includes(`${code}=${option?.Key}`)
                  ? 'checked:border-0 checked:bg-green-700'
                  : ''
              }`}
              checked={selectedFilterKeys?.includes(`${code}=${option?.Key}`)}
            />
            <label
              htmlFor={`brand-${option?.key}`}
              className='flex cursor-pointer items-center gap-1.5 text-sm'
            >
              <span>{option?.Label}</span>
              <span className='text-sm opacity-40'>({option?.DocCount})</span>
            </label>
          </li>
        ))}

      {options?.length > 10 ? (
        <li
          className='flex cursor-pointer items-center gap-2 text-sm text-slate-600'
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? (
            <span>{t('Show More')}</span>
          ) : (
            <span>{t('Show Less')}</span>
          )}
          <Arrow
            className={`mt-0.5 size-2.5 transition-all ${showMore ? 'rotate-180' : ''}`}
          />
        </li>
      ) : null}
    </ul>
  );
};

export default Brands;
