import { useState } from 'react';

import Link from 'next/link';
import Search from './Search';
import Image from '@/components/Image';
import UserActions from './UserActions';
import BurgerMenu from '@/components/icons/BurgerMenu';
import SideMenu from '@/components/modals/SideMenu';

const Navbar = ({
  t,
  setInputValue,
  inputValue,
  setAuth,
  ourSearchInput,
  ourSearchButton,
}) => {
  const [open, setOpen] = useState(false);
  const [side, setSide] = useState(false);
  return (
    <>
      <nav className='mx-auto w-11/12  py-4'>
        <div className='mx-auto flex max-w-[1440px] flex-wrap items-center justify-between gap-2 md:flex-nowrap md:gap-7 lg:gap-10'>
          <div className='order-1 flex items-center gap-1.5 sm:gap-3'>
            <BurgerMenu
              onClick={() => setSide(true)}
              className='hidden cursor-pointer max-md:block'
            />
            <Link href='/'>
              <Image
                loading='eager'
                src='/imgs/layout/Logo.png'
                className='h-auto w-[135px] object-contain min-[420px]:w-40 sm:w-44'
                alt='Logo'
              />
            </Link>
          </div>
          <Search
            {...{
              t,
              open,
              setInputValue,
              inputValue,
              ourSearchInput,
              ourSearchButton,
            }}
          />
          <UserActions {...{ t, open, setOpen, setAuth }} />
        </div>
      </nav>
      {side && <SideMenu {...{ setSide, t, setAuth }} />}
    </>
  );
};

export default Navbar;
