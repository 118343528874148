import { useContext } from 'react';
import { WishlistContext } from './Provider';

const useWishlist = () => {
  const context = useContext(WishlistContext);
  if (!context) {
    throw new Error('useWishlist must be used within a Wishlist Provider');
  }
  return context;
};

export { useWishlist };
