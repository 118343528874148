import FaceBook from '@/components/icons/FaceBook';
import Instagram from '@/components/icons/Instagram';
import Sanpchat from '@/components/icons/Sanpchat';
import TikTokIcon from '@/components/icons/TikTokIcon';
import Twitter from '@/components/icons/Twitter';
import Youtube from '@/components/icons/Youtube';
import Link from 'next/link';
import React from 'react';

const FollowUs = ({ t }) => {
  return (
    <div className='flex flex-col items-center justify-center gap-2 sm:flex-row sm:gap-4'>
      <span className='text-sm font-bold'>{t('Follow Us On')}</span>
      <div className='flex items-center justify-center gap-2'>
        <div className='grid size-8 place-items-center rounded-md bg-black text-white sm:size-6'>
          <Link target='_blank' href={'https://www.tiktok.com/@qasralawani'}>
            <TikTokIcon className='size-3' />
          </Link>
        </div>
        <div className='grid size-8 place-items-center rounded-md bg-black text-white sm:size-6'>
          <Link target='_blank' href={'https://www.facebook.com/Qasrawani/'}>
            <FaceBook className='size-3' />
          </Link>
        </div>
        <div className='grid size-8 place-items-center rounded-md bg-black text-white sm:size-6'>
          <Link target='_blank' href={'https://www.instagram.com/qasralawani'}>
            <Instagram className='size-3' />
          </Link>
        </div>
        <div className='grid size-8 place-items-center rounded-md bg-black text-white sm:size-6'>
          <Link target='_blank' href={'https://www.snapchat.com/add/qasrawani'}>
            <Sanpchat className='size-3' />
          </Link>
        </div>
        <div className='grid size-8 place-items-center rounded-md bg-black text-white sm:size-6'>
          <Link target='_blank' href={'https://twitter.com/Qasralawani'}>
            <Twitter className='size-3' />
          </Link>
        </div>
        <div className='grid size-8 place-items-center rounded-md bg-black text-white sm:size-6'>
          <Link target='_blank' href={'https://www.youtube.com/@QasrAwani'}>
            <Youtube className='size-3' />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FollowUs;
