const NotFound = (props) => (
  <svg
    width={344}
    height={280}
    viewBox='0 0 344 280'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M149.437 231.08C149.468 228.632 149.375 225.235 148.792 221.247C148.302 217.889 146.724 207.162 141.065 199.055C132.515 186.797 122.822 192.261 112.887 179.591C107.198 172.338 108.799 168.537 102.3 162.739C96.556 157.608 90.3919 155.719 83.8158 154.639C67.1889 151.903 40.0605 154.468 40.0605 154.468C42.3536 180.757 51.1917 191.631 58.8172 196.7C69.5209 203.82 77.7527 199.459 87.2982 207.978C99.7197 219.071 91.5656 231.64 103.148 243.183C103.148 243.183 108.216 248.236 147.571 255.714L149.437 231.08Z'
      fill='#F5F5F5'
    />
    <path
      d='M147.564 255.714C147.564 255.714 117.163 203.976 105.588 193.839C94.0064 183.703 40.0605 154.468 40.0605 154.468'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M79.0968 176.528L45.1436 178.433'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M86.1162 180.733L88.3704 155.548'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M101.554 190.706L72.1641 201.566'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M113.983 203.618L117.769 184.527'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M123.474 217.143L95.001 222.343'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M130.897 228.546L141.609 199.855'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M137.652 239.328L104.725 244.178'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M131.364 235.13C130.462 232.853 129.071 229.751 127.003 226.292C125.262 223.377 119.712 214.065 111.379 208.733C98.7944 200.68 91.9229 209.432 77.9001 201.519C69.8704 196.995 69.9015 192.867 61.6697 189.991C54.4017 187.45 47.9811 188.064 41.4983 189.572C25.0891 193.396 1 206.136 1 206.136C13.165 229.557 25.4855 236.226 34.4713 237.999C47.0794 240.486 53.0259 233.311 65.0976 237.54C80.815 243.043 78.0866 257.774 93.1977 264.015C93.1977 264.015 99.8127 266.744 139.036 258.621L131.364 235.13Z'
      fill='#F5F5F5'
    />
    <path
      d='M139.052 258.613C139.052 258.613 91.1845 222.421 76.6098 217.478C62.0351 212.534 1.01562 206.136 1.01562 206.136'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M45.5168 211.609L14.8672 226.339'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M53.6015 212.806L46.0615 188.678'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M71.6815 216.125L48.6807 237.392'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M88.1067 223.299L84.3057 204.216'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M102.043 232.177L77.7207 247.863'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M113.261 239.872L112.196 209.269'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M123.63 247.257L95.0557 264.327'
      stroke='#D6D6D6'
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M242.116 259.002H138.469L148.45 190.466H232.136L242.116 259.002Z'
      fill='#49494F'
    />
    <path
      d='M319.165 202.553H61.4293C54.8843 202.553 49.5752 197.244 49.5752 190.699V28.3248C49.5752 21.7798 54.8843 16.4707 61.4293 16.4707H319.165C325.71 16.4707 331.019 21.7798 331.019 28.3248V190.691C331.019 197.244 325.71 202.553 319.165 202.553Z'
      fill='black'
    />
    <path
      d='M312.331 183.027H68.2535C61.7085 183.027 56.3994 177.718 56.3994 171.173V33.9293C56.3994 27.3843 61.7085 22.0752 68.2535 22.0752H312.331C318.876 22.0752 324.185 27.3843 324.185 33.9293V171.173C324.185 177.718 318.876 183.027 312.331 183.027Z'
      fill='#F5F5F5'
    />
    <path
      d='M264.084 263.992H116.502V256.864C116.502 251.353 120.972 246.883 126.483 246.883H254.103C259.614 246.883 264.084 251.353 264.084 256.864V263.992Z'
      fill='black'
    />
    <path
      d='M65.1289 183.027H310.505'
      stroke='#262626'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M123 260.432V256.685C123 254.423 124.834 252.589 127.096 252.589H237.779'
      stroke='white'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M226.02 208.025L229.945 237.975'
      stroke='#262626'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M187.821 199.14C190.993 199.14 193.565 196.569 193.565 193.396C193.565 190.223 190.993 187.652 187.821 187.652C184.648 187.652 182.076 190.223 182.076 193.396C182.076 196.569 184.648 199.14 187.821 199.14Z'
      fill='white'
    />
    <path
      d='M269.509 32.9963H299.987C305.794 32.9963 310.505 37.7069 310.505 43.5135V106.842'
      stroke='white'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M292.773 53.0593C294.53 48.8229 296.271 45.5504 297.997 43.234C299.715 40.9176 301.961 39.0675 304.721 37.6917C306.338 36.8444 308.017 36.2459 309.773 35.8728C311.522 35.5074 313.194 35.041 314.772 34.4814C316.349 33.9217 317.803 33.1521 319.132 32.1883C320.462 31.2244 321.558 29.7009 322.42 27.6176C323.882 24.1042 323.936 21.2359 322.576 19.0361C321.216 16.8285 319.21 15.1728 316.559 14.069C312.751 12.4833 309.696 12.4599 307.379 13.9757C305.063 15.4993 302.98 17.9789 301.13 21.4146L287.239 15.6392C288.7 12.1257 290.605 9.16412 292.944 6.76998C295.284 4.37584 297.935 2.61133 300.889 1.48422C303.842 0.357107 307.029 -0.132599 310.457 0.0306375C313.885 0.193874 317.391 1.01783 320.982 2.51028C328.732 5.73615 334.197 9.85593 337.376 14.8774C340.547 19.8989 340.656 25.9697 337.702 33.0744C336.746 35.3753 335.681 37.2875 334.492 38.811C333.311 40.3423 331.958 41.6094 330.442 42.6199C328.927 43.6304 327.232 44.4621 325.366 45.1151C323.493 45.768 321.425 46.3821 319.171 46.9573C317.08 47.5248 315.339 48.1233 313.94 48.7607C312.541 49.3981 311.344 50.191 310.364 51.1237C309.385 52.0643 308.561 53.1292 307.892 54.3263C307.224 55.5234 306.563 56.9148 305.91 58.4927L292.773 53.0593ZM283.702 73.666L289.563 59.5576L304.099 65.5974L298.238 79.7057L283.702 73.666Z'
      fill='#49494F'
    />
    <path
      d='M208.062 74.2333V62.7134C208.062 58.9357 205 55.873 201.222 55.873H134.349C130.571 55.873 127.509 58.9357 127.509 62.7134V74.2333V150.978C127.509 154.756 130.571 157.818 134.349 157.818H241.285C245.063 157.818 248.125 154.756 248.125 150.978V81.0737C248.125 77.2959 245.063 74.2333 241.285 74.2333H208.062Z'
      fill='#F5F5F5'
    />
    <path
      d='M201.292 90.3236L187.821 103.795L174.35 90.3236L166.22 98.4543L179.691 111.925L166.22 125.396L174.35 133.527L187.821 120.056L201.292 133.527L209.423 125.396L195.952 111.925L209.423 98.4543L201.292 90.3236Z'
      fill='#D6D6D6'
    />
    <path
      d='M41.849 263.332V254.463H25.5098V278.855L62.3935 279.446V275.007L41.849 263.332Z'
      fill='#F7A99C'
    />
    <path
      d='M63.6985 279.446V277.339C63.6985 275.186 62.5169 273.212 60.6281 272.193L45.0739 263.798C40.41 265.073 36.811 264.894 34.3469 264.474C30.2582 263.783 26.3483 261.925 25.0347 263.48C24.6616 263.923 24.6149 264.506 24.6305 264.91V277.806C24.6305 278.707 25.3611 279.446 26.2706 279.446H63.6985Z'
      fill='black'
    />
    <path
      d='M63.6988 278.039H24.623'
      stroke='#E8F4FF'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M55.0866 269.379L52.9023 272.271'
      stroke='#E8F4FF'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M53.485 268.477L51.3008 271.369'
      stroke='#E8F4FF'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M51.8141 267.638L49.6221 270.53'
      stroke='#E8F4FF'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M49.3418 266.107C46.6911 267.117 42.3148 268.415 36.7958 268.384C31.4712 268.353 27.2348 267.102 24.623 266.107'
      stroke='#E8F4FF'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M88.4096 263.332V254.463H72.0781V278.855L108.962 279.446V275.007L88.4096 263.332Z'
      fill='#F7A99C'
    />
    <path
      d='M110.267 279.446V277.339C110.267 275.186 109.085 273.212 107.196 272.193L91.6423 263.798C86.9784 265.073 83.3794 264.894 80.9153 264.474C76.8266 263.783 72.9167 261.925 71.603 263.48C71.2299 263.923 71.1833 264.506 71.1988 264.91V277.806C71.1988 278.707 71.9295 279.446 72.839 279.446H110.267Z'
      fill='black'
    />
    <path
      d='M110.267 278.039H71.1914'
      stroke='#E8F4FF'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M101.654 269.379L99.4619 272.271'
      stroke='white'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M100.054 268.477L97.8623 271.369'
      stroke='white'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M98.3747 267.638L96.1904 270.53'
      stroke='white'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M95.9101 266.107C93.2595 267.117 88.8832 268.415 83.3642 268.384C78.0396 268.353 73.8032 267.102 71.1914 266.107'
      stroke='white'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M79.0275 137.009C79.0275 137.009 92.4829 179.731 94.5506 185.646C96.6182 191.562 98.5382 199.84 98.0951 207.085C97.6521 214.329 90.7028 258.971 90.7028 258.971H70.4537C70.4537 258.971 69.0234 211.811 68.3316 208.119C67.6398 204.426 61.7322 183.516 61.7322 183.516C61.7322 183.516 59.8122 207.971 58.1876 214.469C56.563 220.975 43.5507 259.701 43.5507 259.701H24.623L32.6061 137.002H79.0275V137.009Z'
      fill='black'
    />
    <path
      d='M109.895 110.969C109.895 110.969 116.315 105.52 117.94 101.952C119.331 98.9053 121.274 93.5418 121.811 92.0571C121.889 91.8317 122.122 91.7073 122.355 91.7617L122.744 91.855C123.933 92.1348 124.671 93.3319 124.384 94.5212L123.739 97.1952L130.525 92.7411C131.108 92.3603 131.893 92.5468 132.235 93.1531C132.538 93.6895 132.39 94.3657 131.9 94.7233L127.423 97.988L134.862 94.4979C135.437 94.2258 136.121 94.4435 136.44 94.9954L136.448 95.0109C136.782 95.5939 136.58 96.3401 135.989 96.6744L136.075 96.6511C136.72 96.4645 137.404 96.7754 137.684 97.3895C138.01 98.0969 137.676 98.9286 136.961 99.224L136.487 99.4183C137.101 99.224 137.754 99.6126 137.878 100.242C138.065 101.167 137.614 102.1 136.782 102.535C134.784 103.577 133.478 103.849 131.582 105.272C130.074 106.399 130.602 107.114 129.001 109.174C127.042 111.677 125.379 111.747 122.013 114.226C120.73 115.167 118.966 116.636 117.1 118.812L109.895 110.969Z'
      fill='#F7A99C'
    />
    <path
      d='M132.351 93.4253C132.351 93.4253 132.693 94.1171 132.048 94.6068C131.403 95.0965 126.343 98.8432 126.343 98.8432'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M135.996 96.6821L129.031 99.9158'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M136.713 99.3796L129.717 102.598'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M123.234 92.0419C123.234 92.0419 124.454 92.8192 124.423 93.6742C124.392 94.5293 123.156 100.048 123.156 100.048'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M70.4534 258.971C70.4534 258.971 68.619 210.622 68.4791 209.37C68.3391 208.119 61.4443 182.529 61.4443 182.529L62.6647 171.219C62.6647 171.219 76.5709 205.445 76.5709 207.699C76.5709 209.953 78.1178 258.979 78.1178 258.979H70.4534V258.971Z'
      fill='black'
    />
    <path
      d='M58.9717 211.516L63.9076 158.083'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M91.4807 254.867H70.291L70.4543 258.971H90.7034L91.4807 254.867Z'
      fill='#262626'
    />
    <path
      d='M44.7711 256.04H24.864L24.623 259.709H43.543L44.7711 256.04Z'
      fill='#262626'
    />
    <path
      d='M92.7158 189.875C95.3976 198.317 95.8251 207.458 93.9362 216.109'
      stroke='white'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M115.234 105.225C115.234 105.225 111.806 107.868 107.896 108.552C103.318 109.353 99.3459 106.648 98.281 105.917C95.3816 103.935 94.3089 101.587 92.3889 98.8356C86.7067 90.6971 83.17 85.6445 78.2262 82.7762C75.6688 81.2993 71.4014 79.6281 64.662 80.1411C63.154 79.597 60.7443 80.0323 59.1275 80.0323C57.7827 80.0323 56.4458 80.0323 55.101 80.0323C52.4581 80.351 32.9785 82.613 29.6672 79.5737C28.3613 78.3766 28.3379 77.1951 28.3379 77.1951C28.3224 76.2157 28.9365 75.2052 30.5533 73.977C34.4943 70.9765 41.7311 68.7301 44.1564 68.015L42.6795 56.9771C42.6795 56.9771 42.6795 56.9771 39.4303 56.9771C36.1811 56.9771 24.6457 58.897 18.4427 61.0657C9.73673 64.1051 5.07282 66.3282 2.77196 71.373C-0.111889 77.6926 2.95852 84.4863 3.75915 86.2508C7.75457 95.089 16.616 98.6724 21.9717 100.833C26.69 102.738 30.8953 103.507 33.818 103.842L31.3073 144.052L77.5266 146.563H84.4681C84.9966 140.422 82.3382 121.331 82.688 115.097C88.5879 123.306 98.3665 127.456 107.64 125.676C117.908 123.702 123.077 115.323 123.917 113.916C120.326 106.826 115.234 105.225 115.234 105.225Z'
      fill='#FEC02D'
    />
    <path
      d='M57.8142 102.31H57.3711V83.1183H60.286V99.8462C60.2783 101.206 59.1745 102.31 57.8142 102.31Z'
      fill='white'
    />
    <path
      d='M84.5305 144.433C82.937 144.729 61.0633 148.398 45.6025 132.074C32.5591 118.3 34.0981 100.872 34.3702 98.2996C33.5618 110.387 32.7456 122.474 31.9372 134.569H31.8906L31.2998 144.045L38.5444 144.441L38.5366 144.387C49.3414 145.242 60.4959 145.879 71.9691 146.252C76.1744 146.392 80.333 146.493 84.4528 146.555C84.4839 145.848 84.5072 145.141 84.5305 144.433Z'
      fill='#FEC02D'
    />
    <path
      d='M72.2791 65.5047C73.072 61.4393 72.9476 58.2601 72.7144 56.2313C72.5434 54.6845 72.2714 53.5185 71.4474 52.4069C71.2764 52.1737 69.9083 50.3781 67.5686 49.9117C64.7702 49.3521 64.0629 51.3809 60.083 51.8783C56.6473 52.3059 55.971 50.9378 53.6624 51.9172C51.1439 52.9899 49.8924 55.4851 49.5193 56.2391C49.356 56.5733 48.5321 58.2601 48.4466 60.6387C48.3844 62.5198 48.8119 63.9345 48.9829 64.5098C49.698 66.9428 50.5375 70.6428 51.5714 76.5659C52.1077 76.5426 52.6441 76.5271 53.1804 76.5038C59.5544 72.8348 65.9129 69.1737 72.2791 65.5047Z'
      fill='#262626'
    />
    <path
      d='M58.6684 85.5049C55.6602 85.5127 53.2194 83.0874 53.2039 80.0792L53.1572 68.365L64.0475 68.3262L64.0941 80.0403C64.1019 83.0408 61.6766 85.4893 58.6684 85.5049Z'
      fill='#F7A99C'
    />
    <path
      d='M64.0774 76.7598L56.9883 73.1141C57.0582 73.7282 57.4624 76.6043 59.9887 78.5865C61.6056 79.8535 63.3001 80.1411 64.0852 80.2266L64.0774 76.7598Z'
      fill='#FF7769'
    />
    <path
      d='M52.8928 64.7349C52.8617 64.5561 52.3953 64.2918 52.2476 64.1985C52.0067 64.0431 51.7424 63.9187 51.4703 63.8332C50.9417 63.6622 50.3588 63.6233 49.8069 63.7477C48.7186 63.9964 47.7625 64.9059 47.576 66.0252C47.4671 66.6704 47.6226 67.3467 47.9024 67.9374C48.3222 68.8314 49.0295 69.5854 49.8846 70.0751C50.592 70.487 51.4004 70.7125 52.2165 70.7669C52.3798 70.7746 54.051 70.7358 54.0432 70.6736C53.6624 68.6992 53.2815 66.717 52.8928 64.7349Z'
      fill='#F7A99C'
    />
    <path
      d='M51.3997 64.8515C51.5318 65.7221 52.2936 70.3316 56.4523 73.5964C57.214 74.1949 62.3055 78.1825 66.9616 76.2392C72.9469 73.7363 72.294 63.911 72.2474 63.3824C72.1074 61.5868 71.6877 56.2855 67.2958 53.4561C63.7124 51.1474 59.8336 51.9947 59.2817 52.1268C58.6987 52.2668 55.0064 53.1918 52.7444 56.8141C50.7001 60.0944 51.1898 63.4213 51.3997 64.8515Z'
      fill='#F7A99C'
    />
    <path
      d='M68.8593 63.3743C68.9526 64.1439 69.5511 64.6958 70.1807 64.618C70.8104 64.5403 71.2457 63.8485 71.1524 63.0867C71.0591 62.3172 70.4683 61.7653 69.8309 61.843C69.1935 61.9207 68.7582 62.6125 68.8593 63.3743Z'
      fill='#262626'
    />
    <path
      d='M61.9403 64.2454C62.0336 65.0149 62.6244 65.5668 63.2618 65.4891C63.8914 65.4114 64.3267 64.7196 64.2334 63.9578C64.1401 63.1882 63.5416 62.6364 62.912 62.7141C62.2823 62.7918 61.847 63.4759 61.9403 64.2454Z'
      fill='#262626'
    />
    <path
      d='M66.7672 64.5249L67.8244 65.9552C68.0187 66.2195 67.9487 66.5926 67.6689 66.7636L66.6973 67.3543'
      stroke='#262626'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M59.6258 67.8597C59.7346 68.7225 60.5197 69.3288 61.3825 69.22C62.2453 69.1112 62.8517 68.3261 62.7428 67.4633C62.634 66.6004 61.8489 65.9941 60.9861 66.103C60.131 66.2118 59.517 66.9969 59.6258 67.8597Z'
      fill='#FF7769'
    />
    <path
      d='M52.1629 68.668C52.1707 68.497 52.1862 67.3232 51.2457 66.4837C50.5072 65.823 49.66 65.7764 49.4268 65.7764'
      stroke='#262626'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M51.3377 66.7171C51.2833 66.7094 50.6537 66.6239 50.2184 67.098C49.8608 67.4867 49.8919 67.9686 49.8997 68.0541'
      stroke='#262626'
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M51.2601 63.7789C51.2601 63.7789 52.0374 63.8489 52.6204 64.4241C53.2034 64.9993 53.4133 65.9943 53.4133 65.9943C54.9058 65.8155 58.3182 65.1859 61.4119 62.4964C63.0909 61.0428 64.117 59.4648 64.7155 58.3299C65.2829 58.8352 66.2468 59.597 67.6071 60.1877C69.3872 60.9573 71.6025 61.3226 71.8979 60.7241C71.9523 60.6153 71.8902 60.2965 71.7736 59.6514C71.7269 59.4182 71.6803 59.0762 71.5715 58.7497C71.3538 58.1045 71.0662 57.4904 70.7242 56.8997C70.0401 55.7259 69.1307 54.6843 68.058 53.8448C67.3584 53.3007 66.5966 52.8498 65.7882 52.5001C63.2075 51.404 59.6241 51.404 56.5381 53.0442C56.5381 53.0442 53.0946 54.8786 51.5788 58.4776C51.058 59.7136 50.9259 60.9495 50.8948 61.377C50.8326 62.2243 50.9259 62.7762 51.0503 63.2037C51.1202 63.4525 51.2057 63.6468 51.2601 63.7789Z'
      fill='#262626'
    />
    <path
      d='M71.4393 65.3721C71.7347 65.3332 72.0223 65.3876 72.2788 65.5042C72.2477 66.3437 72.17 67.3698 71.9835 68.458C71.9368 68.4658 71.8824 68.4891 71.8358 68.4891C70.9729 68.5979 70.1879 67.9838 70.079 67.1288C69.9702 66.2737 70.5765 65.4809 71.4393 65.3721Z'
      fill='#FF7769'
    />
    <path
      d='M59.912 52.3132C59.399 52.1111 58.6528 51.839 57.7278 51.5747C55.7067 50.9917 54.6962 50.7041 53.7712 50.8207C51.0739 51.1705 49.5271 54.7151 48.8819 56.2075C48.3922 57.3346 47.5838 59.2469 48.0113 61.641C48.1978 62.7059 48.5787 63.5454 48.8741 64.0895'
      stroke='#262626'
      strokeWidth={0.25}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M59.9111 51.9946C60.8206 51.5982 61.8 51.2717 62.6706 50.8209C63.5956 50.339 64.3963 49.7171 65.3835 49.3518C66.4173 48.9631 67.5755 48.8076 68.6326 49.1341C69.5421 49.4139 70.3272 50.0203 70.9646 50.7198C71.8274 51.6682 72.4881 52.8108 72.8379 54.0468C73.4986 56.4331 72.9079 58.9128 72.5192 61.2914'
      stroke='#262626'
      strokeWidth={0.25}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M67.9409 70.6348C67.9409 71.4121 67.1869 72.0339 66.2542 72.0339C65.3214 72.0339 64.5674 71.4043 64.5674 70.6348C64.5674 69.8652 65.3214 69.2356 66.2542 69.2356C67.1869 69.2356 67.9409 69.8652 67.9409 70.6348Z'
      fill='white'
    />
    <path
      d='M42.9434 58.8662L46.0526 56.9695C46.0526 56.9695 46.69 54.2334 47.5529 53.6504C48.4157 53.0674 53.0096 51.2874 53.0096 51.2874L53.6781 51.9092C53.6781 51.9092 50.0869 55.1895 49.3407 56.6431C48.5945 58.0967 48.4545 62.1543 48.4545 62.1543L44.9411 66.6938L44.0782 67.3467L42.9434 58.8662Z'
      fill='#F7A99C'
    />
    <path
      d='M34.3775 100.048L32.7451 121.059'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M81.8809 101.766L83.1323 124.946'
      stroke='#262626'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0784 68.668C9.42542 69.1888 6.06741 71.9638 5.52329 76.8764C4.93252 82.2244 8.15062 85.8933 8.65588 86.4452'
      stroke='white'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M89.9336 116.628C90.7964 117.592 94.1622 121.09 99.8055 121.852C105.161 122.575 109.157 120.375 110.307 119.683'
      stroke='white'
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M236.783 135.035L243.989 131.179L254.685 133.138L260.033 127.433L272.688 130.822L279.73 121.198L292.299 128.902L288.024 101.727L271.436 90.1135L233.309 96.1144C231.878 96.3398 230.907 97.6768 231.132 99.1071L236.783 135.035Z'
      fill='#F5F5F5'
    />
    <path
      d='M275.308 103.732L288.024 101.734L271.437 90.1211L273.356 102.317C273.504 103.242 274.375 103.88 275.308 103.732Z'
      fill='#D6D6D6'
    />
    <path
      d='M271.437 90.1136L273.356 102.31C273.504 103.243 274.383 103.88 275.315 103.732L288.032 101.735'
      stroke='#262626'
      strokeWidth={0.274}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M241.922 170.721C242.148 172.152 243.485 173.123 244.915 172.898L294.865 165.039C296.296 164.814 297.267 163.477 297.042 162.047L292.3 131.941L279.731 124.238L272.688 133.861L260.034 130.472L254.686 136.177L243.99 134.219L236.784 138.074L241.922 170.721Z'
      fill='#F5F5F5'
    />
    <path
      d='M236.791 135.035L243.997 131.179L254.693 133.138L260.041 127.433L272.695 130.822L279.738 121.199L292.307 128.902L288.032 101.727L271.444 90.1136L233.316 96.1145C231.886 96.34 230.914 97.6769 231.14 99.1072L236.791 135.035Z'
      stroke='#262626'
      strokeWidth={0.274}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M241.93 170.721C242.155 172.152 243.492 173.123 244.923 172.898L294.873 165.039C296.303 164.814 297.275 163.477 297.05 162.047L292.308 131.941L279.739 124.238L272.696 133.861L260.042 130.472L254.694 136.177L243.998 134.219L236.792 138.074L241.93 170.721Z'
      stroke='#262626'
      strokeWidth={0.274}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M43.5573 50.5097L42.2981 51.7068L31.1436 41.6405L34.3539 38.5779L43.5573 50.5097Z'
      fill='#FF7129'
    />
    <path
      d='M46.3177 48.4421L44.5998 48.7608L40.7754 34.2327L45.1361 33.4243L46.3177 48.4421Z'
      fill='#FF7129'
    />
    <path
      d='M40.3725 53.2456L40.2326 54.9868L25.207 54.8702L25.5646 50.4473L40.3725 53.2456Z'
      fill='#FF7129'
    />
    <path
      d='M219.893 273.569C216.123 269.861 216.069 263.805 219.777 260.035L263.843 215.192L277.492 228.609L233.426 273.452C229.726 277.222 223.663 277.276 219.893 273.569Z'
      fill='black'
    />
    <path
      opacity={0.2}
      d='M298.514 232.364C319.889 232.315 337.177 214.948 337.129 193.573C337.08 172.199 319.713 154.91 298.338 154.958C276.963 155.007 259.675 172.374 259.724 193.749C259.772 215.124 277.139 232.412 298.514 232.364Z'
      fill='#DCF0FF'
    />
    <path
      opacity={0.5}
      d='M262.211 207.302C263.517 210.777 265.336 214.096 267.668 217.143L337.121 193.124C337.067 189.37 336.468 185.623 335.333 182.008L262.211 207.302Z'
      fill='white'
    />
    <path
      opacity={0.5}
      d='M259.816 196.218C259.964 198.41 260.291 200.594 260.811 202.747L333.623 177.562C332.713 175.58 331.633 173.652 330.374 171.81L259.816 196.218Z'
      fill='white'
    />
    <path
      d='M329.736 161.798C312.145 144.511 283.858 144.752 266.571 162.35C249.283 179.941 249.524 208.227 267.123 225.515C284.713 242.803 313 242.562 330.287 224.963C347.575 207.372 347.326 179.094 329.736 161.798ZM272.642 219.895C258.152 205.655 257.95 182.366 272.191 167.877C286.431 153.388 309.72 153.186 324.209 167.426C338.698 181.666 338.9 204.955 324.66 219.444C310.419 233.933 287.131 234.135 272.642 219.895Z'
      fill='black'
    />
    <path
      d='M266.936 232.736L232.089 268.19'
      stroke='white'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M301.628 152.198C290.653 151.095 279.265 154.88 271.142 162.35C263.019 169.82 258.285 180.842 258.464 191.873'
      stroke='white'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default NotFound;
