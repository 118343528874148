import { useGlobalData } from '@/context/global';
import { useState } from 'react';

import Link from 'next/link';
import Arrow from '@/components/icons/Arrow';
import AddIcon from '@/components/icons/AddIcon';
import MinusIcon from '@/components/icons/MinusIcon';

const Categories = ({ setSide, locale }) => {
  const {
    appData: { menuTreeData },
  } = useGlobalData();
  const [active, setActive] = useState(menuTreeData.map(() => false));

  return (
    <div className='border-b p-3'>
      {menuTreeData.map((tree, i) => (
        <div key={`category-menu-tree-data-${i}`} className='mb-3 last:mb-0'>
          <div className='flex items-center justify-between'>
            <Link
              href={`/${tree?.url_key || '/'}`}
              className={`text-[13px] uppercase ${active[i] ? 'font-bold' : 'font-normal'}`}
              onClick={() => setSide(false)}
            >
              {tree?.name || ''}
            </Link>
            {tree?.children_data?.length > 0 ? (
              <span
                onClick={() =>
                  setActive((prev) => [
                    ...prev.slice(0, i),
                    !prev[i],
                    ...prev.slice(i + 1, prev.length),
                  ])
                }
              >
                {!active[i] ? (
                  <AddIcon className='size-3' />
                ) : (
                  <MinusIcon className='size-3' />
                )}
              </span>
            ) : null}
          </div>
          {active[i] && (
            <div className='py-1 ps-2'>
              {tree?.children_data.map((sub, j) => (
                <Link
                  href={`/${sub.url_key || '/'}`}
                  key={`tree-children-data-${j}`}
                  onClick={() => setSide(false)}
                  className='mb-2 flex items-center justify-between text-[#5A5757] first:mt-2 last:mb-0'
                >
                  <span className='text-xs uppercase'>{sub?.name || ''}</span>
                  <Arrow
                    className={`size-2.5 ${locale === 'en' ? '-rotate-90' : 'rotate-90'}`}
                  />
                </Link>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Categories;
