// GET ORDER BY ID
import axios from '@/utils/axios-config';

const getOrderByID = async (handshake, orderId, mage) => {
  let config = {
    method: 'GET',
    url: `/customer/orders/${orderId}`,
    headers: {
      'Authorization': `Bearer ${handshake}`,
      'mage': `Bearer ${mage}`,
      'admin': 'Bearer ' + process.env.NEXT_PUBLIC_ADMIN_TOKEN,
    },
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { getOrderByID };
