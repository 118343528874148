import CheckComIcon from '../icons/CheckComIcon';

const Functions = ({ selected, setSelected, locale }) => {
  return (
    <li
      onClick={() => setSelected(0)}
      className={`xs:justify-start flex items-center justify-center gap-2 border p-1.5 ${
        selected === 0 && 'border-black'
      } cursor-pointer rounded-md`}
    >
      <span className='flex flex-col items-center justify-center'>
        <span className='flex items-center'>
          <hr className='xs:w-14 w-10 border border-green-600' />
          <span className='grid size-5 place-items-center rounded-full border border-green-600'>
            <CheckComIcon className='size-2 text-green-600' />
          </span>
        </span>
      </span>
      <span className='flex items-center justify-center gap-1'>
        {/* <TbCategory /> */}
        <span>{locale === 'en' ? 'Functions' : 'الدوال'}</span>
      </span>
    </li>
  );
};

export default Functions;
