import SuccessChangePassword from '../icons/SuccessChangePassword';

const SuccessChangePass = ({ t, setAuth }) => {
  return (
    <div className='mb-2 mt-3'>
      <SuccessChangePassword className='mx-auto h-40' />
      <p className='my-2 text-center text-sm font-bold'>
        {t('The password has been changed successfully')}
      </p>
      <button
        onClick={() => setAuth('Login')}
        className='w-full rounded-sm bg-black p-1.5 text-sm font-bold text-white'
      >
        {t('Back To Login')}
      </button>
    </div>
  );
};

export default SuccessChangePass;
