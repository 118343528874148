import { useContext, useEffect, useState } from 'react';
import CompareIcon from '../icons/CompareIcon';
import CompareContext from '@/context/compare/compare-context';
import toast from 'react-hot-toast';
import { useRouter } from 'next/router';

const AddToCompare = ({ t, product, iconClassName, fromPDP }) => {
  const { locale } = useRouter();
  const compareCtx = useContext(CompareContext);
  const [inCompare, setInCompare] = useState(false);

  useEffect(() => {
    const existingProduct = compareCtx.compareProducts.find(
      (compareProduct) => compareProduct.sku === product.sku
    );

    setInCompare(!!existingProduct);
  }, [product?.sku, compareCtx?.compareProducts]);

  const handleAddToCompare = () => {
    //alert(compareCtx.compareProducts.length);
    if (compareCtx.compareProducts.length >= 4 && !inCompare) {
      return toast.error(
        locale === 'en'
          ? 'Maximum 4 products can be compared, please remove one or more from compare to add another.'
          : 'يمكن مقارنة 4 منتجات كحد أقصى، يرجى إزالة منتج أو أكثر من المقارنة لإضافة منتج آخر.'
      );
    }
    compareCtx.addProductToCompare(product);
  };

  return (
    <button onClick={handleAddToCompare} className='flex items-end gap-2'>
      <CompareIcon className={iconClassName} />
      {fromPDP && (
        <span className='mt-1.5 block font-semibold uppercase'>
          {t('Add To Compare')}
        </span>
      )}
    </button>
  );
};

export default AddToCompare;
