const TrackIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1429 6.44271H9.04442C8.79285 6.44271 8.58423 6.23409 8.58423 5.98251V1.69351C8.58423 1.23945 8.76217 0.816077 9.08124 0.49701C9.4003 0.177942 9.82368 0 10.2777 0H10.2839C11.0509 0.00613591 11.7749 0.306795 12.3271 0.852891C12.8794 1.41126 13.18 2.14757 13.18 2.91456V4.39945C13.1862 5.62049 12.3639 6.44271 11.1429 6.44271ZM9.50462 5.52232H11.1429C11.8547 5.52232 12.2658 5.11121 12.2658 4.39945V2.91456C12.2658 2.38687 12.0572 1.88373 11.6829 1.5033C11.3086 1.13514 10.8054 0.926523 10.2839 0.920387C10.2839 0.920387 10.2839 0.920387 10.2777 0.920387C10.0753 0.920387 9.87891 1.00015 9.73165 1.14741C9.58438 1.29468 9.50462 1.48489 9.50462 1.69351V5.52232Z"
      fill="currentColor"
    />
    <path
      d="M4.74919 13.5481C4.46081 13.5481 4.19083 13.4376 3.98834 13.229L2.96978 12.2043C2.91456 12.1491 2.82865 12.143 2.76729 12.1921L1.71805 12.9775C1.39285 13.2229 0.963339 13.2658 0.595184 13.0818C0.227029 12.8977 0 12.5295 0 12.1184V2.91456C0 1.06151 1.06151 0 2.91456 0H10.2777C10.5292 0 10.7378 0.208621 10.7378 0.460193C10.7378 0.711766 10.5292 0.920387 10.2777 0.920387C9.85427 0.920387 9.51066 1.264 9.51066 1.68738V12.1184C9.51066 12.5295 9.28363 12.8977 8.91548 13.0818C8.54732 13.2658 8.11781 13.229 7.79261 12.9836L6.74337 12.1982C6.68201 12.1491 6.5961 12.1614 6.54702 12.2105L5.51618 13.2413C5.30756 13.4376 5.03758 13.5481 4.74919 13.5481ZM2.8532 11.241C3.13545 11.241 3.41157 11.3453 3.62019 11.5601L4.63875 12.5848C4.67556 12.6216 4.72465 12.6277 4.74919 12.6277C4.74306 12.6154 4.82283 12.6216 4.85964 12.5848L5.89047 11.5539C6.2709 11.1735 6.87222 11.1367 7.2956 11.4619L8.3387 12.2411C8.4062 12.2902 8.46756 12.2718 8.49824 12.2534C8.52892 12.235 8.58414 12.1982 8.58414 12.1184V1.68738C8.58414 1.41126 8.65164 1.14742 8.76822 0.920387H2.90842C1.54625 0.920387 0.91425 1.55239 0.91425 2.91456V12.1184C0.91425 12.2043 0.969472 12.2411 1.00015 12.2596C1.03697 12.278 1.09833 12.2902 1.15969 12.2411L2.20893 11.4557C2.39914 11.3146 2.62617 11.241 2.8532 11.241Z"
      fill="currentColor"
    />
    <path
      d="M6.43675 5.82957H3.062C2.81043 5.82957 2.60181 5.62095 2.60181 5.36937C2.60181 5.1178 2.81043 4.90918 3.062 4.90918H6.43675C6.68832 4.90918 6.89694 5.1178 6.89694 5.36937C6.89694 5.62095 6.68832 5.82957 6.43675 5.82957Z"
      fill="currentColor"
    />
  </svg>
);
export default TrackIcon;
