import FilterIcon from '../icons/FilterIcon';
import Arrow from '../icons/Arrow';
import { useState } from 'react';

const FilterSort = ({ sortKeys, handleSorting, setFilter, t }) => {
  const [sortName, setSortName] = useState('Most Views');
  return (
    <div className='my-4 hidden bg-zinc-200 py-2 max-md:order-1 max-md:block'>
      <div className='mx-auto flex w-11/12 items-center justify-between'>
        <div
          onClick={() => setFilter(true)}
          className='flex cursor-pointer items-center gap-2'
        >
          <FilterIcon />
          <span className='text-[15px]'>{t('Filters')}</span>
        </div>
        <div className='group relative flex w-40 items-center justify-between rounded-sm bg-white p-1.5 text-sm'>
          <span className='cursor-pointer font-semibold'>{t(sortName)}</span>
          <Arrow />
          <ul className='absolute left-0 top-full z-50 hidden w-full min-w-52 rounded-md border bg-white p-2 shadow-lg *:cursor-pointer *:text-[15px] *:text-black group-hover:block'>
            {sortKeys?.map((item, key) => (
              <li
                key={`sort-key-${key}`}
                onClick={() => {
                  handleSorting({ keys: item?.key });
                  setSortName(item?.label);
                }}
                className={`hover:text-secondary-700 ${
                  sortKeys?.length - 1 == key ? 'mb-0' : 'mb-1.5'
                } `}
              >
                {t(item?.label)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterSort;
