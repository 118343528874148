import AddtoWishlist from '../global/buttons/AddtoWishlist';
import ShareIcon from '../icons/ShareIcon';
import Image from '../Image';
import Share from '../share';
import AddToCompare from './AddToCompare';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import ImgTag from '../global/ImgTag';
import { labelPositions } from '@/constants/Pdp';
import { compareObjects, extractOptionIds } from '@/helpers/product';
// import { useContainerDimensions } from '@/hooks/global/useContainerDimension';

const Album = ({ t, product }) => {
  const { locale } = useRouter();
  const [images, setImages] = useState([]);
  const [currentImg, setCurrentImg] = useState(null);
  const isConfigurable = product?.type_id === 'configurable';
  const productVariant = !isConfigurable
    ? null
    : product?._configurables?.find((variant) =>
        compareObjects(
          variant?.options,
          extractOptionIds(product?.selectedConfigurations)
        )
      );
  useEffect(() =>{
    const sortedImages = (
      isConfigurable ? productVariant?._media_ : product?._media_
    )?.image
      ?.toSorted((a, b) => +a.position - b.position)
      ?.map((img, i) => ({
        ...img,
        id: i + 1,
      }));
    setImages(sortedImages);
    setCurrentImg(sortedImages?.at(0)?.image);
  }, [product]);
  console.log('currentImg', currentImg);
  return (
    <div className='col-span-full md:col-span-6'>
      <div className='relative'>
        <button className='flex w-full items-center justify-center'>
          <Image
            loading='eager'
            src={currentImg || images?.at(0)?.image}
            className={`aspect-square w-full border-2 p-4`}
            alt='Main Img!'
            fetchpriority='high'
          />
        </button>

        <div className='absolute start-2 top-2 z-10 flex !h-full !w-full flex-col gap-4 pb-6'>
          <div className='flex h-full gap-2'>
            <div className='absolute right-2 top-2 z-50'>
              <Share
                label={<ShareIcon />}
                title={product?.name || ''}
                description={product?.meta_description || ''}
              />
            </div>
            {product?.label?.map(
              (label) =>
                label &&
                label?.status === '1' && (
                  <a
                    key={`album-product-label-${label}`}
                    className='absolute z-40 !h-[calc(100%-15px)] !w-[calc(100%-20px)] cursor-pointer'
                    {...(label?.frontend_settings?.redirect_url && {
                      href: label?.frontend_settings?.redirect_url,
                    })}
                  >
                    <ImgTag
                      src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/amasty/amlabel/${label?.frontend_settings?.image}`}
                      className='absolute'
                      style={{
                        ...labelPositions(
                          label?.frontend_settings?.position,
                          label?.frontend_settings?.image_size
                        ),
                        width: `${product?.label?.at(0)?.frontend_settings?.image_size}%`,
                      }}
                      alt='label'
                    />
                  </a>
                )
            )}
          </div>
          <AddToCompare
            product={product}
            t={t}
            iconClassName='sm:hidden size-5'
          />
          <div className='sm:hidden'>
            <AddtoWishlist product={product} />
          </div>
        </div>
      </div>
      <div className='mt-3 max-sm:mt-2'>
        <Swiper
          loop={images?.filter((img) => img?.id !== currentImg?.id).length > 4}
          key={locale}
          dir={locale.includes('ar') ? 'rtl' : 'ltr'}
          autoplay={{ delay: 3500, disableOnInteraction: false }}
          slidesPerView={4}
          spaceBetween={8}
          className='px-0.5'
        >
          {images?.map((item) => (
            <SwiperSlide key={`album-image-${item?.id}`}>
              <button
                className='aspect-square size-full '
                key={item?.id}
                onClick={() => {
                  setCurrentImg(item?.image);
                }}
              >
                <Image
                  loading='eager'
                  src={item?.image}
                  className={`aspect-square border-2 ${currentImg?.id === item?.id && 'border-rose-600'} object-contain`}
                  alt='Main Img!'
                />
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* {!fromQuickView && (
        <div className='hidden mt-3 md:block'>
          <ValidityBranches t={t} setBranches={setBranches} />
        </div>
      )} */}
    </div>
  );
};

export default Album;
