import * as React from 'react';
const ReturnIcon = (props) => (
  <svg viewBox='0 0 30 29.99936' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g data-name='Layer 2' id='Layer_2'>
      <g id='Interface-Solid'>
        <g id='interface-solid-multimedia-refresh-button-2'>
          <path
            fill='currentColor'
            d='M28.75,17.77671a1.24991,1.24991,0,0,0-1.25,1.25,2.73109,2.73109,0,0,1-2.67578,2.77734H9.122l3.48254-3.57227a1.25,1.25,0,0,0-1.78906-1.74609L5.2627,22.181a1.25046,1.25046,0,0,0,0,1.7461l5.55273,5.69531a1.25,1.25,0,1,0,1.78906-1.7461L9.122,24.30405H24.8418c.00653,0,.0119-.00366.01837-.00366A5.23207,5.23207,0,0,0,30,19.02671,1.24991,1.24991,0,0,0,28.75,17.77671Z'
          />
          <path
            fill='currentColor'
            d='M20.87805,8.19467l-3.48254,3.57227A1.25,1.25,0,1,0,19.18457,13.513L24.7373,7.81772a1.25045,1.25045,0,0,0,0-1.74609L19.18457.37631a1.25,1.25,0,1,0-1.78906,1.7461l3.48254,3.57226H5.1582c-.00653,0-.0119.00367-.01837.00367A5.23207,5.23207,0,0,0,0,10.972a1.25,1.25,0,0,0,2.5,0A2.7311,2.7311,0,0,1,5.17578,8.19467Z'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default ReturnIcon;
