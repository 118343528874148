import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import toast from 'react-hot-toast';
import Eye from '@/components/icons/Eye';
import Eyelock from '@/components/icons/Eyelock';
import { changePasswordValidation } from '@/utils/valitation/auth';
import { deleteCookie, getCookie } from 'cookies-next';
import { resetNewPassword, resetNewPasswordByMobile } from '@/services/auth';
import { useRouter } from 'next/router';

const ChangePassword = ({ t, setAuth, isFromLogin, setIsFromLogin }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { locale } = useRouter();
  const handshake = getCookie('handshake');

  const handleChangePassword = async (values, { resetForm }) => {
    setLoading(true);
    if (isFromLogin == 'Change Password By Email') {
      const resetPassword = getCookie('resetPassword')
        ? JSON.parse(getCookie('resetPassword'))
        : null;

      const otp = getCookie('otp');

      const changedPassword = await resetNewPassword({
        handshake,
        email: resetPassword?.email,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
        resetToken: otp,
      });

      if (changedPassword?.status == 200) {
        toast.success(t('PasswordUpdatedSuccessfully'));
        resetForm();
        setAuth('Success Change Password');
        deleteCookie('resetPassword');
        deleteCookie('otp');
        deleteCookie('email');
      } else {
        setAuth('Otp');
        toast.error(changedPassword?.error?.error?.message);
      }
    } else {
      const mobilenumber = getCookie('mobilenumber');

      const changedPassword = await resetNewPasswordByMobile({
        handshake,
        mobilenumber: `${locale.toLowerCase()?.includes('ae') ? '971' : '966'}${mobilenumber?.slice(-9)}`,
        password: values.password,
      });
      console.log('changedPassword///////////', changedPassword);
      if (changedPassword?.data?.data?.succeess == 'true') {
        toast.success(t('PasswordUpdatedSuccessfully'));
        resetForm();
        setIsFromLogin(false);
        setAuth('Success Change Password');
        deleteCookie('resetPassword');
        deleteCookie('otp');
        deleteCookie('email');
        deleteCookie('mobilenumber');
      } else {
        toast.error(changedPassword?.error?.error?.message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={handleChangePassword}
        validationSchema={changePasswordValidation(t)}
      >
        <Form className='mt-2.5 *:mb-2.5'>
          <div>
            <div className='relative *:mb-1'>
              <Field
                name='password'
                type={!show ? 'password' : 'text'}
                className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm'
                placeholder={t('Password')}
              />
              {show ? (
                <Eye
                  onClick={() => setShow(!show)}
                  className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 text-zinc-500'
                />
              ) : (
                <Eyelock
                  onClick={() => setShow(!show)}
                  className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 text-zinc-500'
                />
              )}
            </div>
            <ErrorMessage
              name='password'
              component='p'
              className='text-sm text-red-600'
            />
          </div>
          <div>
            <div className='relative *:mb-1'>
              <Field
                name='confirmPassword'
                type={!show ? 'password' : 'text'}
                className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm'
                placeholder={t('Confirm Password')}
              />
              {show ? (
                <Eye
                  onClick={() => setShow(!show)}
                  className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 text-zinc-500'
                />
              ) : (
                <Eyelock
                  onClick={() => setShow(!show)}
                  className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 text-zinc-500'
                />
              )}
            </div>
            <ErrorMessage
              name='confirmPassword'
              component='p'
              className='text-sm text-red-600'
            />
          </div>
          <button
            type='submit'
            disabled={loading}
            className='flex h-8 w-full items-center justify-center rounded-sm bg-black p-1.5 text-sm font-bold text-white disabled:cursor-not-allowed'
          >
            {loading ? (
              <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
            ) : (
              t('Send')
            )}
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default ChangePassword;
