import { useBodyScrollLock } from '@/hooks/useBodyScrollLock';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import Categories from './Categories';
import TrackBranch from './TrackBranch';
import Arrow from '@/components/icons/Arrow';
import CallWhatsEmail from './CallWhatsEmail';
import LangIcon from '@/components/icons/LangIcon';
import UserIcon from '@/components/icons/UserIcon';
import ShippingReturnFAQ from './ShippingReturnFAQ';
import NeedHelpIcon from '@/components/icons/NeedHelpIcon';
import LocaleSwitcher from '@/components/global/buttons/LocaleSwitcher';
const SideMenu = ({ setSide, t, setAuth }) => {
  useBodyScrollLock();
  const { locale, push } = useRouter();
  const { data: session } = useSession();
  const onHide = () => {
    setSide(false);
  };

  return (
    <div className='fixed start-0 top-0 z-[500] flex min-h-screen w-screen items-center'>
      <div className='max-h-screen min-h-screen w-11/12 overflow-auto bg-white sm:w-96'>
        <div
          onClick={() => {
            if (session?.user) {
              push('/user');
            } else {
              setAuth('Login');
            }
            setSide(false);
          }}
          className='flex items-center justify-between border-b p-3'
        >
          <div className='flex items-center gap-2'>
            <UserIcon className='size-5' />
            {session?.user ? (
              <div className='flex flex-col'>
                <span className='text-xs'>{t('Welcome')}</span>
                <span className='text-sm font-bold'>
                  {session?.user?.firstname || 'unknown'}{' '}
                  {session?.user?.lastname || ''}
                </span>
              </div>
            ) : (
              <span className='text-sm font-semibold'>
                {t('Login / Register')}
              </span>
            )}
          </div>
          <Arrow
            className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
          />
        </div>
        <Categories locale={locale} setSide={setSide} />
        <TrackBranch setAuth={setAuth} onHide={onHide} locale={locale} t={t} />
        <div className='border-b p-3'>
          <div className='mb-4 flex cursor-pointer items-center justify-between'>
            <div className='flex items-center gap-2'>
              <LangIcon className='size-4' />

              <LocaleSwitcher hideIcon callback={onHide} />
            </div>
            <Arrow
              className={`${locale.includes('ar') ? 'rotate-90' : '-rotate-90'} size-3`}
            />
          </div>
          <div className='mb-4'>
            <div className='mb-2 flex items-center gap-2'>
              <NeedHelpIcon className='size-4' />
              <span className='text-sm'>{t('Need Help')}</span>
            </div>
            <p className='hidden ps-6 text-xs text-[#898989]'>
              Ipsum has been the {"industry's"} standard dummy text ever since
              the 1500s make a type specimen book. It has survived not only five
              centuries
            </p>
          </div>
          <CallWhatsEmail t={t} />
          <ShippingReturnFAQ t={t} locale={locale} onHide={onHide} />
        </div>
      </div>
      <div
        className='min-h-screen flex-1 bg-black bg-opacity-60'
        onClick={() => setSide(false)}
      ></div>
    </div>
  );
};

export default SideMenu;
