export const sortKeys = [
  {
    key: 'sortBy=special_price&sortDir=DESC',
    label: 'Discount From High To Low',
  },
  {
    key: 'sortBy=special_price&sortDir=ASC',
    label: 'Discount From Low To High',
  },
  {
    key: 'sortBy=position&sortDir=ASC',
    label: 'Most Views',
  },
  {
    key: 'sortBy=price&sortDir=ASC',
    label: 'Price From Low To High',
  },
  {
    key: 'sortBy=price&sortDir=DESC',
    label: 'Price From High To Low',
  },

  // {
  // 	key: 'sortBy=created_at&sortDir=DESC',
  // 	label: 'latest',
  // },
];
