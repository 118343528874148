const Sizes = ({ t }) => {
  return (
    <div className='flex flex-col gap-3'>
      <p className='font-bold whitespace-nowrap md:text-lg'>{t('Size')} : </p>
      <ul className='flex flex-wrap items-center flex-1 gap-2'>
        <li className='grid text-sm border rounded-sm size-10 place-items-center md:text-base'>
          S
        </li>
        <li className='grid text-sm border rounded-sm size-10 place-items-center md:text-base'>
          M
        </li>
        <li className='grid text-sm border rounded-sm size-10 place-items-center md:text-base'>
          L
        </li>
        <li className='grid text-sm border rounded-sm size-10 place-items-center md:text-base'>
          XL
        </li>
      </ul>
    </div>
  );
};

export default Sizes;
