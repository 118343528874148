import axiosRequest from '@/utils/axios-config';
import axios from 'axios';

export const getFetcher = async (url) => {
  const req = await axios.get(url);
  return req.data;
};

export const postFetcher = async (url, { arg }) => {
  const updatedArgs = {};

  for (let updatedArg of Object.keys(arg)) {
    updatedArgs[updatedArg] = arg[updatedArg];
  }

  try {
    const res = await axios.post(url, updatedArgs);
    return res?.data || null;
  } catch (err) {
    return err;
  }
};

export const cusPostFetcher = async (url, { arg }) => {
  const updatedArgs = {};

  for (let updatedArg of Object.keys(arg)) {
    url += `&${arg[updatedArg]}`;
  }

  console.log('url', url);
  const { data } = await axiosRequest.post(url, updatedArgs);
  return data;
};

export const activeCart = async (handshake, order_id) => {
  let config = {
    method: 'post',
    url: '/cart/active',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: JSON.stringify({ order_id, device: 'web' }),
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
