import { registerValidation } from '@/utils/valitation/auth';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useCallback, useContext, useState } from 'react';

import toast from 'react-hot-toast';
import {
  register,
  registerByMobile,
  registerVerifyOtpByMobile,
} from '@/services/auth';
import { getCookie, setCookie } from 'cookies-next';
import { event_otp_verification, event_sign_up } from '@/utils/gtm';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import MobileOtpRegister from './MobileOtpRegister';
import Image from '@/components/global/image';
import { decodeJwt } from '@/utils/global';
import AWSPersonalizeCtx from '@/context/aws-personalize/aws-personalize-context';

const Register = ({ t, setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [showSubmitOtp, setShowSubmitOtp] = useState(false); // put it true when register with phone to show otp form
  const [formData, setFormData] = useState({}); // local state to save the registration form data to save the data in case using the phone registration

  const { locale, push, asPath } = useRouter();

  const [mail, setMail] = useState(false);
  const handshake = getCookie('handshake');
  const { session } = useSession();
  const { firstActionAfterLoginHandler } = useContext(AWSPersonalizeCtx);

  const intialValuesRegister = {
    firstname: '',
    lastname: '',
    email: '',
    terms: '',
    mobile: '',
  };

  const pushToCheckoutHandler = useCallback(() => {
    asPath === '/cart' ? push('/checkout') : undefined;
  }, [asPath, push]);

  const handleRegister = async (values, { resetForm }) => {
    setLoading(true);
    const submittedValues = {
      ...values,
      password: 'zLwtLEmroMUo',
      registerType: !mail ? 'mobile' : 'email',
    };

    const { data, error } = await register(handshake, submittedValues, locale);
    console.log('error', { error, data });
    if (data?.data?.success === 'false' || error) {
      const data = {
        method: !mail ? 'Mobile' : 'Email',
        result: `Signup Failed: ${error?.error?.message}`,
        user_id: null,
      };

      const { method, result, user_id } = data;
      event_sign_up(method, result, user_id);
      // handling the error coming from backend and show to client
      toast.error(
        data?.data?.errormsg || error?.error?.message || t('Resister Failed')
      );
      setShowSubmitOtp(false);
      return setLoading(false);
    } else {
      const dataInfo = {
        method: !mail ? 'Mobile' : 'Email',
        result: `Signup Success`,
        user_id: null,
      };
      const { method, result, user_id } = dataInfo;

      event_sign_up(
        method,
        result,
        user_id,
        submittedValues.email,
        `${locale.toLowerCase()?.includes('ae') ? '966' : '971'}${submittedValues.mobile?.slice(-9)}`,
        submittedValues.firstname,
        submittedValues.lastname
      );

      // check we have token to login
      if (data?.data?.token) {
        const mage = data?.data?.token;
        const userData = data?.data?.user;

        await signIn('credentials', {
          user: JSON.stringify({
            ...userData,
            token: mage,
            register_type: data?.data?.register_type,
          }),
          token: mage,
          callbackUrl: '/',
          redirect: false,
        });

        firstActionAfterLoginHandler();
        const decodeMage = decodeJwt(mage);
        setCookie('mage', mage, {
          expires: new Date(decodeMage?.exp * 1000),
        });
        setCookie('email', null);
        setCookie('mobilenumber', null);
        pushToCheckoutHandler();
      }

      toast.success(t('Resister success'));
      resetForm();
      setFormData({});
      setAuth(null);
      return setLoading(false);
    }
  };

  const handleRegisterByMobile = async (values, { resetForm }) => {
    setLoading(true);

    const { data, error } = await registerByMobile(
      handshake,
      values?.mobile,
      values?.email,
      locale
    );

    if (data?.data?.success === 'false' || error) {
      const info = {
        method: 'Mobile',
        result: `Signup Failed: ${locale == 'en' ? 'This mobile number is already registered.' : 'تم تسجيل رقم الهاتف المحمول هذا بالفعل.'}`,
        user_id: '',
      };
      const { method, result, user_id } = info;

      event_sign_up(method, result, user_id);
      // handling the error coming from backend and show to client
      if (
        error?.error?.startsWith(
          'There is already an account registered with this email'
        )
      ) {
        toast.error(
          locale == 'en'
            ? 'This email address is already registered.'
            : 'تم التسجيل بهذا البريد من قبل'
          // data?.data?.errormsg || error?.error || t('Resister Failed')
        );
      } else console.log('error____', error.error, error);
      toast.error(
        (error.error || '')?.trim() === 'Message Send Failed'
          ? locale === 'ar'
            ? 'فشل إرسال الرسالة'
            : 'Message Send Failed'
          : error.error || locale === 'ar'
            ? 'حدث خطأ ما، يرجى المحاولة مرة أخرى'
            : 'Something went wrong, please try again'
      );
      setLoading(false);
    } else {
      const data = {
        method: !mail ? 'Mobile' : 'Email',
        result: `result: ${t('Regestered successfully')}`,
        user_id: session?.user?.id,
      };
      setFormData(values);

      console.log('data in Register', data);
      const { method, result, user_id } = data;

      event_sign_up(method, result, user_id);
      toast.success(
        `${locale == 'en' ? 'OTP has been sent on your mobile number' : 'تم إرسال رمز التحقق على رقم هاتفك المحمول'}: ${`${locale.toLowerCase()?.includes('ae') ? '00971' : '00966'}${values.mobile?.slice(-9)}`}`
      );
      resetForm();
      setShowSubmitOtp(true);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleOtp = async ({ otp }) => {
    setLoading(true);
    const { mobile, email } = formData;
    const { error } = await registerVerifyOtpByMobile(
      handshake,
      mobile,
      email,
      otp,
      locale
    );

    if (!error || error == 'null' || error == null) {
      const result = `Verification Success`;

      event_otp_verification(result);
      await handleRegister(formData, { resetForm: () => {} });
    } else {
      toast.error(locale == 'en' ? 'Invalid OTP' : 'رمز التحقق غير صالح');
    }
    setLoading(false);
  };

  return (
    <>
      {!showSubmitOtp ? (
        <>
          <div className='my-4 text-center text-sm sm:text-lg'>
            <span className='font-bold'>{t('If you have an account,')} </span>
            <span className='text-zinc-500'>
              {t('Log in using your email.')}
            </span>
          </div>

          {false && (
            <div className='mb-4 mt-2.5 grid grid-cols-2 text-center text-sm sm:text-lg'>
              <div
                onClick={() => setMail(true)}
                className='cursor-pointer border-b pb-3 text-sm sm:text-lg'
              >
                <span
                  className={`${mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
                >
                  {t('Login with email')}
                </span>
              </div>
              <div
                onClick={() => setMail(false)}
                className='cursor-pointer border-b pb-3 text-sm sm:text-lg'
              >
                <span
                  className={`${!mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
                >
                  {t('Login with mobile')}
                </span>
              </div>
            </div>
          )}

          <Formik
            onSubmit={mail ? handleRegister : handleRegisterByMobile}
            initialValues={intialValuesRegister}
            validationSchema={registerValidation(t, locale)}
          >
            <Form className='mb-2 mt-2.5 grid grid-cols-2 gap-3'>
              <div className='*:mb-1 max-sm:col-span-full'>
                <Field
                  name='firstname'
                  className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                  placeholder={t('First Name')}
                />
                <ErrorMessage
                  name='firstname'
                  component='p'
                  className='text-sm text-red-600 sm:text-lg'
                />
              </div>
              <div className='*:mb-1 max-sm:col-span-full'>
                <Field
                  name='lastname'
                  className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                  placeholder={t('Last Name')}
                />
                <ErrorMessage
                  name='lastname'
                  component='p'
                  className='text-sm text-red-600 sm:text-lg'
                />
              </div>
              <div className='col-span-full *:mb-1'>
                <Field
                  name='email'
                  type='email'
                  className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                  placeholder={t('Email Address')}
                />
                <ErrorMessage
                  name='email'
                  component='p'
                  className='text-sm text-red-600 sm:text-lg'
                />
              </div>
              <div className={`col-span-full  *:mb-1`}>
                <div
                  className={`flex border ${locale.includes('ar') ? 'flex-row' : 'flex-row-reverse'}`}
                >
                  <Field
                    className='flex-grow rounded-sm  p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                    name='mobile'
                    placeholder='05xxxxxxxx'
                    type='tel'
                    id='mobile'
                  />
                  <span
                    dir='ltr'
                    className='flex w-20 items-center justify-center gap-2 border-s'
                  >
                    <Image
                      loading='lazy'
                      src={
                        locale === 'ar'
                          ? '/imgs/layout/ksa.png'
                          : '/imgs/layout/uae.jfif'
                      }
                      className='w-5 object-contain '
                      alt={locale === 'ar' ? 'KSA' : 'UAE'}
                    />
                    <span>{locale === 'ar' ? '+966' : '+971'}</span>
                  </span>
                </div>
                <ErrorMessage
                  name='mobile'
                  component='p'
                  className='text-sm text-red-600 sm:text-lg'
                />
              </div>

              <div className='col-span-full flex flex-wrap items-center gap-1'>
                <Field
                  name='terms'
                  type='checkbox'
                  id='accept-terms'
                  className='size-4 appearance-none rounded-sm border checked:border-black checked:bg-black'
                />
                <label
                  htmlFor='accept-terms'
                  className='flex gap-1 text-sm text-zinc-600 sm:text-lg'
                >
                  <span>{t('I Accept')}</span>
                  <span className='font-bold underline'>
                    {t('Terms & Conditions')}
                  </span>
                </label>
                <ErrorMessage
                  name='terms'
                  component='p'
                  className='w-full text-sm text-red-600 sm:text-lg'
                />
              </div>
              <button
                type='submit'
                disabled={loading}
                className='col-span-full flex w-full items-center justify-center gap-2 rounded-sm bg-black p-1.5 py-2 text-sm font-bold text-white disabled:cursor-not-allowed disabled:opacity-75 max-sm:text-sm sm:text-lg'
              >
                {loading && (
                  <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
                )}

                <span>{t('Register')}</span>
              </button>
            </Form>
          </Formik>
        </>
      ) : (
        <MobileOtpRegister handleOtp={handleOtp} t={t} loading={loading} />
      )}
    </>
  );
};

export default Register;
