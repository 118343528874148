const LangIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66683 15.1663H6.00016C2.38016 15.1663 0.833496 13.6197 0.833496 9.99967V5.99967C0.833496 2.37967 2.38016 0.833008 6.00016 0.833008H10.0002C13.6202 0.833008 15.1668 2.37967 15.1668 5.99967V7.33301C15.1668 7.60634 14.9402 7.83301 14.6668 7.83301C14.3935 7.83301 14.1668 7.60634 14.1668 7.33301V5.99967C14.1668 2.92634 13.0735 1.83301 10.0002 1.83301H6.00016C2.92683 1.83301 1.8335 2.92634 1.8335 5.99967V9.99967C1.8335 13.073 2.92683 14.1663 6.00016 14.1663H6.66683C6.94016 14.1663 7.16683 14.393 7.16683 14.6663C7.16683 14.9397 6.94016 15.1663 6.66683 15.1663Z"
      fill="currentColor"
    />
    <path
      d="M14.6668 6.16699H1.35352C1.08018 6.16699 0.853516 5.94033 0.853516 5.66699C0.853516 5.39366 1.08018 5.16699 1.35352 5.16699H14.6668C14.9402 5.16699 15.1668 5.39366 15.1668 5.66699C15.1668 5.94033 14.9402 6.16699 14.6668 6.16699Z"
      fill="currentColor"
    />
    <path
      d="M8.00016 10.833H1.35352C1.08018 10.833 0.853516 10.6063 0.853516 10.333C0.853516 10.0597 1.08018 9.83301 1.35352 9.83301H8.00016C8.2735 9.83301 8.50016 10.0597 8.50016 10.333C8.50016 10.6063 8.2735 10.833 8.00016 10.833Z"
      fill="currentColor"
    />
    <path
      d="M5.67334 15.16C5.40001 15.16 5.17334 14.9333 5.17334 14.66V1.34668C5.17334 1.07335 5.40001 0.84668 5.67334 0.84668C5.94667 0.84668 6.17334 1.07335 6.17334 1.34668V14.66C6.17334 14.9333 5.95334 15.16 5.67334 15.16Z"
      fill="currentColor"
    />
    <path
      d="M10.3398 8.49333C10.0665 8.49333 9.83984 8.26666 9.83984 7.99333V1.34668C9.83984 1.07335 10.0665 0.84668 10.3398 0.84668C10.6132 0.84668 10.8398 1.07335 10.8398 1.34668V7.99333C10.8398 8.26666 10.6198 8.49333 10.3398 8.49333Z"
      fill="currentColor"
    />
    <path
      d="M9.8399 15.1661C9.5599 15.1661 9.30656 15.0661 9.11989 14.8794C8.89323 14.6594 8.79324 14.3328 8.84658 13.9928L8.9999 12.9328C9.03323 12.6795 9.18656 12.3728 9.37323 12.1862L12.1399 9.41947C12.4999 9.05947 12.8532 8.8728 13.2266 8.8328C13.6866 8.78614 14.1466 8.98614 14.5799 9.41947C15.0132 9.85281 15.2132 10.3062 15.1666 10.7728C15.1332 11.1462 14.9399 11.4995 14.5799 11.8595L11.8132 14.6261C11.6332 14.8061 11.3265 14.9595 11.0665 14.9995L10.0132 15.1528C9.95324 15.1595 9.89324 15.1661 9.8399 15.1661ZM13.3466 9.8328C13.3399 9.8328 13.3266 9.8328 13.3199 9.8328C13.1866 9.84614 13.0266 9.94611 12.8399 10.1328L10.0732 12.8995C10.0399 12.9328 9.99322 13.0328 9.98655 13.0795L9.83323 14.1394L10.9199 14.0195C10.9666 14.0128 11.0666 13.9595 11.0999 13.9328L13.8666 11.1661C14.0532 10.9795 14.1532 10.8195 14.1666 10.6862C14.1799 10.5328 14.0799 10.3528 13.8666 10.1394C13.6666 9.93278 13.4932 9.8328 13.3466 9.8328Z"
      fill="currentColor"
    />
    <path
      d="M13.8269 12.4064C13.7802 12.4064 13.7402 12.3997 13.6935 12.3864C12.6935 12.1064 11.8935 11.3064 11.6135 10.3064C11.5402 10.0397 11.6935 9.76644 11.9602 9.69311C12.2335 9.61978 12.5002 9.77312 12.5735 10.0398C12.7602 10.7065 13.2935 11.2398 13.9602 11.4264C14.2269 11.4998 14.3802 11.7798 14.3069 12.0398C14.2469 12.2665 14.0402 12.4064 13.8269 12.4064Z"
      fill="currentColor"
    />
  </svg>
);
export default LangIcon;
