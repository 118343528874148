const FavProdCard = (props) => (
  <svg
    width={17}
    height={15}
    viewBox='0 0 17 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.2748 4.56301C16.2748 9.63655 8.67139 13.9068 8.67139 13.9068C8.67139 13.9068 1.06738 9.57343 1.06738 4.57329C1.06738 2.53176 2.75709 0.90676 4.86923 0.90676C6.98137 0.90676 8.67108 3.34426 8.67108 3.34426C8.67108 3.34426 10.3608 0.90676 12.4729 0.90676C14.5851 0.90676 16.2748 2.53176 16.2748 4.56301Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default FavProdCard;
