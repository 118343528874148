import { forgetSendOtpForEmail, forgetSendOtpForMobile } from '@/services/auth';
import { forgotValidation } from '@/utils/valitation/auth';
import { getCookie, setCookie } from 'cookies-next';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useState } from 'react';

import toast from 'react-hot-toast';

const ForgotPassword = ({ t, setAuth, setIsFromLogin, isFromLogin }) => {
  const [loading, setLoading] = useState(false);
  const handshake = getCookie('handshake');
  const { locale } = useRouter();

  const handleForgotApi = async (
    { email, mobilenumber },
    { setFieldError }
  ) => {
    setLoading(true);
    if (isFromLogin == 'by email') {
      const res = await forgetSendOtpForEmail(handshake, email);
      if (res?.data?.data?.status) {
        setCookie('resetPassword', JSON.stringify({ email }));
        toast.success(res?.data?.message || t('Otp send successfully'));
        setIsFromLogin('Forgot Password by Email');
        setAuth('Otp');
      } else {
        toast.error(res?.data?.data?.message);
        setFieldError('email', res?.data?.data?.message);
      }
    } else {
      // by mobile
      const res = await forgetSendOtpForMobile(handshake, mobilenumber, locale);
      console.log('res////////////////', res);
      if (res?.data?.data?.success == 'true') {
        setCookie('mobilenumber', mobilenumber);
        toast.success(res?.data?.message || t('Otp send successfully'));
        setIsFromLogin('Forgot Password by Mobile');
        setAuth('Otp');
      } else {
        toast.error(res?.error?.error);
        setFieldError('email', res?.error?.error);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className='my-2.5 text-center text-sm sm:text-lg'>
        <span className='font-bold'>{t('Enter your email address')} </span>
        <span className='text-zinc-500'>
          {t('and we will send you a verification code to your email address.')}
        </span>
      </div>
      <Formik
        initialValues={{
          ...(isFromLogin == 'by email' ? { email: '' } : { mobilenumber: '' }),
        }}
        onSubmit={handleForgotApi}
        validationSchema={forgotValidation(
          t,
          isFromLogin == 'by mobile',
          locale
        )}
      >
        <Form className='*:mb-1.5'>
          {isFromLogin == 'by mobile' ? (
            <div className='*:mb-1'>
              <div
                className={`flex ${locale.includes('ar') ? 'flex-row' : 'flex-row-reverse'} items-center rounded-sm border p-1.5`}
              >
                <Field
                  name='mobilenumber'
                  className='ltr flex-1 ps-4 outline-none max-sm:placeholder:text-sm sm:text-lg'
                  maxLength={10}
                  type='tel'
                  placeholder={`05${locale === 'ar' ? 'x' : '0'}xxxxxxx`}
                />
                <span className='w-14 border-s text-center'>
                  {locale === 'ar' ? '+966' : '+971'}
                </span>
              </div>
              <ErrorMessage
                name='mobilenumber'
                component='p'
                className='text-sm text-red-500 sm:text-base'
              />
            </div>
          ) : (
            <div className='*:mb-1'>
              <Field
                name='email'
                type='email'
                className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                placeholder={t('Email Address')}
              />
              <ErrorMessage
                name='email'
                component='p'
                className='text-sm text-red-600 sm:text-lg'
              />
            </div>
          )}

          <button
            type='submit'
            disabled={loading}
            className='flex h-10 w-full items-center justify-center rounded-sm bg-black p-1.5 text-sm font-bold text-white disabled:cursor-not-allowed max-sm:h-8 sm:text-lg'
          >
            {loading ? (
              <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
            ) : (
              t('Forgot Password')
            )}
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default ForgotPassword;
