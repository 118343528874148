import React from 'react';
import Colors from './Colors';
import Sizes from './Sizes';

const Configurables = ({
  handleOnClickConfigurable,
  selectedIdx,
  t,
  configurables,
}) => {
  return (
    <>
      {Object.entries(configurables || {})?.map((item, key) =>
        item[0] === 'color' ? (
          <Colors
            {...{ handleOnClickConfigurable, selectedIdx, parent: item, t }}
            key={`product-configurable-${key}`}
          />
        ) : (
          <Sizes
            {...{
              handleOnClickConfigurable,
              name: item[0],
              selectedIdx,
              data: Object.values(item[1]),
              t,
            }}
            key={`product-sizes-${key}`}
          />
        )
      )}
    </>
  );
};

export default Configurables;
